import Alert from "@mui/material/Alert"
import Grid from "@mui/material/Grid"

const THC_DEPARTMENT_SUFFIXES = [
    "FVR",
    "LOM",
    "LAK",
    "PLA",
    "DGN",
    "ARD",
    "BES",
    "CPE",
    "TX-01",
    "CPR-02",
    "ZBL",
    "PLA-01",
]

interface TenetHealthcareNoticeProps {
    uciPrimaryDepartment: string
}

function TenetHealthcareNotice({ uciPrimaryDepartment }: TenetHealthcareNoticeProps) {
    if (!THC_DEPARTMENT_SUFFIXES.some((suffix) => uciPrimaryDepartment.endsWith(`-${suffix}`))) {
        return null
    }

    return (
        <Grid item xs={12}>
            <Alert severity="warning">
                This user is likely a former Tenet Healthcare employee and will not be able to reset
                their UCInetID password through activate.uci.edu. For account password support, please
                direct them to the HS Self-Service Portal at myaccount.hs.uci.edu instead.
            </Alert>
        </Grid>
    )
}

export default TenetHealthcareNotice
