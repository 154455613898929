import useBlockList from "../../DataHooks/useBlockList"
import useNetworkRegistrations from "../../DataHooks/useNetworkRegistrations"
import BlockList from "./BlockList"

interface BlockListProps {
    ucinetid: string
    expanded?: boolean
}

let BlockListForUCInetID = (props: BlockListProps) => {
    let { ucinetid, expanded = false } = props

    let { registrations } = useNetworkRegistrations(ucinetid)
    let { loading, ucinetidBlocked, blocks } = useBlockList(
        ucinetid,
        registrations.map((r) => r.mac_address)
    )

    return (
        <BlockList
            loading={loading}
            expanded={expanded}
            ucinetid={ucinetid}
            ucinetidBlocked={ucinetidBlocked}
            blocks={blocks}
        ></BlockList>
    )
}

export default BlockListForUCInetID
