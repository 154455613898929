import React from "react"
import { QueryFunction, QueryKey, useQuery, UseQueryOptions, UseQueryResult } from "react-query"

const DEFAULT_DELAY = 200

const keys_equal = (k1: QueryKey, k2: QueryKey) => {
    if (typeof k1 === "string") {
        return k1 === k2
    } else {
        return k1.every((value, index) => value === k2[index])
    }
}

interface DelayedOptions<
    TQueryFnData extends unknown,
    TError extends unknown,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey
> extends UseQueryOptions<TQueryFnData, TError, TData, TQueryKey> {
    /** The number of ms to delay before submitting the query */
    delay?: number
}

export const useDelayedQuery = <
    TQueryFnData extends unknown,
    TError extends unknown,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey
>(
    queryKey: TQueryKey,
    queryFn: QueryFunction<TQueryFnData, TQueryKey>,
    options?: DelayedOptions<TQueryFnData, TError, TData, TQueryKey>
): UseQueryResult<TData, TError> => {
    const { delay, ...otherOptions } = options ?? { delay: DEFAULT_DELAY }

    const [waiting, setWaiting] = React.useState(false)
    const [delayedKey, setDelayedKey] = React.useState(queryKey)

    React.useEffect(() => {
        const callback = () => {
            setDelayedKey(queryKey)
            setWaiting(false)
        }

        if (!keys_equal(queryKey, delayedKey)) {
            setWaiting(true)
            const id = window.setTimeout(callback, DEFAULT_DELAY, queryKey)

            return () => {
                window.clearTimeout(id)
            }
        }
    }, [queryKey, delayedKey])

    const res = useQuery(delayedKey, queryFn, otherOptions)

    if (waiting) {
        return {
            ...res,
            data: undefined,
            error: null,
            isError: false,
            isIdle: false,
            isLoading: true,
            isLoadingError: false,
            isRefetchError: false,
            isSuccess: false,
            status: "loading",
        }
    } else {
        return res
    }
}
