import { Avatar, ListItem, ListItemAvatar, ListItemText } from "@mui/material"
import { yellow } from "@mui/material/colors"
import { Router } from "@mui/icons-material"
import { ValidSearchType } from "../Search"
import { SearchSuggestionsState } from "./SearchSuggestions"

let is_valid_ip_address = (s: string) => {
    return s.match(/^([0-9]{1,3}\.){3}[0-9]{1,3}$/) ? true : false;
}

let useIPAddressSearchSuggestions = (searchValue: string): SearchSuggestionsState => {
    let ip = searchValue.trim()

    return {
        loading: false,
        error: false,
        searchSuggestions: is_valid_ip_address(ip)
            ? [
                  {
                      type: ValidSearchType.ip_address,
                      value: ip,
                      component: (
                          <ListItem button>
                              <ListItemAvatar>
                                  <Avatar
                                      style={{
                                          backgroundColor: yellow["A400"],
                                      }}
                                  >
                                      <Router></Router>
                                  </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                  primary={<>{ip}</>}
                                  secondary={<>IP Address</>}
                              ></ListItemText>
                          </ListItem>
                      ),
                  },
              ]
            : [],
    }
}

export default useIPAddressSearchSuggestions
