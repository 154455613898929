import { Grid, Stack, TextField, Button} from "@mui/material"

import React, { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"

import { ValidSearchType } from "./Search"
import SearchSuggestions from "./SearchSuggestions/SearchSuggestions"

interface SearchBarProps {
    submitSearch: (searchValue: string, searchType: ValidSearchType | null) => void
}

let SearchBar = (props: SearchBarProps) => {
    let { submitSearch } = props

    const params = useParams()

    /** The actual input into the search bar */
    const [searchInput, setSearchInput] = useState(params.query || "")
    const textInputRef: React.Ref<HTMLInputElement> = useRef(null)

    /** Update search input when path changes */
    useEffect(() => {
        setSearchInput(params.query || "")
    }, [params])

    const [showSuggestions, setShowSuggestions] = useState(false)
    const [returnFirstSuggestion, setReturnFirstSuggestion] = useState(false)

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(e.currentTarget.value)
    }

    const handleInputFocus = () => {
        setShowSuggestions(true)
    }

    const handleInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (!e.relatedTarget) {
            setShowSuggestions(false)
        }
    }

    /** This function is fired when the user presses the "Search" button or hits enter */
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        // SearchSuggestions will pass the first result to handleSuggestionClick
        setReturnFirstSuggestion(true)
    }

    /** Called upon actual click or when SearchSuggestions returns the first result */
    const handleSuggestionClick = (searchValue: string, searchType: ValidSearchType | null) => {
        setReturnFirstSuggestion(false)
        setShowSuggestions(false)

        // Remove focus from input element
        textInputRef.current?.blur()

        submitSearch(searchValue, searchType)
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs>
                    <Stack direction="column" style={{ position: "relative" }}>
                        <TextField
                            value={searchInput}
                            onFocus={handleInputFocus}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            autoFocus={!searchInput}
                            fullWidth
                            placeholder="UCInetID, Name, ID Number, MAC Address, IP Address, Support Group, Department, etc..."
                            margin="dense"
                            variant="outlined"
                            inputRef={textInputRef}
                        ></TextField>
                        <div
                            style={{
                                position: "absolute",
                                top: "100%",
                                width: "100%",
                                zIndex: 100,
                                display: showSuggestions ? "initial" : "none",
                            }}
                        >
                            <SearchSuggestions
                                searchInput={searchInput}
                                handleSuggestionClick={handleSuggestionClick}
                                returnFirstSuggestion={returnFirstSuggestion}
                            />
                        </div>
                    </Stack>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" type="submit">
                        Search
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default SearchBar
