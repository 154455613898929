import {
    Avatar,
    ListItem,
    ListItemAvatar,
    ListItemText,
} from "@mui/material"
import { indigo } from "@mui/material/colors"
import { Web } from "@mui/icons-material"
import { TLDS } from "../../../data/tlds"
import { ValidSearchType } from "../Search"
import { SearchSuggestionsState } from "./SearchSuggestions"

let useWebsiteSearchSuggestions = (tsv: string): SearchSuggestionsState => {
    let searchValue = tsv.trim()

    let is_domain = false

    for (let tld of TLDS) {
        if (searchValue.endsWith(tld)) {
            is_domain = true
        }
    }

    return {
        loading: false,
        error: false,
        searchSuggestions: is_domain
            ? [
                  {
                      value: searchValue,
                      type: ValidSearchType.host_name,
                      component: (
                          <ListItem button>
                              <ListItemAvatar>
                                  <Avatar
                                      style={{
                                          backgroundColor: indigo["A400"],
                                      }}
                                  >
                                      <Web></Web>
                                  </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                  primary={searchValue}
                                  secondary="Host Name"
                              ></ListItemText>
                          </ListItem>
                      ),
                  },
              ]
            : [],
    }
}

export default useWebsiteSearchSuggestions
