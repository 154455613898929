export let format_date_string = (date_value: string | number) => {
    let date = new Date(date_value)

    return date.toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
    })
}
