import { Box } from "@mui/material"
import { Alert } from '@mui/material';

interface CheckProps {
    query_status: "idle" | "error" | "loading" | "success"
    query_loading_text: string | JSX.Element
    query_error_text?: string | JSX.Element

    condition: boolean
    success_text: string | JSX.Element
    fail_text: string | JSX.Element
}

export const Check = (props: CheckProps) => {
    let {
        query_status,
        query_loading_text,
        query_error_text = "HDUtils encountered an error.",
        condition,
        success_text,
        fail_text,
    } = props

    return (
        <Box paddingBottom="1rem">
            <Alert
                severity={
                    query_status === "loading"
                        ? "info"
                        : query_status === "error"
                        ? "warning"
                        : condition
                        ? "success"
                        : "error"
                }
            >
                {query_status === "loading"
                    ? query_loading_text
                    : query_status === "error"
                    ? query_error_text
                    : condition
                    ? success_text
                    : fail_text}
            </Alert>
        </Box>
    )
}
