import { Box, Collapse, LinearProgress, List, Paper, Typography } from "@mui/material"

import React from "react"
import { TransitionGroup } from "react-transition-group"

import { ValidSearchType } from "../Search"
import useSupportGroupsSearchSuggestions from "./SupportGroupsSearchSuggestions/useSupportGroupsSearchSuggestions"
import useAccountSearchSuggestions from "./useAccountSearchSuggestions"
import useDepartmentSearchSuggestions from "./useDepartmentSearchSuggestions"
import useIPAddressSearchSuggestions from "./useIPAddressSearchSuggestions"
import useMACAddressSearchSuggestions from "./useMACAddressSearchSuggestions/useMACAddressSearchSuggestions"
import useWebsiteSearchSuggestions from "./useWebsiteSearchSuggestions"

// let wildcard_email = (email: string) => {
//     email = email.replace("@", "*@*")
//     return "*" + email
// }

export interface SearchSuggestionsState {
    loading: boolean
    error: boolean
    searchSuggestions: SearchSuggestion[]
}
export interface SearchSuggestion {
    /** Sets the search value to *this* on suggestion click */
    value: string
    /** Sets the type for the search value on suggestion click */
    type: ValidSearchType
    /** The component to be rendered in the list, should be `<ListItem/>` */
    component: JSX.Element
}

export interface SearchSuggestionsProps {
    /** The actual value in the search bar */
    searchInput: string

    /** Sets the `searchValue` and the `searchType` so the correct view is displayed */
    handleSuggestionClick: (value: string, type: ValidSearchType | null) => void

    /** If true, will call `handleSuggestionClick` with the first suggestion */
    returnFirstSuggestion: boolean
}

let SearchSuggestions = (props: SearchSuggestionsProps) => {
    let { searchInput, handleSuggestionClick, returnFirstSuggestion } = props

    let [delayedInput, setDelayedInput] = React.useState("")

    React.useEffect(() => {
        let timeout_id = setTimeout(() => {
            setDelayedInput(searchInput)
        }, 300)

        return () => {
            clearTimeout(timeout_id)
        }
    }, [searchInput])

    let {
        loading: accounts_loading,
        // error: accounts_error,
        searchSuggestions: accounts_searchSuggestions,
    } = useAccountSearchSuggestions(delayedInput)

    let {
        // loading: support_groups_loading,
        searchSuggestions: support_groups_searchSuggestions,
    } = useSupportGroupsSearchSuggestions(searchInput)

    let { loading: departments_loading, searchSuggestions: departments_searchSuggestions } =
        useDepartmentSearchSuggestions(delayedInput)

    let { loading: mac_addresses_loading, searchSuggestions: mac_addresses_searchSuggestions } =
        useMACAddressSearchSuggestions(delayedInput)

    let { searchSuggestions: ip_address_searchSuggestions } =
        useIPAddressSearchSuggestions(searchInput)

    let { searchSuggestions: website_searchSuggestions } = useWebsiteSearchSuggestions(searchInput)

    let search_suggestions = React.useMemo(
        () => [
            ...website_searchSuggestions,
            ...support_groups_searchSuggestions,
            ...departments_searchSuggestions,
            ...accounts_searchSuggestions,
            ...ip_address_searchSuggestions,
            ...mac_addresses_searchSuggestions,
        ],
        [
            support_groups_searchSuggestions,
            accounts_searchSuggestions,
            mac_addresses_searchSuggestions,
            departments_searchSuggestions,
            ip_address_searchSuggestions,
            website_searchSuggestions,
        ]
    )
    let loading = React.useMemo(
        () => accounts_loading || departments_loading || mac_addresses_loading,
        [accounts_loading, departments_loading, mac_addresses_loading]
    )

    React.useEffect(() => {
        // console.log(loading, searchInput, delayedInput, search_suggestions)

        if (
            returnFirstSuggestion &&
            searchInput === delayedInput &&
            !loading &&
            search_suggestions.length > 0
        ) {
            console.log("Using First Suggestion")
            let suggestion = search_suggestions[0]
            handleSuggestionClick(suggestion.value, suggestion.type)
        } else if (returnFirstSuggestion && !loading && search_suggestions.length === 0) {
            handleSuggestionClick(searchInput, null)
        }
    }, [
        returnFirstSuggestion,
        searchInput,
        delayedInput,
        loading,
        search_suggestions,
        handleSuggestionClick,
    ])

    return (
        <>
            {/* <BlockHeader>Live Search</BlockHeader> */}
            {/* <>
                DEBUG
                <br></br>
                <ul>
                    <li>accounts_loading: {accounts_loading ? "t" : "f"}</li>
                    <li>
                        departments_loading: {departments_loading ? "t" : "f"}
                    </li>
                    <li>
                        mac_addresses_loading:{" "}
                        {mac_addresses_loading ? "t" : "f"}
                    </li>
                </ul>
            </> */}

            <Collapse in={searchInput.length > 0}>
                <Paper>
                    <Collapse in={loading}>
                        <LinearProgress></LinearProgress>
                    </Collapse>
                    <Collapse in={loading && search_suggestions.length === 0}>
                        <Box padding="2rem" display="flex" justifyContent="center">
                            <Typography variant="button">
                                {returnFirstSuggestion
                                    ? "One moment, looking for the best match"
                                    : "Loading"}
                            </Typography>
                        </Box>
                    </Collapse>
                    <Collapse in={search_suggestions.length > 0}>
                        <List>
                            <TransitionGroup>
                                {search_suggestions.map((s) => (
                                    <Collapse key={s.value}>
                                        <div onClick={() => handleSuggestionClick(s.value, s.type)}>
                                            {s.component}
                                        </div>
                                    </Collapse>
                                ))}
                            </TransitionGroup>
                        </List>
                    </Collapse>
                    <Collapse in={!loading && search_suggestions.length === 0}>
                        <Box padding="2rem" display="flex" justifyContent="center">
                            <Typography variant="button">No Results</Typography>
                        </Box>
                    </Collapse>
                </Paper>
            </Collapse>
        </>
    )
}

export default SearchSuggestions
