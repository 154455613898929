import { Box, Chip, Grid, Paper, Typography, Skeleton } from "@mui/material"
import useLDAP from "../../DataHooks/useLDAP"
import { getIconForAffiliation } from "../../Utilities/accountUtilities"
import { fetchQuickTempDataByUCInetID, fetchKFSOrgByNumber } from "../../../apiFunctions"
import { useQuery } from "react-query"
import TenetHealthcareNotice from "./TenetHealthcareNotice"

interface QuickInfoProps {
    ucinetid: string
}

function formatPhoneNumber(phone: string) {
    const nums = phone.replace(/\D/g, "")
    const match = nums.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match === null) {
        return "Irregular phone number: " + phone
    }
    return "(" + match[1] + ") " + match[2] + "-" + match[3]
}

let QuickInfo = (props: QuickInfoProps) => {
    let { ucinetid } = props

    let { loading: ldapLoading, entry } = useLDAP(ucinetid)
    let attributes = entry?.attributes

    const { isLoading: qtinfo_isLoading, data: qt_data } = useQuery(
        ["quick-temp-data", ucinetid],
        fetchQuickTempDataByUCInetID(ucinetid),
        {
            enabled: attributes?.uciPrimaryDepartmentCode === "IR7008",
        }
    )

    const { isLoading: department_support_isLoading, data: department_support_data } = useQuery(
        ["kfs-org", qt_data?.department_number],
        fetchKFSOrgByNumber(qt_data?.department_number.replaceAll(/[a-zA-Z]/g, "")),
        {
            enabled: qt_data?.department_number !== undefined,
        }
    )

    const loading = ldapLoading || qtinfo_isLoading
    const isQuickTempEmployee = qt_data !== undefined && qt_data !== null

    return (
        <Box marginBottom="1rem">
            {
                loading && attributes?.uid === undefined ? (
                    <>
                        <Box sx={{ height: "100%" }}>
                            <Typography variant="h2"><Skeleton animation="wave" /></Typography>
                            <Typography variant="body1"><Skeleton animation="wave" /></Typography>
                            <Typography variant="body2"><Skeleton animation="wave" /></Typography>
                            <Typography variant="h4"><Skeleton animation="wave" /></Typography>
                        </Box>
                    </>
                ) : (
                    <Grid container spacing={2}>
                        <TenetHealthcareNotice uciPrimaryDepartment={attributes?.uciPrimaryDepartment ?? ""} />
                        <Grid item xs={12} md={loading || attributes?.department ? 6 : 12}>
                            <Paper sx={{ height: "100%" }}>
                                <Box p="2rem">
                                    <Typography variant="h6">
                                        {attributes?.displayName} ({attributes?.uid})
                                    </Typography>
                                    <Typography>{attributes?.uciMailDeliveryPoint}</Typography>
                                    <Box marginTop="1rem">
                                        <Grid container spacing={2}>
                                            {attributes?.uciAffiliation?.map((affiliation) => (
                                                <Grid item key={affiliation}>
                                                    <Chip
                                                        size="small"
                                                        icon={getIconForAffiliation(affiliation)}
                                                        label={affiliation.toUpperCase()}
                                                    ></Chip>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>
                        {(isQuickTempEmployee || attributes?.eduPersonAffiliation?.includes("employee")) && (
                            <Grid item xs={12} md={6}>
                                <Paper sx={{ height: "100%" }}>
                                    <Box p="2rem">
                                        {isQuickTempEmployee ? (
                                            <>
                                                <Typography variant="h6">
                                                    {qt_data?.department_number} -{" "}
                                                    {department_support_isLoading ? (
                                                        <Skeleton
                                                            width="40%"
                                                            sx={{ display: "inline-block" }}
                                                        ></Skeleton>
                                                    ) : (
                                                        department_support_data?.org_name
                                                    )}
                                                </Typography>
                                                <Typography>
                                                    Employee is a Temporary Employee
                                                </Typography>
                                                <Typography variant="subtitle2">
                                                    Supervisor: {qt_data?.supervisor_name} (
                                                    {qt_data?.supervisor_ucinetid} -{" "}
                                                    {qt_data?.supervisor_phone !== undefined &&
                                                        formatPhoneNumber(qt_data?.supervisor_phone)}
                                                    )
                                                </Typography>
                                            </>
                                        ) : (
                                            <>
                                                {attributes?.department && (
                                                    <Typography variant="h6">
                                                        {attributes?.department.join(", ")}
                                                    </Typography>
                                                )}
                                                {attributes?.uciPrimaryDepartment && (
                                                    <Typography>
                                                        {attributes?.uciPrimaryDepartment}
                                                    </Typography>
                                                )}
                                                {attributes?.uciPrimaryTitle && (
                                                    <Typography>
                                                        {attributes?.uciPrimaryTitle}
                                                        {attributes?.title?.[0] &&
                                                            ` - ${attributes?.title?.[0]}`}
                                                    </Typography>
                                                )}
                                                {attributes?.uciPrimaryEmployeeClassDescription && (
                                                    <Typography>
                                                        {attributes?.uciPrimaryEmployeeClassDescription}
                                                    </Typography>
                                                )}
                                                {attributes?.telephoneNumber && (
                                                    <Typography>
                                                        {attributes.telephoneNumber}
                                                    </Typography>
                                                )}
                                            </>
                                        )}
                                    </Box>
                                </Paper>
                            </Grid>
                        )}
                    </Grid>
                )}
        </Box>
    )
}

export default QuickInfo
