import {
    Collapse,
    Grid,
    Table,
    TableHead,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Paper,
    Box,
    Typography,
    TableBody,
    LinearProgress,
    Link,
} from "@mui/material"
import { SettingsEthernet } from "@mui/icons-material"
import { Autocomplete } from '@mui/material';
import React from "react"
import InfoBlock from "../../GenericBlocks/InfoBlock/InfoBlock"
import { default as JPLPortComponent } from "./JPLPort"
import _ from "lodash"
import { Building, JPLPort } from "../../../apiInterfaces"
import SubnetsMetadata from "./SubnetsMetadata"
import { useQuery } from "react-query"
import { fetchBuildingPorts, fetchBuildings } from "../../../apiFunctions"

export let escape_text = (str: string) =>
    _.unescape(str).replaceAll("&#039;", "'").replaceAll("\\", "")

let buildingToOption = (building: Building) => {
    return `${building.abbr_name} - ${escape_text(building.alias)}`
}

let JPL = () => {
    let [selectedBuilding, setSelectedBuilding] = React.useState<Building | null>(null)
    let [selectedRoom, setSelectedRoom] = React.useState<string | null>(null)

    let {
        // isLoading: buildingListLoading,
        // isError: buildingListError,
        data: buildings,
    } = useQuery(["all-buildings"], fetchBuildings)

    let {
        isLoading: ports_abbr_loading,
        // isError: portsError,
        data: ports_abbr_unsorted,
    } = useQuery(
        ["building-ports", selectedBuilding?.abbr_name],
        fetchBuildingPorts(selectedBuilding?.abbr_name),
        {
            enabled: selectedBuilding !== null,
        }
    )

    let {
        isLoading: ports_compco_loading,
        // isError: portsError,
        data: ports_compco_unsorted,
    } = useQuery(
        ["building-ports", selectedBuilding?.compco_id],
        fetchBuildingPorts(selectedBuilding?.compco_id),
        {
            enabled:
                selectedBuilding !== null &&
                selectedBuilding.abbr_name !== selectedBuilding.compco_id,
        }
    )

    const portsLoading = ports_abbr_loading || ports_compco_loading

    let ports = React.useMemo(() => {
        const key = (a: JPLPort, b: JPLPort) => (a.station_id > b.station_id ? 1 : -1)

        const ports_abbr_sorted = ports_abbr_unsorted?.sort(key) ?? []

        if (selectedBuilding?.abbr_name !== selectedBuilding?.compco_id) {
            const ports_compco_sorted = ports_compco_unsorted?.sort(key) ?? []
            return [...ports_abbr_sorted, ...ports_compco_sorted]
        } else {
            return ports_abbr_sorted
        }
    }, [selectedBuilding, ports_abbr_unsorted, ports_compco_unsorted])

    if (selectedBuilding === null) {
        ports = []
    }

    let handleBuildingChange = (event: React.ChangeEvent<{}>, value: Building | null) => {
        setSelectedBuilding(value)
        setSelectedRoom(null)
    }

    buildings = buildings ?? []
    let rooms = [...new Set(ports?.map((port) => port.room.toUpperCase()) ?? [])].sort()

    return (
        <InfoBlock
            icon={<SettingsEthernet></SettingsEthernet>}
            title="Jack, Port, Location"
            loadingIndicator={false}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Autocomplete
                        autoComplete
                        autoHighlight
                        autoSelect
                        loading={buildings.length === 0}
                        fullWidth
                        options={buildings}
                        getOptionLabel={(option) => buildingToOption(option)}
                        renderInput={(params) => (
                            <TextField {...params} label="Building"></TextField>
                        )}
                        value={selectedBuilding}
                        onChange={handleBuildingChange}
                    ></Autocomplete>
                </Grid>
                <Grid item xs={6}>
                    <Collapse in={selectedBuilding !== null}>
                        <Autocomplete
                            autoComplete
                            autoHighlight
                            autoSelect
                            loading={portsLoading}
                            noOptionsText="No Rooms Available"
                            fullWidth
                            options={rooms}
                            getOptionLabel={(option) => (option ? option : "N/A")}
                            renderInput={(params) => (
                                <TextField {...params} label="Room"></TextField>
                            )}
                            value={selectedRoom}
                            onChange={(e, v) => setSelectedRoom(v)}
                        ></Autocomplete>
                    </Collapse>
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={Boolean(selectedBuilding)}>
                        <Box>
                            <Box marginBottom="1rem">
                                <SubnetsMetadata></SubnetsMetadata>
                            </Box>
                            <Typography variant="h6">Active Ports:</Typography>
                            <Paper>
                                {portsLoading && <LinearProgress></LinearProgress>}
                                <TableContainer style={{ maxHeight: "80vh" }}>
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    <b>Station ID</b>
                                                </TableCell>
                                                <TableCell>
                                                    <b>Building Number</b>
                                                </TableCell>
                                                <TableCell>
                                                    <b>Room</b>
                                                </TableCell>
                                                <TableCell>
                                                    <b>Subnet</b>
                                                </TableCell>
                                                <TableCell>
                                                    <b>Port</b>
                                                </TableCell>
                                                <TableCell>
                                                    <b>VOIP Phone Number</b>
                                                </TableCell>
                                                <TableCell>
                                                    <b>Contact</b>
                                                </TableCell>
                                                <TableCell>
                                                    <b>Notes</b>
                                                </TableCell>
                                                {/* <TableCell>
                                                    Order Number
                                                </TableCell>
                                                <TableCell>
                                                    Entered By
                                                </TableCell> */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {ports &&
                                                (selectedRoom !== null
                                                    ? ports.filter(
                                                          (port) =>
                                                              port.room.toUpperCase() ===
                                                              selectedRoom
                                                      )
                                                    : ports
                                                ).map((port) => (
                                                    <JPLPortComponent
                                                        key={port.station_id}
                                                        port={port}
                                                    ></JPLPortComponent>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {!portsLoading && ports?.length === 0 && (
                                    <Box p="1rem">
                                        <b>No Ports in this Building!</b>
                                        <br />
                                        Residential Buildings aren't tracked in JPL, instead please
                                        refer to{" "}
                                        <Link
                                            href="https://www.oit.uci.edu/resnet/ethernet-port-locations/"
                                            target="_blank"
                                        >
                                            this page
                                        </Link>{" "}
                                        to determine the active port in their housing community.
                                    </Box>
                                )}
                            </Paper>
                        </Box>
                    </Collapse>
                </Grid>
            </Grid>
        </InfoBlock>
    )
}

export default JPL
