import useNetworkRegistrations from "../../DataHooks/useNetworkRegistrations"
import NetworkRegistrations from "./NetworkRegistrations"

interface NetworkRegistrationsForUCInetIDProps {
    ucinetid: string
    expanded?: boolean
}

let NetworkRegistrationsForUCInetID = (props: NetworkRegistrationsForUCInetIDProps) => {
    let { ucinetid, expanded = false } = props

    let { loading, registrations } = useNetworkRegistrations(ucinetid)

    return (
        <NetworkRegistrations
            loading={loading}
            expanded={expanded}
            registrations={registrations}
        ></NetworkRegistrations>
    )
}

export default NetworkRegistrationsForUCInetID
