import {
    Box,
    CircularProgress,
    Fade,
    Typography,
    Grid,
    Avatar,
    Divider,
    Tooltip,
} from "@mui/material"
import { green, red } from "@mui/material/colors"
import { Check, Clear } from "@mui/icons-material"
import { SwitchTransition } from "react-transition-group"
import { fetchForescoutHostByIP, fetchForescoutHostByMAC } from "../../../apiFunctions"
import { RESNET_SUBNET_TO_SEGMENT } from "../../../data/resnet_segments"
import { useDelayedQuery } from "../../DataHooks/useDelayedQuery"
import { format_date_string } from "../../Utilities/dateUtilities"
import { format_mac_address } from "../../Utilities/macUtilities"

type ForescoutMonitorProps =
    | {
          ip: string
          mac?: never
      }
    | {
          ip?: never
          mac: string
      }

export const ForescoutMonitor = (props: ForescoutMonitorProps) => {
    const { ip, mac } = props

    if (ip === undefined && mac === undefined) {
        console.error("Forescout Monitor must have either a MAC or an IP provided!")
    }

    const usingIP = ip !== undefined

    const { status, data, dataUpdatedAt, isFetched } = useDelayedQuery(
        [`forescout-info-by-${usingIP ? "ip" : "mac"}`, usingIP ? ip : mac],
        usingIP ? fetchForescoutHostByIP(ip) : fetchForescoutHostByMAC(mac ?? "BAD MAC"),
        usingIP
            ? {
                  enabled: ip !== "",
                  refetchInterval: 30 * 1000,
                  retry: false,
              }
            : {
                  enabled: mac !== "",
                  refetchInterval: 30 * 1000,
                  retry: false,
              }
    )

    console.log(status, isFetched, data)

    const {
        online = undefined,
        channel = undefined,
        mac_vendor_string = undefined,
        dex_MobileAccess = undefined,
        dex_MobileAccessUser = undefined,
        dhcp_hostname_v2 = undefined,
        dhcp_os_v2 = undefined,
        dhcp_class_v2 = undefined,
        bdomain = undefined,
        dhcp_server = undefined,
    } = data?.host?.fields ?? {}

    const forescout_community =
        RESNET_SUBNET_TO_SEGMENT[(bdomain?.value as keyof typeof RESNET_SUBNET_TO_SEGMENT) ?? ""]

    return (
        <Box
            sx={{
                padding: "1rem 2rem",
                border: (theme) =>
                    `1px solid ${
                        theme.palette.mode === "light"
                            ? "rgba(0, 0, 0, 0.23)"
                            : "rgba(255, 255, 255, 0.23)"
                    }`,
                borderRadius: (theme) => theme.shape.borderRadius,
            }}
        >
            <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                <Grid item xs={1}>
                    <SwitchTransition>
                        <Fade key={dataUpdatedAt}>
                            {status === "loading" ? (
                                <CircularProgress></CircularProgress>
                            ) : (
                                <Avatar
                                    style={{
                                        backgroundColor:
                                            online?.value === "true" ? green["A400"] : red["A400"],
                                    }}
                                >
                                    {online?.value === "true" ? <Check></Check> : <Clear></Clear>}
                                </Avatar>
                            )}
                        </Fade>
                    </SwitchTransition>
                </Grid>
                <Grid item xs>
                    <Typography variant="h6">Forescout</Typography>
                    <Tooltip
                        title="  HDUtils will refresh every 30 seconds. Pay attention to the time that
                        Forescout last updated its data. There is currently no known way for us to
                        forcibly refresh Forescout's data."
                    >
                        <Typography variant="caption">
                            Last Refreshed:{" "}
                            {new Date(dataUpdatedAt).toLocaleString("en-US", {
                                month: "2-digit",
                                day: "2-digit",
                                year: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                            })}
                        </Typography>
                    </Tooltip>
                </Grid>
            </Grid>
            <Box height="1rem"></Box>
            <Divider></Divider>
            <Box paddingTop="1rem">
                {status === "error" || (isFetched && data?.host === undefined) ? (
                    <Typography>Forescout could not find this host!</Typography>
                ) : status === "loading" && !isFetched ? (
                    <Typography>Loading...</Typography>
                ) : (
                    <>
                        <Typography>
                            The device is <b>{online?.value === "true" ? "ONLINE" : "OFFLINE"}</b>{" "}
                            (Last Updated:{" "}
                            <b>
                                {online?.timestamp
                                    ? format_date_string(online?.timestamp * 1000)
                                    : "Unknown"}
                            </b>
                            )
                        </Typography>
                        <Typography>
                            The connection's interface is: <b>{channel?.value || "Unknown"}</b>
                        </Typography>
                        <Typography>
                            The MAC address is{" "}
                            <b>{data?.host?.mac ? format_mac_address(data.host.mac) : "Unknown"}</b>
                            . This indicates that it is made by{" "}
                            <b>{mac_vendor_string?.value || "Unknown"}</b>
                        </Typography>
                        <Typography>
                            The device appears to belong to the following device category:{" "}
                            <b>{dhcp_class_v2?.value || "Unknown"}</b>
                        </Typography>
                        <Typography>
                            The device appears to be running the following operating system:{" "}
                            <b>{dhcp_os_v2?.value || "Unknown"}</b>
                        </Typography>
                        <Typography>
                            The IP address is <b>{data?.host?.ip}</b>. Its hostname is{" "}
                            <b>{dhcp_hostname_v2?.value || "Unknown"}</b>
                        </Typography>
                        <Typography>
                            The device is on the <b>{bdomain?.value || "Unknown"}</b> subnet in the{" "}
                            <b>{forescout_community || "Unknown"}</b> community
                        </Typography>
                        <Typography>
                            The device is using the <b>{dhcp_server?.value || "Unknown"}</b> DHCP
                            Server
                        </Typography>
                        <Typography>
                            {dex_MobileAccess?.value === "true" ? (
                                <>
                                    Forescout believes this device is registered to{" "}
                                    <b>{dex_MobileAccessUser?.value || "Unknown"}</b>
                                </>
                            ) : (
                                <b>Forescout reports that this device IS NOT REGISTERED!</b>
                            )}
                        </Typography>
                    </>
                )}
            </Box>
        </Box>
    )
}
