import {
    Alert,
    AlertTitle,
    Grid,
    LinearProgress,
    Link,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from "@mui/material"
import { useRef, useState } from "react"
import { useQuery, useQueryClient } from "react-query";
import { fetchALMEventsByQuery, fetchALMOperators } from "../../apiFunctions";
import { ALMEvent } from "../../apiInterfaces";

import HelpTooltip from "../GenericBlocks/HelpTooltip";
import LicenseManagerLogSearchForm from "./LicenseManagerLogSearchForm";


const ROWS_PER_PAGE = 100

let LicenseManagerLogs = () => {
    const queryClient = useQueryClient()
    const result = useRef<null | HTMLDivElement>(null)
    let [query, setQuery] = useState("")
    let [almEvents, setAlmEvents] = useState<ALMEvent[]>([])
    let [page, setPage] = useState(0);

    const { data: operators, isLoading: operatorsLoading, isError: operatorsError } = useQuery(
        ["alm-operators"],
        fetchALMOperators()
    )

    let { data: almEventData, isFetching: almEventLoading, isError: almEventError } = useQuery(
        ["alm-events", query],
        fetchALMEventsByQuery(query),
        {
            enabled: query !== "",
            onSuccess: (eventData) => {
                setAlmEvents(almEvents.concat(eventData.events))
            }
        }
    )

    const handleSubmit = (query: string) => {
        setQuery(query)
        setAlmEvents([])
        queryClient.invalidateQueries(["alm-events", query], { exact: true })
        if(result && result.current){
            result.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }

    const handleChangePage = (event: any, newPage: number) => {
        if (newPage + 1 >= almEvents.length / ROWS_PER_PAGE) {
            setQuery(query += "&skip=" + almEventData!.offset)
            queryClient.invalidateQueries(["alm-events", query], { exact: true })
        }
        setPage(newPage);
    };

    return (
        <Grid container display="flex" width="100%" pt="2rem" pb="2rem" spacing={2}>
            <Grid item xs={12}>
                <Typography>
                    This tool helps to track all License modifications events. Currently, it only availables to Adobe Licenses.
                    Dev team is wrapping up this tool, be back soon.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Paper elevation={5} sx={{ width: "inherit" }}>
                    {
                        operatorsLoading ? (
                            <Skeleton variant="rectangular" height="10rem" />
                        ) : operatorsError ? (
                            <Alert severity="error">
                                <AlertTitle>
                                    <b>Error</b>
                                </AlertTitle>
                                Error fetctching log information. Try to refresh the page or contact dev team.
                            </Alert>
                        ) : operators && (
                            <LicenseManagerLogSearchForm
                                operators={operators.map((operator) => (operator.operator))}
                                types={["LICENSE_ASSIGNED"]}
                                onSubmit={handleSubmit}
                            />
                        )
                    }
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper ref={result} elevation={5} sx={{ mt: "1rem", width: "inherit" }} >
                    {almEventLoading && almEvents.length === 0 ? (
                        <Skeleton variant="rectangular" height="75vh" />
                    ) : almEventError ? (
                        <Alert severity="error">
                            <AlertTitle>
                                <b>Error</b>
                            </AlertTitle>
                            Error fetctching log information. Try to refresh the page or contact dev team.
                        </Alert>
                    ) : almEvents.length > 0 && (
                        <TableContainer sx={{ maxHeight: "75vh"}}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Event Time</TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                                            Operator{" "}
                                            <HelpTooltip tooltip="UcinetID of help desk operator" />
                                        </TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                                            User{" "}
                                            <HelpTooltip tooltip="UcinetID of student or faculty" />
                                        </TableCell>
                                        <TableCell>Event Type</TableCell>
                                        <TableCell>Reason</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {almEvents
                                        .slice(page * ROWS_PER_PAGE, page * ROWS_PER_PAGE + ROWS_PER_PAGE)
                                        .map((event, index) => (
                                            <TableRow key={index}>
                                                <TableCell sx={{ whiteSpace: "nowrap" }}>
                                                    <Typography fontSize="medium">
                                                        {(new Date(event.timestamp)).toLocaleString('en-US')}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell sx={{ whiteSpace: "nowrap" }}>
                                                    <Link
                                                        target="_blank"
                                                        fontSize="medium"
                                                        href={`/search/ucinetid/${event.operator}`}
                                                    >
                                                        {event.operator}
                                                    </Link>
                                                </TableCell>
                                                <TableCell sx={{ whiteSpace: "nowrap" }}>
                                                    <Link
                                                        target="_blank"
                                                        fontSize="medium"
                                                        href={`/search/ucinetid/${event.ucinetid}`}
                                                    >
                                                        {event.ucinetid}
                                                    </Link>
                                                </TableCell>
                                                <TableCell sx={{ whiteSpace: "nowrap" }}>
                                                    <Typography fontSize="medium">
                                                        {event.type}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography fontSize="medium" style={{ whiteSpace: 'pre-line' }}>
                                                        {event.detail}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table >
                        </TableContainer >
                    )}
                    {
                        almEventLoading ? (
                            <LinearProgress />
                        ) : (
                            <TablePagination
                                component="div"
                                count={almEvents.length}
                                rowsPerPageOptions={[-1]}
                                rowsPerPage={100}
                                onPageChange={handleChangePage}
                                page={page}
                            />
                        )
                    }
                </Paper >
            </Grid >
        </Grid >
    )
}

export default LicenseManagerLogs