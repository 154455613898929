import { Box, Paper, Typography, Divider, Grid, Tooltip, IconButton } from "@mui/material"
import { Dashboard, MenuBook, MyLocation, Router, SettingsEthernet } from "@mui/icons-material"
import InfoBlock from "../../GenericBlocks/InfoBlock/InfoBlock"

import { NetworkTroubleshooter } from "./NetworkTroubleshooter"

export const NetworkSetupAssistant = () => {
    return (
        <InfoBlock
            icon={<Router></Router>}
            title="Network Setup Assistant"
            loadingIndicator={false}
        >
            <Paper>
                <Box p="1rem">
                    <Box paddingBottom="1rem">
                        <Typography>
                            Network Setup Assistant is utility designed to help use setup and
                            troubleshoot connections to UCI's network.
                        </Typography>
                        <Grid item container justifyContent="center" spacing={5}>
                            <Grid item>
                                <Tooltip title="Forescout Dashboard">
                                    <IconButton
                                        href="https://fs-man.resnet.uci.edu/forescout-client"
                                        target="_blank"
                                        size="large">
                                        <Dashboard></Dashboard>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title="Wiki Network Troubleshooting Guide">
                                    <IconButton
                                        href="https://wiki.oit.uci.edu/display/OITHD/ResNet+Troubleshooting+101"
                                        target="_blank"
                                        size="large">
                                        <MenuBook></MenuBook>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title="Ethernet Port Locations in Apartments">
                                    <IconButton
                                        href="https://www.oit.uci.edu/resnet/ethernet-port-locations/"
                                        target="_blank"
                                        size="large">
                                        <MyLocation></MyLocation>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title="Finding IP and MAC Addresses">
                                    <IconButton
                                        href="https://wiki.oit.uci.edu/display/OITHD/Finding+the+IP+and+MAC+Address+of+different+devices"
                                        target="_blank"
                                        size="large">
                                        <SettingsEthernet></SettingsEthernet>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider></Divider>
                    <NetworkTroubleshooter></NetworkTroubleshooter>
                </Box>
            </Paper>
        </InfoBlock>
    );
}
