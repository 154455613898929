import { Box } from "@mui/material"

import { Route, Routes, useNavigate } from "react-router-dom"

import PageParent from "../PageStructure/PageParent/PageParent"
import ToolHeader from "../GenericBlocks/ToolHeader"

import SearchBar from "./SearchBar"
import SearchResults from "./SearchResults"

// const MAC_ADDRESS_REGEX = /^([0-9A-Fa-f]{2}[:-]?){5}([0-9A-Fa-f]{2})$/
// const URL_REGEX = /^[\w-.]+\.[\w-]*$/
export interface ViewProps {
    searchValue: string
    searchType: ValidSearchType
    manuallySubmitted: boolean
}

interface SearchProps {}

/** These are search types that have an associated View for showing information for them */
export enum ValidSearchType {
    ucinetid = "ucinetid",
    mac_address = "mac_address",
    host_name = "host_name",
    support_group = "support_group",
    department = "department",
    subnet = "subnet",
    ip_address = "ip_address",
}

let Search = (props: SearchProps) => {
    const navigate = useNavigate()

    /** Add search query to path for SearchResults to parse */
    const submitSearch = (searchValue: string, searchType: ValidSearchType | null) => {
        navigate(`${searchType}/${searchValue}`)
    }

    const searchBar = <SearchBar submitSearch={submitSearch} />

    return (
        <PageParent>
            <ToolHeader>Search</ToolHeader>
            <Routes>
                {/* React Router v6 does not support optional parameters */}
                <Route path=":type/:query" element={searchBar} />
                <Route path="*" element={searchBar} />
            </Routes>
            <Box mt="2rem"></Box>
            <Routes>
                <Route path=":type/:query" element={<SearchResults />} />
            </Routes>
        </PageParent>
    )
}

export default Search
