import { TableRow, TableCell, Grid, Box } from "@mui/material"
import React from "react"
import HelpTooltip from "./HelpTooltip"

interface TableKeyValueRowProps {
    title: React.ReactNode
    children: React.ReactNode
    tooltip?: React.ReactNode | string
}

let TableKeyValueRow = (props: TableKeyValueRowProps) => {
    let { title, children, tooltip } = props

    return (
        <TableRow>
            <TableCell variant="head">
                <Box whiteSpace="nowrap">
                    <Grid container spacing={2} wrap="nowrap">
                        <Grid item>{title}</Grid>
                        {tooltip !== undefined && (
                            <Grid item>
                                {tooltip && <HelpTooltip tooltip={tooltip}/>}
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </TableCell>
            <TableCell>{children}</TableCell>
        </TableRow>
    )
}

export default TableKeyValueRow
