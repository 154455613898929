import { useQuery } from "react-query"
import { fetchForescoutHostsByMAC } from "../../../apiFunctions"

let useForescoutMACSearch = (searchValue: string) => {
    const is_enabled = searchValue.length >= 2

    let { isLoading, isError, data } = useQuery(
        ["forescout-hosts-filtered-by-mac", searchValue],
        fetchForescoutHostsByMAC(searchValue),
        { enabled: is_enabled }
    )

    return {
        loading: isLoading,
        error: isError,
        hosts: is_enabled ? data?.slice(0, 20) ?? [] : [],
    }
}

export default useForescoutMACSearch
