export const SUPPORT_GROUP_ALIASES = {
    "Claire Trevor School of the Arts": [],
    "Institute of Transportation Studies (ITS)": [],
    CALIT2: [],
    Engineering: [],
    "Beckman Laser Institute and Clinic": [],
    "Applied Innovation": [],
    "Advanced Power & Energy Program / NFCRC (APEP)": [],
    Housing: [],
    Education: [],
    "Research Group Support": [],
    "Campus Recreation (ARC)": [],
    "Integrated Nanosystems Research Facility": [],
    "Humanities Research Institute": [],
    "Continuing Education": ["DCE"],
    "Biological Sciences": ["BS", "Bio Sci"],
    "Public Health": [],
    "Health Sciences/UCIMC": ["HS"],
    "Social Sciences": [],
    "Information & Computer Science": ["ICS", "Donald Bren"],
    "Mind Research Unit (MRU)": [],
    "Merage School of Business": [],
    Humanities: [],
    "ICHA Support": [],
    "OIT Desktop Support": ["DSS"],
    "Law School": [],
    "Wellness Health and Counseling Services (WHCS)": [],
    "Self Sufficient (in OIT CSC Department)": [],
    "Social Ecology": [],
    Library: [],
    "Student Affairs": ["SA"],
    "Physical Sciences": [],
    "Student Government": [],
    "CHRS/RAIN": [],
}
