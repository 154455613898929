export interface Subnet {
    subnet: string
    range: string
    range_start: number
    range_end: number
    name: string
    message: string
    internet: boolean
}

export const SUBNETS: Subnet[] = [
    {
        subnet: "10.8.0.0/15",
        range: "10.8.0.0 - 10.9.255.255",
        range_start: 168296448,
        range_end: 168427519,
        name: "Undergrad-Wireless-Registered",
        message: "",
        internet: true,
    },
    {
        subnet: "10.11.0.0/20",
        range: "10.11.0.1 - 10.11.15.255",
        range_start: 168493057,
        range_end: 168497151,
        name: "Undergrad-Wireless-UNREGISTERED",
        message:
            "If the caller's MAC address *is* registered, try to recheck on CounterACT/Forescout.",
        internet: false,
    },
    {
        subnet: "10.11.16.0/20",
        range: "10.11.16.1 - 10.11.31.255",
        range_start: 168497153,
        range_end: 168501247,
        name: "Undergrad-Wireless-BLOCKED",
        message: "Please try to search by UCInetID or MAC address.",
        internet: false,
    },
    {
        subnet: "10.11.32.0/20",
        range: "10.11.32.1 - 10.11.47.255",
        range_start: 168501249,
        range_end: 168505343,
        name: "Undergrad-Wireless-Guest",
        message: "",
        internet: true,
    },
    {
        subnet: "10.12.0.0/15",
        range: "10.12.0.0 - 10.13.255.255",
        range_start: 168558592,
        range_end: 168689663,
        name: "Undergrad-Wired",
        message: "",
        internet: true,
    },
    {
        subnet: "128.195.96.0/22",
        range: "128.195.96.0 - 128.195.99.255",
        range_start: 2160287744,
        range_end: 2160288767,
        name: "Public-NAT-Pool",
        message: "",
        internet: true,
    },
    {
        subnet: "169.234.128.0/17",
        range: "169.234.128.0 - 169.234.255.255",
        range_start: 2850717696,
        range_end: 2850750463,
        name: "Grad-Wired/Personal-Router",
        message: "",
        internet: true,
    },
    {
        subnet: "192.168.0.0/16",
        range: "192.168.0.0 - 192.168.255.255",
        range_start: 3232235520,
        range_end: 3232301055,
        name: "Unconfigured-Router/Static-IP",
        message:
            "The resident can set up their router here (http://www.oit.uci.edu/resnet/network-setup/) or they can come in to the walk-in help desk to get it set up.",
        internet: false,
    },
    {
        subnet: "169.254.0.0/16",
        range: "169.254.0.0 - 169.254.255.255",
        range_start: 2851995648,
        range_end: 2852061183,
        name: "Self-Assigned",
        message: "",
        internet: false,
    },
    {
        subnet: "10.255.224.0/21",
        range: "10.255.224.0 - 10.255.231.255",
        range_start: 184541184,
        range_end: 184543231,
        name: "UCInet-Mobile-Access-BLOCKED",
        message: "",
        internet: false,
    },
    {
        subnet: "10.255.216.0/21",
        range: "10.255.216.0 - 10.255.223.255",
        range_start: 184539136,
        range_end: 184541183,
        name: "UCInet-Mobile-Access-BLOCKED",
        message: "",
        internet: false,
    },
    {
        subnet: "128.195.234.0/23",
        range: "128.195.234.0 - 128.195.235.255",
        range_start: 2160323072,
        range_end: 2160323583,
        name: "UCInet-Mobile-Access-Guest",
        message: "",
        internet: true,
    },
    {
        subnet: "128.195.232.0/23",
        range: "128.195.232.0 - 128.195.233.255",
        range_start: 2160322560,
        range_end: 2160323071,
        name: "UCInet-Mobile-Access-Guest",
        message: "",
        internet: true,
    },
    {
        subnet: "10.255.40.0/21",
        range: "10.255.40.0 - 10.255.47.255",
        range_start: 184494080,
        range_end: 184496127,
        name: "UCInet-Mobile-Access-Registration",
        message: "",
        internet: true,
    },
    {
        subnet: "10.255.32.0/21",
        range: "10.255.32.0 - 10.255.39.255",
        range_start: 184492032,
        range_end: 184494079,
        name: "UCInet-Mobile-Access-Registration",
        message: "",
        internet: true,
    },
    {
        subnet: "169.234.0.0/17",
        range: "169.234.0.0 - 169.234.127.255",
        range_start: 2850684928,
        range_end: 2850717695,
        name: "UCInet-Mobile-Access",
        message: "",
        internet: true,
    },
    {
        subnet: "192.5.19.0/24",
        range: "192.5.19.0 - 192.5.19.255",
        range_start: 3221558016,
        range_end: 3221558271,
        name: "LightPath",
        message: "",
        internet: true,
    },
    {
        subnet: "128.195.0.0/16",
        range: "128.195.0.1 - 128.195.255.254",
        range_start: 2160263169,
        range_end: 2160328702,
        name: "UCI-Net",
        message: "",
        internet: true,
    },
    {
        subnet: "128.200.0.0/16",
        range: "128.200.0.1 - 192.5.19.255",
        range_start: 2690056193,
        range_end: 2690121726,
        name: "UCI-Net",
        message: "",
        internet: true,
    },
    {
        subnet: "128.195.64.0/20",
        range: "128.195.64.65 - 128.195.79.254",
        range_start: 2160279617,
        range_end: 2160283646,
        name: "UCI VPN",
        message: "",
        internet: true,
    },
    {
        subnet: "128.200.3.0/28",
        range: "128.200.3.1 - 128.200.3.14",
        range_start: 2160591617,
        range_end: 2160591630,
        name: "UCI VPN",
        message: "",
        internet: true,
    },
]
