import React from "react"
import { KFSOrg } from "../../../apiInterfaces"
import { SearchSuggestionsState } from "./SearchSuggestions"
import {
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
} from "@mui/material"
import { Domain } from "@mui/icons-material"
import { teal } from "@mui/material/colors"
import { ValidSearchType } from "../Search"
import { useQuery } from "react-query"
import { fetchKFSOrgsByName } from "../../../apiFunctions"

let departmentToSearchSuggestion = (department: KFSOrg) => {
    let { org_name, department_number } = department

    return (
        <ListItem key={org_name} button>
            <ListItemAvatar>
                <Avatar style={{ backgroundColor: teal["A400"] }}>
                    <Domain></Domain>
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={`${org_name} - ${department_number}`}
                secondary="Department / KFS Organization"
            ></ListItemText>
        </ListItem>
    )
}

let useDepartmentSearchSuggestions = (
    searchInput: string
): SearchSuggestionsState => {
    let query = searchInput.match(/^[a-zA-Z ]+$/) ? searchInput : ""
    let { isLoading, isError, data } = useQuery(
        ["kfs-orgs-by-name", query],
        fetchKFSOrgsByName(query),
        { enabled: Boolean(query) }
    )

    return {
        loading: isLoading,
        error: isError,
        searchSuggestions: (data ?? []).map((dept) => ({
            value: dept.department_number,
            type: ValidSearchType.department,
            component: departmentToSearchSuggestion(dept),
        })),
    }
}

export default useDepartmentSearchSuggestions
