export const RESNET_SEGMENTS = {
    "Arroyo Vista - 1002": [
        {
            range: "10.13.0.0-10.13.0.255",
            subnets: ["10.13.0.0/255.255.255.0"],
        },
        {
            range: "169.234.176.0-169.234.176.127",
            subnets: ["169.234.176.0/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1006": [
        {
            range: "10.13.1.0-10.13.1.255",
            subnets: ["10.13.1.0/255.255.255.0"],
        },
        {
            range: "169.234.176.0-169.234.176.127",
            subnets: ["169.234.176.0/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1008": [
        {
            range: "10.13.2.0-10.13.2.255",
            subnets: ["10.13.2.0/255.255.255.0"],
        },
        {
            range: "169.234.176.128-169.234.176.255",
            subnets: ["169.234.176.128/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1010": [
        {
            range: "10.13.3.0-10.13.3.255",
            subnets: ["10.13.3.0/255.255.255.0"],
        },
        {
            range: "169.234.176.128-169.234.176.255",
            subnets: ["169.234.176.128/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1012": [
        {
            range: "10.13.4.0-10.13.4.255",
            subnets: ["10.13.4.0/255.255.255.0"],
        },
        {
            range: "169.234.177.0-169.234.177.127",
            subnets: ["169.234.177.0/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1014": [
        {
            range: "10.13.5.0-10.13.5.255",
            subnets: ["10.13.5.0/255.255.255.0"],
        },
        {
            range: "169.234.177.128-169.234.177.255",
            subnets: ["169.234.177.128/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1016": [
        {
            range: "10.13.6.0-10.13.6.255",
            subnets: ["10.13.6.0/255.255.255.0"],
        },
        {
            range: "169.234.177.128-169.234.177.255",
            subnets: ["169.234.177.128/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1018": [
        {
            range: "10.13.7.0-10.13.7.255",
            subnets: ["10.13.7.0/255.255.255.0"],
        },
        {
            range: "169.234.178.0-169.234.178.127",
            subnets: ["169.234.178.0/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1020": [
        {
            range: "10.13.8.0-10.13.8.255",
            subnets: ["10.13.8.0/255.255.255.0"],
        },
        {
            range: "169.234.178.128-169.234.178.255",
            subnets: ["169.234.178.128/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1022": [
        {
            range: "10.13.9.0-10.13.9.255",
            subnets: ["10.13.9.0/255.255.255.0"],
        },
        {
            range: "169.234.178.0-169.234.178.127",
            subnets: ["169.234.178.0/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1024": [
        {
            range: "10.13.10.0-10.13.10.255",
            subnets: ["10.13.10.0/255.255.255.0"],
        },
        {
            range: "169.234.179.0-169.234.179.127",
            subnets: ["169.234.179.0/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1026": [
        {
            range: "10.13.11.0-10.13.11.255",
            subnets: ["10.13.11.0/255.255.255.0"],
        },
        {
            range: "169.234.179.0-169.234.179.127",
            subnets: ["169.234.179.0/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1028": [
        {
            range: "10.13.12.0-10.13.12.255",
            subnets: ["10.13.12.0/255.255.255.0"],
        },
        {
            range: "169.234.179.128-169.234.179.255",
            subnets: ["169.234.179.128/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1030": [
        {
            range: "10.13.13.0-10.13.13.255",
            subnets: ["10.13.13.0/255.255.255.0"],
        },
        {
            range: "169.234.180.0-169.234.180.127",
            subnets: ["169.234.180.0/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1032": [
        {
            range: "10.13.14.0-10.13.14.255",
            subnets: ["10.13.14.0/255.255.255.0"],
        },
        {
            range: "169.234.180.128-169.234.180.255",
            subnets: ["169.234.180.128/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1034": [
        {
            range: "10.13.15.0-10.13.15.255",
            subnets: ["10.13.15.0/255.255.255.0"],
        },
        {
            range: "169.234.181.0-169.234.181.127",
            subnets: ["169.234.181.0/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1036": [
        {
            range: "10.13.16.0-10.13.16.255",
            subnets: ["10.13.16.0/255.255.255.0"],
        },
        {
            range: "169.234.180.0-169.234.180.127",
            subnets: ["169.234.180.0/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1038": [
        {
            range: "10.13.17.0-10.13.17.255",
            subnets: ["10.13.17.0/255.255.255.0"],
        },
        {
            range: "169.234.181.128-169.234.181.255",
            subnets: ["169.234.181.128/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1040": [
        {
            range: "10.13.18.0-10.13.18.255",
            subnets: ["10.13.18.0/255.255.255.0"],
        },
        {
            range: "169.234.181.128-169.234.181.255",
            subnets: ["169.234.181.128/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1042": [
        {
            range: "10.13.19.0-10.13.19.255",
            subnets: ["10.13.19.0/255.255.255.0"],
        },
        {
            range: "169.234.182.0-169.234.182.127",
            subnets: ["169.234.182.0/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1044": [
        {
            range: "10.13.20.0-10.13.20.255",
            subnets: ["10.13.20.0/255.255.255.0"],
        },
        {
            range: "169.234.182.128-169.234.182.255",
            subnets: ["169.234.182.128/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1048": [
        {
            range: "10.13.21.0-10.13.21.255",
            subnets: ["10.13.21.0/255.255.255.0"],
        },
        {
            range: "169.234.182.128-169.234.182.255",
            subnets: ["169.234.182.128/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1050": [
        {
            range: "10.13.22.0-10.13.22.255",
            subnets: ["10.13.22.0/255.255.255.0"],
        },
        {
            range: "169.234.183.0-169.234.183.127",
            subnets: ["169.234.183.0/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1052": [
        {
            range: "10.13.23.0-10.13.23.255",
            subnets: ["10.13.23.0/255.255.255.0"],
        },
        {
            range: "169.234.183.128-169.234.183.255",
            subnets: ["169.234.183.128/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1054": [
        {
            range: "10.13.24.0-10.13.24.255",
            subnets: ["10.13.24.0/255.255.255.0"],
        },
        {
            range: "169.234.183.0-169.234.183.127",
            subnets: ["169.234.183.0/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1056": [
        {
            range: "10.13.25.0-10.13.25.255",
            subnets: ["10.13.25.0/255.255.255.0"],
        },
        {
            range: "169.234.184.0-169.234.184.127",
            subnets: ["169.234.184.0/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1058": [
        {
            range: "10.13.26.0-10.13.26.255",
            subnets: ["10.13.26.0/255.255.255.0"],
        },
        {
            range: "169.234.184.0-169.234.184.127",
            subnets: ["169.234.184.0/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1064": [
        {
            range: "10.13.27.0-10.13.27.255",
            subnets: ["10.13.27.0/255.255.255.0"],
        },
        {
            range: "169.234.184.128-169.234.184.255",
            subnets: ["169.234.184.128/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1066": [
        {
            range: "10.13.28.0-10.13.28.255",
            subnets: ["10.13.28.0/255.255.255.0"],
        },
        {
            range: "169.234.185.0-169.234.185.127",
            subnets: ["169.234.185.0/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1068": [
        {
            range: "10.13.29.0-10.13.29.255",
            subnets: ["10.13.29.0/255.255.255.0"],
        },
        {
            range: "169.234.185.0-169.234.185.127",
            subnets: ["169.234.185.0/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1070": [
        {
            range: "10.13.30.0-10.13.30.255",
            subnets: ["10.13.30.0/255.255.255.0"],
        },
        {
            range: "169.234.185.128-169.234.185.255",
            subnets: ["169.234.185.128/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1074": [
        {
            range: "10.13.31.0-10.13.31.255",
            subnets: ["10.13.31.0/255.255.255.0"],
        },
        {
            range: "169.234.186.0-169.234.186.127",
            subnets: ["169.234.186.0/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1076": [
        {
            range: "10.13.32.0-10.13.32.255",
            subnets: ["10.13.32.0/255.255.255.0"],
        },
        {
            range: "169.234.186.128-169.234.186.255",
            subnets: ["169.234.186.128/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1078": [
        {
            range: "10.13.33.0-10.13.33.255",
            subnets: ["10.13.33.0/255.255.255.0"],
        },
        {
            range: "169.234.187.0-169.234.187.127",
            subnets: ["169.234.187.0/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1080": [
        {
            range: "10.13.34.0-10.13.34.255",
            subnets: ["10.13.34.0/255.255.255.0"],
        },
        {
            range: "169.234.186.128-169.234.186.255",
            subnets: ["169.234.186.128/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1082": [
        {
            range: "10.13.35.0-10.13.35.255",
            subnets: ["10.13.35.0/255.255.255.0"],
        },
        {
            range: "169.234.187.128-169.234.187.255",
            subnets: ["169.234.187.128/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1084": [
        {
            range: "10.13.36.0-10.13.36.255",
            subnets: ["10.13.36.0/255.255.255.0"],
        },
        {
            range: "169.234.187.128-169.234.187.255",
            subnets: ["169.234.187.128/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1086": [
        {
            range: "10.13.37.0-10.13.37.255",
            subnets: ["10.13.37.0/255.255.255.0"],
        },
        {
            range: "169.234.188.0-169.234.188.127",
            subnets: ["169.234.188.0/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1088": [
        {
            range: "10.13.38.0-10.13.38.255",
            subnets: ["10.13.38.0/255.255.255.0"],
        },
        {
            range: "169.234.188.128-169.234.188.255",
            subnets: ["169.234.188.128/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1090": [
        {
            range: "10.13.39.0-10.13.39.255",
            subnets: ["10.13.39.0/255.255.255.0"],
        },
        {
            range: "169.234.189.0-169.234.189.127",
            subnets: ["169.234.189.0/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1092": [
        {
            range: "10.13.40.0-10.13.40.255",
            subnets: ["10.13.40.0/255.255.255.0"],
        },
        {
            range: "169.234.188.0-169.234.188.127",
            subnets: ["169.234.188.0/255.255.255.128"],
        },
    ],
    "Arroyo Vista - 1094": [
        {
            range: "10.13.41.0-10.13.41.255",
            subnets: ["10.13.41.0/255.255.255.0"],
        },
        {
            range: "169.234.189.128-169.234.189.255",
            subnets: ["169.234.189.128/255.255.255.128"],
        },
    ],
    "Campus Village - 1001": [
        {
            range: "10.13.128.0-10.13.128.255",
            subnets: ["10.13.128.0/255.255.255.0"],
        },
    ],
    "Campus Village - 1003": [
        {
            range: "10.13.129.0-10.13.129.255",
            subnets: ["10.13.129.0/255.255.255.0"],
        },
    ],
    "Campus Village - 1005": [
        {
            range: "10.13.130.0-10.13.130.255",
            subnets: ["10.13.130.0/255.255.255.0"],
        },
    ],
    "Campus Village - 1007": [
        {
            range: "10.13.131.0-10.13.131.255",
            subnets: ["10.13.131.0/255.255.255.0"],
        },
    ],
    "Campus Village - 1009": [
        {
            range: "10.13.132.0-10.13.132.255",
            subnets: ["10.13.132.0/255.255.255.0"],
        },
    ],
    "Campus Village - 1011": [
        {
            range: "10.13.133.0-10.13.133.255",
            subnets: ["10.13.133.0/255.255.255.0"],
        },
    ],
    "Campus Village - 1013": [
        {
            range: "10.13.134.0-10.13.134.255",
            subnets: ["10.13.134.0/255.255.255.0"],
        },
    ],
    "Campus Village - 1015": [
        {
            range: "10.13.135.0-10.13.135.255",
            subnets: ["10.13.135.0/255.255.255.0"],
        },
    ],
    "Campus Village - 1017": [
        {
            range: "10.13.136.0-10.13.136.255",
            subnets: ["10.13.136.0/255.255.255.0"],
        },
    ],
    "Campus Village - 1019": [
        {
            range: "10.13.137.0-10.13.137.255",
            subnets: ["10.13.137.0/255.255.255.0"],
        },
    ],
    "Campus Village - 1021": [
        {
            range: "10.13.138.0-10.13.138.255",
            subnets: ["10.13.138.0/255.255.255.0"],
        },
    ],
    "Campus Village - 1023": [
        {
            range: "10.13.139.0-10.13.139.255",
            subnets: ["10.13.139.0/255.255.255.0"],
        },
    ],
    "Campus Village - 1025": [
        {
            range: "10.13.140.0-10.13.140.255",
            subnets: ["10.13.140.0/255.255.255.0"],
        },
    ],
    "Campus Village - 1027": [
        {
            range: "10.13.141.0-10.13.141.255",
            subnets: ["10.13.141.0/255.255.255.0"],
        },
    ],
    "Campus Village - 1029": [
        {
            range: "10.13.142.0-10.13.142.255",
            subnets: ["10.13.142.0/255.255.255.0"],
        },
    ],
    "Campus Village - 1031": [
        {
            range: "10.13.143.0-10.13.143.255",
            subnets: ["10.13.143.0/255.255.255.0"],
        },
    ],
    "Campus Village - 1033": [
        {
            range: "10.13.144.0-10.13.144.255",
            subnets: ["10.13.144.0/255.255.255.0"],
        },
    ],
    "Campus Village - 1035": [
        {
            range: "10.13.145.0-10.13.145.255",
            subnets: ["10.13.145.0/255.255.255.0"],
        },
    ],
    "Campus Village - 1037": [
        {
            range: "10.13.146.0-10.13.146.255",
            subnets: ["10.13.146.0/255.255.255.0"],
        },
    ],
    "Campus Village - 1039": [
        {
            range: "10.13.147.0-10.13.147.255",
            subnets: ["10.13.147.0/255.255.255.0"],
        },
    ],
    "Campus Village - 1041": [
        {
            range: "10.13.148.0-10.13.148.255",
            subnets: ["10.13.148.0/255.255.255.0"],
        },
    ],
    "Campus Village - 1043": [
        {
            range: "10.13.149.0-10.13.149.255",
            subnets: ["10.13.149.0/255.255.255.0"],
        },
    ],
    "Campus Village - 1045": [
        {
            range: "10.13.150.0-10.13.150.255",
            subnets: ["10.13.150.0/255.255.255.0"],
        },
    ],
    "Campus Village - 1047": [
        {
            range: "10.13.151.0-10.13.151.255",
            subnets: ["10.13.151.0/255.255.255.0"],
        },
    ],
    "Campus Village - 1049": [
        {
            range: "10.13.152.0-10.13.152.255",
            subnets: ["10.13.152.0/255.255.255.0"],
        },
    ],
    "Alturas Apartments - 100 and 200": [
        {
            range: "169.234.198.0-169.234.198.127",
            subnets: ["169.234.198.0/255.255.255.128"],
        },
    ],
    "Alturas Apartments - 1100 and 1200": [
        {
            range: "169.234.200.128-169.234.200.255",
            subnets: ["169.234.200.128/255.255.255.128"],
        },
    ],
    "Alturas Apartments - 1300 and 1400": [
        {
            range: "169.234.201.0-169.234.201.127",
            subnets: ["169.234.201.0/255.255.255.128"],
        },
    ],
    "Alturas Apartments - 1500 through 1800": [
        {
            range: "169.234.201.128-169.234.201.255",
            subnets: ["169.234.201.128/255.255.255.128"],
        },
    ],
    "Alturas Apartments - 300 and 400": [
        {
            range: "169.234.198.128-169.234.198.255",
            subnets: ["169.234.198.128/255.255.255.128"],
        },
    ],
    "Alturas Apartments - 500 and 600": [
        {
            range: "169.234.199.0-169.234.199.127",
            subnets: ["169.234.199.0/255.255.255.128"],
        },
    ],
    "Alturas Apartments - 700 and 800": [
        {
            range: "169.234.199.128-169.234.199.255",
            subnets: ["169.234.199.128/255.255.255.128"],
        },
    ],
    "Alturas Apartments - 900 and 1000": [
        {
            range: "169.234.200.0-169.234.200.127",
            subnets: ["169.234.200.0/255.255.255.128"],
        },
    ],
    "Gabrielino Apartments - All Buildings": [
        {
            range: "169.234.251.0-169.234.251.127",
            subnets: ["169.234.251.0/255.255.255.128"],
        },
    ],
    "Las Lomas Apartments - All Buildings": [
        {
            range: "169.234.247.0-169.234.247.255",
            subnets: ["169.234.247.0/255.255.255.0"],
        },
    ],
    "Miramonte Apartments - All Buildings": [
        {
            range: "169.234.246.0-169.234.246.255",
            subnets: ["169.234.246.0/255.255.255.0"],
        },
    ],
    "Santiago Apartments - 100 and 200": [
        {
            range: "169.234.248.0-169.234.248.127",
            subnets: ["169.234.248.0/255.255.255.128"],
        },
    ],
    "Santiago Apartments - 1100": [
        {
            range: "169.234.250.128-169.234.250.191",
            subnets: ["169.234.250.128/255.255.255.192"],
        },
    ],
    "Santiago Apartments - 300 and 400": [
        {
            range: "169.234.248.128-169.234.248.255",
            subnets: ["169.234.248.128/255.255.255.128"],
        },
    ],
    "Santiago Apartments - 500 and 600": [
        {
            range: "169.234.249.0-169.234.249.127",
            subnets: ["169.234.249.0/255.255.255.128"],
        },
    ],
    "Santiago Apartments - 700 and 800": [
        {
            range: "169.234.249.128-169.234.249.255",
            subnets: ["169.234.249.128/255.255.255.128"],
        },
    ],
    "Santiago Apartments - 900 and 1000": [
        {
            range: "169.234.250.0-169.234.250.127",
            subnets: ["169.234.250.0/255.255.255.128"],
        },
    ],
    "Mesa Court - Aldea": [
        {
            range: "10.12.78.0-10.12.79.255",
            subnets: ["10.12.78.0/255.255.254.0"],
        },
        {
            range: "169.234.149.0-169.234.149.255",
            subnets: ["169.234.149.0/255.255.255.0"],
        },
    ],
    "Mesa Court - Barranca": [
        {
            range: "10.12.72.0-10.12.73.255",
            subnets: ["10.12.72.0/255.255.254.0"],
        },
        {
            range: "169.234.146.0-169.234.146.255",
            subnets: ["169.234.146.0/255.255.255.0"],
        },
    ],
    "Mesa Court - Cascada": [
        {
            range: "10.12.70.0-10.12.71.255",
            subnets: ["10.12.70.0/255.255.254.0"],
        },
        {
            range: "169.234.145.0-169.234.145.255",
            subnets: ["169.234.145.0/255.255.255.0"],
        },
    ],
    "Mesa Court - Isla": [
        {
            range: "10.12.74.0-10.12.75.255",
            subnets: ["10.12.74.0/255.255.254.0"],
        },
        {
            range: "169.234.147.0-169.234.147.255",
            subnets: ["169.234.147.0/255.255.255.0"],
        },
    ],
    "Mesa Court - Jardin": [
        {
            range: "10.12.76.0-10.12.77.255",
            subnets: ["10.12.76.0/255.255.254.0"],
        },
        {
            range: "169.234.148.0-169.234.148.255",
            subnets: ["169.234.148.0/255.255.255.0"],
        },
    ],
    "Mesa Court - Laguna": [
        {
            range: "10.12.80.0-10.12.81.255",
            subnets: ["10.12.80.0/255.255.254.0"],
        },
        {
            range: "169.234.150.0-169.234.150.255",
            subnets: ["169.234.150.0/255.255.255.0"],
        },
    ],
    "Mesa Court - Lluvia": [
        {
            range: "10.12.64.0-10.12.65.255",
            subnets: ["10.12.64.0/255.255.254.0"],
        },
        {
            range: "169.234.142.0-169.234.142.255",
            subnets: ["169.234.142.0/255.255.255.0"],
        },
    ],
    "Mesa Court - Niebla": [
        {
            range: "10.12.68.0-10.12.69.255",
            subnets: ["10.12.68.0/255.255.254.0"],
        },
        {
            range: "169.234.144.0-169.234.144.255",
            subnets: ["169.234.144.0/255.255.255.0"],
        },
    ],
    "Mesa Court - Ondas": [
        {
            range: "10.12.66.0-10.12.67.255",
            subnets: ["10.12.66.0/255.255.254.0"],
        },
        {
            range: "169.234.143.0-169.234.143.255",
            subnets: ["169.234.143.0/255.255.255.0"],
        },
    ],
    "Mesa Court - Selva": [
        {
            range: "10.12.82.0-10.12.83.255",
            subnets: ["10.12.82.0/255.255.254.0"],
        },
        {
            range: "169.234.151.0-169.234.151.255",
            subnets: ["169.234.151.0/255.255.255.0"],
        },
    ],
    "Mesa Court - Arroyo": [
        {
            range: "10.12.46.0-10.12.47.255",
            subnets: ["10.12.46.0/255.255.254.0"],
        },
        {
            range: "169.234.141.127-169.234.141.255",
            subnets: [
                "169.234.141.127/255.255.255.255",
                "169.234.141.128/255.255.255.128",
            ],
        },
    ],
    "Mesa Court - Bahia": [
        {
            range: "10.12.38.0-10.12.39.255",
            subnets: ["10.12.38.0/255.255.254.0"],
        },
        {
            range: "169.234.139.128-169.234.139.255",
            subnets: ["169.234.139.128/255.255.255.128"],
        },
    ],
    "Mesa Court - Cielo": [
        {
            range: "10.12.32.0-10.12.33.255",
            subnets: ["10.12.32.0/255.255.254.0"],
        },
        {
            range: "169.234.138.0-169.234.138.127",
            subnets: ["169.234.138.0/255.255.255.128"],
        },
    ],
    "Mesa Court - Cuesta": [
        {
            range: "10.12.40.0-10.12.41.255",
            subnets: ["10.12.40.0/255.255.254.0"],
        },
        {
            range: "169.234.140.0-169.234.140.127",
            subnets: ["169.234.140.0/255.255.255.128"],
        },
    ],
    "Mesa Court - Lago": [
        {
            range: "10.12.34.0-10.12.35.255",
            subnets: ["10.12.34.0/255.255.254.0"],
        },
        {
            range: "169.234.138.128-169.234.138.255",
            subnets: ["169.234.138.128/255.255.255.128"],
        },
    ],
    "Mesa Court - Loma": [
        {
            range: "10.12.44.0-10.12.45.255",
            subnets: ["10.12.44.0/255.255.254.0"],
        },
        {
            range: "169.234.141.0-169.234.141.127",
            subnets: ["169.234.141.0/255.255.255.128"],
        },
    ],
    "Mesa Court - Sierra": [
        {
            range: "10.12.36.0-10.12.37.255",
            subnets: ["10.12.36.0/255.255.254.0"],
        },
        {
            range: "169.234.139.0-169.234.139.127",
            subnets: ["169.234.139.0/255.255.255.128"],
        },
    ],
    "Mesa Court - Viento": [
        {
            range: "10.12.42.0-10.12.43.255",
            subnets: ["10.12.42.0/255.255.254.0"],
        },
        {
            range: "169.234.140.128-169.234.140.255",
            subnets: ["169.234.140.128/255.255.255.128"],
        },
    ],
    "Mesa Court - Brisa": [
        {
            range: "10.12.2.0-10.12.3.255",
            subnets: ["10.12.2.0/255.255.254.0"],
        },
        {
            range: "169.234.129.0-169.234.129.255",
            subnets: ["169.234.129.0/255.255.255.0"],
        },
    ],
    "Mesa Court - Camino": [
        {
            range: "10.12.10.0-10.12.11.255",
            subnets: ["10.12.10.0/255.255.254.0"],
        },
        {
            range: "169.234.133.0-169.234.133.127",
            subnets: ["169.234.133.0/255.255.255.128"],
        },
    ],
    "Mesa Court - Ciudad": [
        {
            range: "10.12.12.0-10.12.13.255",
            subnets: ["10.12.12.0/255.255.254.0"],
        },
        {
            range: "169.234.133.128-169.234.133.255",
            subnets: ["169.234.133.128/255.255.255.128"],
        },
    ],
    "Mesa Court - Cumbre": [
        {
            range: "10.12.8.0-10.12.9.255",
            subnets: ["10.12.8.0/255.255.254.0"],
        },
        {
            range: "169.234.132.0-169.234.132.255",
            subnets: ["169.234.132.0/255.255.255.0"],
        },
    ],
    "Mesa Court - Estrella": [
        {
            range: "10.12.16.0-10.12.17.255",
            subnets: ["10.12.16.0/255.255.254.0"],
        },
        {
            range: "169.234.135.0-169.234.135.255",
            subnets: ["169.234.135.0/255.255.255.0"],
        },
    ],
    "Mesa Court - Nubes": [
        {
            range: "10.12.20.0-10.12.21.255",
            subnets: ["10.12.20.0/255.255.254.0"],
        },
        {
            range: "169.234.137.0-169.234.137.255",
            subnets: ["169.234.137.0/255.255.255.0"],
        },
    ],
    "Mesa Court - Otero": [
        {
            range: "10.12.0.0-10.12.1.255",
            subnets: ["10.12.0.0/255.255.254.0"],
        },
        {
            range: "169.234.128.0-169.234.128.255",
            subnets: ["169.234.128.0/255.255.255.0"],
        },
    ],
    "Mesa Court - Palo": [
        {
            range: "10.12.18.0-10.12.19.255",
            subnets: ["10.12.18.0/255.255.254.0"],
        },
        {
            range: "169.234.136.0-169.234.136.255",
            subnets: ["169.234.136.0/255.255.255.0"],
        },
    ],
    "Mesa Court - Prado": [
        {
            range: "10.12.4.0-10.12.5.255",
            subnets: ["10.12.4.0/255.255.254.0"],
        },
        {
            range: "169.234.130.0-169.234.130.255",
            subnets: ["169.234.130.0/255.255.255.0"],
        },
    ],
    "Mesa Court - Puente": [
        {
            range: "10.12.6.0-10.12.7.255",
            subnets: ["10.12.6.0/255.255.254.0"],
        },
        {
            range: "169.234.131.0-169.234.131.255",
            subnets: ["169.234.131.0/255.255.255.0"],
        },
    ],
    "Mesa Court - Vista": [
        {
            range: "10.12.14.0-10.12.15.255",
            subnets: ["10.12.14.0/255.255.254.0"],
        },
        {
            range: "169.234.134.0-169.234.134.255",
            subnets: ["169.234.134.0/255.255.255.0"],
        },
    ],
    "Mesa Court - Towers": [
        {
            range: "10.12.84.0-10.12.85.255",
            subnets: ["10.12.84.0/255.255.254.0"],
        },
    ],
    "Middle Earth - Calmindon and Grey Havens": [
        {
            range: "10.12.172.0-10.12.175.255",
            subnets: ["10.12.172.0/255.255.252.0"],
        },
        {
            range: "169.234.170.0-169.234.171.255",
            subnets: ["169.234.170.0/255.255.254.0"],
        },
    ],
    "Middle Earth - Elrond and Shadowfax": [
        {
            range: "10.12.160.0-10.12.163.255",
            subnets: ["10.12.160.0/255.255.252.0"],
        },
        {
            range: "169.234.164.0-169.234.165.255",
            subnets: ["169.234.164.0/255.255.254.0"],
        },
    ],
    "Middle Earth - Harrowdale and Balin": [
        {
            range: "10.12.180.0-10.12.183.255",
            subnets: ["10.12.180.0/255.255.252.0"],
        },
        {
            range: "169.234.174.0-169.234.175.255",
            subnets: ["169.234.174.0/255.255.254.0"],
        },
    ],
    "Middle Earth - Quenya": [
        {
            range: "10.12.184.0-10.12.187.255",
            subnets: ["10.12.184.0/255.255.252.0"],
        },
        {
            range: "169.234.203.0-169.234.203.255",
            subnets: ["169.234.203.0/255.255.255.0"],
        },
    ],
    "Middle Earth - Rohan and Aldor": [
        {
            range: "10.12.164.0-10.12.167.255",
            subnets: ["10.12.164.0/255.255.252.0"],
        },
        {
            range: "169.234.166.0-169.234.167.255",
            subnets: ["169.234.166.0/255.255.254.0"],
        },
    ],
    "Middle Earth - Snowbourn and Gondolin": [
        {
            range: "10.12.168.0-10.12.171.255",
            subnets: ["10.12.168.0/255.255.252.0"],
        },
        {
            range: "169.234.168.0-169.234.169.255",
            subnets: ["169.234.168.0/255.255.254.0"],
        },
    ],
    "Middle Earth - Whispering Wood and Woodhall": [
        {
            range: "10.12.176.0-10.12.179.255",
            subnets: ["10.12.176.0/255.255.252.0"],
        },
        {
            range: "169.234.172.0-169.234.173.255",
            subnets: ["169.234.172.0/255.255.254.0"],
        },
    ],
    "Middle Earth - Crickhollow": [
        {
            range: "10.12.142.0-10.12.143.255",
            subnets: ["10.12.142.0/255.255.254.0"],
        },
        {
            range: "169.234.160.0-169.234.160.255",
            subnets: ["169.234.160.0/255.255.255.0"],
        },
    ],
    "Middle Earth - Evenstar": [
        {
            range: "10.12.146.0-10.12.147.255",
            subnets: ["10.12.146.0/255.255.254.0"],
        },
        {
            range: "169.234.162.0-169.234.162.255",
            subnets: ["169.234.162.0/255.255.255.0"],
        },
    ],
    "Middle Earth - Hobbiton": [
        {
            range: "10.12.136.0-10.12.137.255",
            subnets: ["10.12.136.0/255.255.254.0"],
        },
        {
            range: "169.234.158.0-169.234.158.127",
            subnets: ["169.234.158.0/255.255.255.128"],
        },
    ],
    "Middle Earth - Isengard": [
        {
            range: "10.12.132.0-10.12.133.255",
            subnets: ["10.12.132.0/255.255.254.0"],
        },
        {
            range: "169.234.157.0-169.234.157.127",
            subnets: ["169.234.157.0/255.255.255.128"],
        },
    ],
    "Middle Earth - Lorien": [
        {
            range: "10.12.128.0-10.12.129.255",
            subnets: ["10.12.128.0/255.255.254.0"],
        },
        {
            range: "169.234.156.0-169.234.156.127",
            subnets: ["169.234.156.0/255.255.255.128"],
        },
    ],
    "Middle Earth - Mirkwood": [
        {
            range: "10.12.134.0-10.12.135.255",
            subnets: ["10.12.134.0/255.255.254.0"],
        },
        {
            range: "169.234.157.128-169.234.157.255",
            subnets: ["169.234.157.128/255.255.255.128"],
        },
    ],
    "Middle Earth - Misty Mountain": [
        {
            range: "10.12.138.0-10.12.139.255",
            subnets: ["10.12.138.0/255.255.254.0"],
        },
        {
            range: "169.234.158.128-169.234.158.255",
            subnets: ["169.234.158.128/255.255.255.128"],
        },
    ],
    "Middle Earth - Oakenshield": [
        {
            range: "10.12.144.0-10.12.145.255",
            subnets: ["10.12.144.0/255.255.254.0"],
        },
        {
            range: "169.234.161.0-169.234.161.255",
            subnets: ["169.234.161.0/255.255.255.0"],
        },
    ],
    "Middle Earth - Rivendell": [
        {
            range: "10.12.130.0-10.12.131.255",
            subnets: ["10.12.130.0/255.255.254.0"],
        },
        {
            range: "169.234.156.128-169.234.156.255",
            subnets: ["169.234.156.128/255.255.255.128"],
        },
    ],
    "Middle Earth - The Shire": [
        {
            range: "10.12.140.0-10.12.141.255",
            subnets: ["10.12.140.0/255.255.254.0"],
        },
        {
            range: "169.234.159.0-169.234.159.127",
            subnets: ["169.234.159.0/255.255.255.128"],
        },
    ],
    "Middle Earth - Valimar": [
        {
            range: "10.12.148.0-10.12.149.255",
            subnets: ["10.12.148.0/255.255.254.0"],
        },
        {
            range: "169.234.163.0-169.234.163.255",
            subnets: ["169.234.163.0/255.255.255.0"],
        },
    ],
    "Middle Earth - Towers & Pippin": [
        {
            range: "10.12.188.0-10.12.191.255",
            subnets: ["10.12.188.0/255.255.252.0"],
        },
    ],
    "OIT Trailers - RNC Office": [
        {
            range: "10.13.65.0-10.13.65.255",
            subnets: ["10.13.65.0/255.255.255.0"],
        },
        {
            range: "169.234.254.128-169.234.254.255",
            subnets: ["169.234.254.128/255.255.255.128"],
        },
    ],
    "OIT Trailers - Walk-up": [
        {
            range: "10.13.64.0-10.13.64.255",
            subnets: ["10.13.64.0/255.255.255.0"],
        },
    ],
    "OIT Trailers - Walk-up, Public": [
        {
            range: "169.234.255.160-169.234.255.191",
            subnets: ["169.234.255.160/255.255.255.224"],
        },
    ],
    "Palo Verde - 1100 and 1200": [
        {
            range: "169.234.227.0-169.234.227.127",
            subnets: ["169.234.227.0/255.255.255.128"],
        },
    ],
    "Palo Verde - 1300 and 1400": [
        {
            range: "169.234.227.128-169.234.227.255",
            subnets: ["169.234.227.128/255.255.255.128"],
        },
    ],
    "Palo Verde - 2100 and 2200": [
        {
            range: "169.234.228.0-169.234.228.127",
            subnets: ["169.234.228.0/255.255.255.128"],
        },
    ],
    "Palo Verde - 3100 and 3200": [
        {
            range: "169.234.228.128-169.234.228.255",
            subnets: ["169.234.228.128/255.255.255.128"],
        },
    ],
    "Palo Verde - 3300 and 4100": [
        {
            range: "169.234.229.0-169.234.229.127",
            subnets: ["169.234.229.0/255.255.255.128"],
        },
    ],
    "Palo Verde - 4200 and 4300": [
        {
            range: "169.234.229.128-169.234.229.255",
            subnets: ["169.234.229.128/255.255.255.128"],
        },
    ],
    "Palo Verde - 4400 and 5100": [
        {
            range: "169.234.230.0-169.234.230.127",
            subnets: ["169.234.230.0/255.255.255.128"],
        },
    ],
    "Palo Verde - 5200 and 5300": [
        {
            range: "169.234.230.128-169.234.230.255",
            subnets: ["169.234.230.128/255.255.255.128"],
        },
    ],
    "Palo Verde - 6100 and 6200": [
        {
            range: "169.234.231.0-169.234.231.127",
            subnets: ["169.234.231.0/255.255.255.128"],
        },
    ],
    "Palo Verde - 6300 and 7000": [
        {
            range: "169.234.231.128-169.234.231.255",
            subnets: ["169.234.231.128/255.255.255.128"],
        },
    ],
    "Palo Verde - 7100": [
        {
            range: "169.234.232.0-169.234.232.255",
            subnets: ["169.234.232.0/255.255.255.0"],
        },
    ],
    "Palo Verde - 7200": [
        {
            range: "169.234.233.0-169.234.233.255",
            subnets: ["169.234.233.0/255.255.255.0"],
        },
    ],
    "Palo Verde - 7300": [
        {
            range: "169.234.234.0-169.234.234.255",
            subnets: ["169.234.234.0/255.255.255.0"],
        },
    ],
    "Palo Verde - 7400": [
        {
            range: "169.234.235.0-169.234.235.255",
            subnets: ["169.234.235.0/255.255.255.0"],
        },
    ],
    "Palo Verde - 7500": [
        {
            range: "169.234.236.0-169.234.236.255",
            subnets: ["169.234.236.0/255.255.255.0"],
        },
    ],
    "Palo Verde - 7600": [
        {
            range: "169.234.237.0-169.234.237.255",
            subnets: ["169.234.237.0/255.255.255.0"],
        },
    ],
    "Palo Verde - 8100": [
        {
            range: "169.234.238.0-169.234.238.255",
            subnets: ["169.234.238.0/255.255.255.0"],
        },
    ],
    "Palo Verde - 8200": [
        {
            range: "169.234.239.0-169.234.239.255",
            subnets: ["169.234.239.0/255.255.255.0"],
        },
    ],
    "Palo Verde - 8300": [
        {
            range: "169.234.240.0-169.234.240.255",
            subnets: ["169.234.240.0/255.255.255.0"],
        },
    ],
    "Palo Verde - 8400": [
        {
            range: "169.234.241.0-169.234.241.255",
            subnets: ["169.234.241.0/255.255.255.0"],
        },
    ],
    "Palo Verde - 8500": [
        {
            range: "169.234.242.0-169.234.242.255",
            subnets: ["169.234.242.0/255.255.255.0"],
        },
    ],
    "Palo Verde - 8600": [
        {
            range: "169.234.243.0-169.234.243.255",
            subnets: ["169.234.243.0/255.255.255.0"],
        },
    ],
    "Palo Verde - 8700": [
        {
            range: "169.234.244.0-169.234.244.255",
            subnets: ["169.234.244.0/255.255.255.0"],
        },
    ],
    "Palo Verde - 8800": [
        {
            range: "169.234.245.0-169.234.245.255",
            subnets: ["169.234.245.0/255.255.255.0"],
        },
    ],
    "Verano Place - 100": [
        {
            range: "169.234.205.128-169.234.205.255",
            subnets: ["169.234.205.128/255.255.255.128"],
        },
    ],
    "Verano Place - 1100 and 1200": [
        {
            range: "169.234.208.128-169.234.208.255",
            subnets: ["169.234.208.128/255.255.255.128"],
        },
    ],
    "Verano Place - 1300 and 1400": [
        {
            range: "169.234.209.0-169.234.209.127",
            subnets: ["169.234.209.0/255.255.255.128"],
        },
    ],
    "Verano Place - 1500 and 1600": [
        {
            range: "169.234.209.128-169.234.209.255",
            subnets: ["169.234.209.128/255.255.255.128"],
        },
    ],
    "Verano Place - 1700 and 1800": [
        {
            range: "169.234.210.0-169.234.210.127",
            subnets: ["169.234.210.0/255.255.255.128"],
        },
    ],
    "Verano Place - 1900 and 2000": [
        {
            range: "169.234.210.128-169.234.210.255",
            subnets: ["169.234.210.128/255.255.255.128"],
        },
    ],
    "Verano Place - 200": [
        {
            range: "169.234.206.0-169.234.206.127",
            subnets: ["169.234.206.0/255.255.255.128"],
        },
    ],
    "Verano Place - 2100 and 2200": [
        {
            range: "169.234.211.0-169.234.211.127",
            subnets: ["169.234.211.0/255.255.255.128"],
        },
    ],
    "Verano Place - 2300 and 2400": [
        {
            range: "169.234.211.128-169.234.211.255",
            subnets: ["169.234.211.128/255.255.255.128"],
        },
    ],
    "Verano Place - 2500 and 2600": [
        {
            range: "169.234.212.0-169.234.212.127",
            subnets: ["169.234.212.0/255.255.255.128"],
        },
    ],
    "Verano Place - 2700 and 2800": [
        {
            range: "169.234.212.128-169.234.212.255",
            subnets: ["169.234.212.128/255.255.255.128"],
        },
    ],
    "Verano Place - 2900 and 3000": [
        {
            range: "169.234.213.0-169.234.213.127",
            subnets: ["169.234.213.0/255.255.255.128"],
        },
    ],
    "Verano Place - 300 and 400": [
        {
            range: "169.234.206.128-169.234.206.255",
            subnets: ["169.234.206.128/255.255.255.128"],
        },
    ],
    "Verano Place - 3100 and 3200": [
        {
            range: "169.234.213.128-169.234.213.255",
            subnets: ["169.234.213.128/255.255.255.128"],
        },
    ],
    "Verano Place - 500 and 600": [
        {
            range: "169.234.207.0-169.234.207.127",
            subnets: ["169.234.207.0/255.255.255.128"],
        },
    ],
    "Verano Place - 700 and 800": [
        {
            range: "169.234.207.128-169.234.207.255",
            subnets: ["169.234.207.128/255.255.255.128"],
        },
    ],
    "Verano Place - 900 and 1000": [
        {
            range: "169.234.208.0-169.234.208.127",
            subnets: ["169.234.208.0/255.255.255.128"],
        },
    ],
    "Verano Place - 66000": [
        {
            range: "169.234.214.0-169.234.214.255",
            subnets: ["169.234.214.0/255.255.255.0"],
        },
    ],
    "Verano Place - 67000": [
        {
            range: "169.234.215.0-169.234.215.255",
            subnets: ["169.234.215.0/255.255.255.0"],
        },
    ],
    "Verano Place - 68000": [
        {
            range: "169.234.216.0-169.234.216.255",
            subnets: ["169.234.216.0/255.255.255.0"],
        },
    ],
    "Verano Place - 69000": [
        {
            range: "169.234.217.0-169.234.217.255",
            subnets: ["169.234.217.0/255.255.255.0"],
        },
    ],
    "Verano Place - 6200 and 6220": [
        {
            range: "169.234.218.0-169.234.218.127",
            subnets: ["169.234.218.0/255.255.255.128"],
        },
    ],
    "Verano Place - 6222 and 6230": [
        {
            range: "169.234.218.128-169.234.218.255",
            subnets: ["169.234.218.128/255.255.255.128"],
        },
    ],
    "Verano Place - 6238 and 6253": [
        {
            range: "169.234.219.0-169.234.219.127",
            subnets: ["169.234.219.0/255.255.255.128"],
        },
    ],
    "Verano Place - 6255 and 6263": [
        {
            range: "169.234.219.128-169.234.219.255",
            subnets: ["169.234.219.128/255.255.255.128"],
        },
    ],
    "Verano Place - 6271 and 6286": [
        {
            range: "169.234.220.0-169.234.220.127",
            subnets: ["169.234.220.0/255.255.255.128"],
        },
    ],
    "Verano Place - 6287 and 6295": [
        {
            range: "169.234.220.128-169.234.220.255",
            subnets: ["169.234.220.128/255.255.255.128"],
        },
    ],
    "Verano Place - 6303 and 6319": [
        {
            range: "169.234.221.0-169.234.221.127",
            subnets: ["169.234.221.0/255.255.255.128"],
        },
    ],
    "Verano Place - 6321 and 6329": [
        {
            range: "169.234.221.128-169.234.221.255",
            subnets: ["169.234.221.128/255.255.255.128"],
        },
    ],
    "Verano Place - 6337 and 6352": [
        {
            range: "169.234.222.0-169.234.222.127",
            subnets: ["169.234.222.0/255.255.255.128"],
        },
    ],
    "Verano Place - 6355 and 6363": [
        {
            range: "169.234.222.128-169.234.222.255",
            subnets: ["169.234.222.128/255.255.255.128"],
        },
    ],
    "Verano Place - 6371 and 6386": [
        {
            range: "169.234.223.0-169.234.223.127",
            subnets: ["169.234.223.0/255.255.255.128"],
        },
    ],
    "Verano Place - 6387 and 6395": [
        {
            range: "169.234.223.128-169.234.223.255",
            subnets: ["169.234.223.128/255.255.255.128"],
        },
    ],
    "Verano Place - 6403 and 6419": [
        {
            range: "169.234.224.0-169.234.224.127",
            subnets: ["169.234.224.0/255.255.255.128"],
        },
    ],
    "Verano Place - 6437 and 6456": [
        {
            range: "169.234.224.128-169.234.224.255",
            subnets: ["169.234.224.128/255.255.255.128"],
        },
    ],
    "Verano Place - 6457 and 6476": [
        {
            range: "169.234.225.0-169.234.225.127",
            subnets: ["169.234.225.0/255.255.255.128"],
        },
    ],
    "Verano Place - 6478 and 6493": [
        {
            range: "169.234.225.128-169.234.225.255",
            subnets: ["169.234.225.128/255.255.255.128"],
        },
    ],
    "Verano Place - 6495 and 6511": [
        {
            range: "169.234.226.0-169.234.226.127",
            subnets: ["169.234.226.0/255.255.255.128"],
        },
    ],
    "Verano Place - 6512 and 6528": [
        {
            range: "169.234.226.128-169.234.226.255",
            subnets: ["169.234.226.128/255.255.255.128"],
        },
    ],
}

export const RESNET_SUBNET_TO_SEGMENT = {
    "10.13.0.0/255.255.255.0": "Arroyo Vista - 1002",
    "169.234.176.0/255.255.255.128": "Arroyo Vista - 1006",
    "10.13.1.0/255.255.255.0": "Arroyo Vista - 1006",
    "10.13.2.0/255.255.255.0": "Arroyo Vista - 1008",
    "169.234.176.128/255.255.255.128": "Arroyo Vista - 1010",
    "10.13.3.0/255.255.255.0": "Arroyo Vista - 1010",
    "10.13.4.0/255.255.255.0": "Arroyo Vista - 1012",
    "169.234.177.0/255.255.255.128": "Arroyo Vista - 1012",
    "10.13.5.0/255.255.255.0": "Arroyo Vista - 1014",
    "169.234.177.128/255.255.255.128": "Arroyo Vista - 1016",
    "10.13.6.0/255.255.255.0": "Arroyo Vista - 1016",
    "10.13.7.0/255.255.255.0": "Arroyo Vista - 1018",
    "169.234.178.0/255.255.255.128": "Arroyo Vista - 1022",
    "10.13.8.0/255.255.255.0": "Arroyo Vista - 1020",
    "169.234.178.128/255.255.255.128": "Arroyo Vista - 1020",
    "10.13.9.0/255.255.255.0": "Arroyo Vista - 1022",
    "10.13.10.0/255.255.255.0": "Arroyo Vista - 1024",
    "169.234.179.0/255.255.255.128": "Arroyo Vista - 1026",
    "10.13.11.0/255.255.255.0": "Arroyo Vista - 1026",
    "10.13.12.0/255.255.255.0": "Arroyo Vista - 1028",
    "169.234.179.128/255.255.255.128": "Arroyo Vista - 1028",
    "10.13.13.0/255.255.255.0": "Arroyo Vista - 1030",
    "169.234.180.0/255.255.255.128": "Arroyo Vista - 1036",
    "10.13.14.0/255.255.255.0": "Arroyo Vista - 1032",
    "169.234.180.128/255.255.255.128": "Arroyo Vista - 1032",
    "10.13.15.0/255.255.255.0": "Arroyo Vista - 1034",
    "169.234.181.0/255.255.255.128": "Arroyo Vista - 1034",
    "10.13.16.0/255.255.255.0": "Arroyo Vista - 1036",
    "10.13.17.0/255.255.255.0": "Arroyo Vista - 1038",
    "169.234.181.128/255.255.255.128": "Arroyo Vista - 1040",
    "10.13.18.0/255.255.255.0": "Arroyo Vista - 1040",
    "10.13.19.0/255.255.255.0": "Arroyo Vista - 1042",
    "169.234.182.0/255.255.255.128": "Arroyo Vista - 1042",
    "10.13.20.0/255.255.255.0": "Arroyo Vista - 1044",
    "169.234.182.128/255.255.255.128": "Arroyo Vista - 1048",
    "10.13.21.0/255.255.255.0": "Arroyo Vista - 1048",
    "10.13.22.0/255.255.255.0": "Arroyo Vista - 1050",
    "169.234.183.0/255.255.255.128": "Arroyo Vista - 1054",
    "10.13.23.0/255.255.255.0": "Arroyo Vista - 1052",
    "169.234.183.128/255.255.255.128": "Arroyo Vista - 1052",
    "10.13.24.0/255.255.255.0": "Arroyo Vista - 1054",
    "10.13.25.0/255.255.255.0": "Arroyo Vista - 1056",
    "169.234.184.0/255.255.255.128": "Arroyo Vista - 1058",
    "10.13.26.0/255.255.255.0": "Arroyo Vista - 1058",
    "10.13.27.0/255.255.255.0": "Arroyo Vista - 1064",
    "169.234.184.128/255.255.255.128": "Arroyo Vista - 1064",
    "10.13.28.0/255.255.255.0": "Arroyo Vista - 1066",
    "169.234.185.0/255.255.255.128": "Arroyo Vista - 1068",
    "10.13.29.0/255.255.255.0": "Arroyo Vista - 1068",
    "10.13.30.0/255.255.255.0": "Arroyo Vista - 1070",
    "169.234.185.128/255.255.255.128": "Arroyo Vista - 1070",
    "10.13.31.0/255.255.255.0": "Arroyo Vista - 1074",
    "169.234.186.0/255.255.255.128": "Arroyo Vista - 1074",
    "10.13.32.0/255.255.255.0": "Arroyo Vista - 1076",
    "169.234.186.128/255.255.255.128": "Arroyo Vista - 1080",
    "10.13.33.0/255.255.255.0": "Arroyo Vista - 1078",
    "169.234.187.0/255.255.255.128": "Arroyo Vista - 1078",
    "10.13.34.0/255.255.255.0": "Arroyo Vista - 1080",
    "10.13.35.0/255.255.255.0": "Arroyo Vista - 1082",
    "169.234.187.128/255.255.255.128": "Arroyo Vista - 1084",
    "10.13.36.0/255.255.255.0": "Arroyo Vista - 1084",
    "10.13.37.0/255.255.255.0": "Arroyo Vista - 1086",
    "169.234.188.0/255.255.255.128": "Arroyo Vista - 1092",
    "10.13.38.0/255.255.255.0": "Arroyo Vista - 1088",
    "169.234.188.128/255.255.255.128": "Arroyo Vista - 1088",
    "10.13.39.0/255.255.255.0": "Arroyo Vista - 1090",
    "169.234.189.0/255.255.255.128": "Arroyo Vista - 1090",
    "10.13.40.0/255.255.255.0": "Arroyo Vista - 1092",
    "10.13.41.0/255.255.255.0": "Arroyo Vista - 1094",
    "169.234.189.128/255.255.255.128": "Arroyo Vista - 1094",
    "10.13.128.0/255.255.255.0": "Campus Village - 1001",
    "10.13.129.0/255.255.255.0": "Campus Village - 1003",
    "10.13.130.0/255.255.255.0": "Campus Village - 1005",
    "10.13.131.0/255.255.255.0": "Campus Village - 1007",
    "10.13.132.0/255.255.255.0": "Campus Village - 1009",
    "10.13.133.0/255.255.255.0": "Campus Village - 1011",
    "10.13.134.0/255.255.255.0": "Campus Village - 1013",
    "10.13.135.0/255.255.255.0": "Campus Village - 1015",
    "10.13.136.0/255.255.255.0": "Campus Village - 1017",
    "10.13.137.0/255.255.255.0": "Campus Village - 1019",
    "10.13.138.0/255.255.255.0": "Campus Village - 1021",
    "10.13.139.0/255.255.255.0": "Campus Village - 1023",
    "10.13.140.0/255.255.255.0": "Campus Village - 1025",
    "10.13.141.0/255.255.255.0": "Campus Village - 1027",
    "10.13.142.0/255.255.255.0": "Campus Village - 1029",
    "10.13.143.0/255.255.255.0": "Campus Village - 1031",
    "10.13.144.0/255.255.255.0": "Campus Village - 1033",
    "10.13.145.0/255.255.255.0": "Campus Village - 1035",
    "10.13.146.0/255.255.255.0": "Campus Village - 1037",
    "10.13.147.0/255.255.255.0": "Campus Village - 1039",
    "10.13.148.0/255.255.255.0": "Campus Village - 1041",
    "10.13.149.0/255.255.255.0": "Campus Village - 1043",
    "10.13.150.0/255.255.255.0": "Campus Village - 1045",
    "10.13.151.0/255.255.255.0": "Campus Village - 1047",
    "10.13.152.0/255.255.255.0": "Campus Village - 1049",
    "169.234.198.0/255.255.255.128": "Alturas Apartments - 100 and 200",
    "169.234.200.128/255.255.255.128": "Alturas Apartments - 1100 and 1200",
    "169.234.201.0/255.255.255.128": "Alturas Apartments - 1300 and 1400",
    "169.234.201.128/255.255.255.128": "Alturas Apartments - 1500 through 1800",
    "169.234.198.128/255.255.255.128": "Alturas Apartments - 300 and 400",
    "169.234.199.0/255.255.255.128": "Alturas Apartments - 500 and 600",
    "169.234.199.128/255.255.255.128": "Alturas Apartments - 700 and 800",
    "169.234.200.0/255.255.255.128": "Alturas Apartments - 900 and 1000",
    "169.234.251.0/255.255.255.128": "Gabrielino Apartments - All Buildings",
    "169.234.247.0/255.255.255.0": "Las Lomas Apartments - All Buildings",
    "169.234.246.0/255.255.255.0": "Miramonte Apartments - All Buildings",
    "169.234.248.0/255.255.255.128": "Santiago Apartments - 100 and 200",
    "169.234.250.128/255.255.255.192": "Santiago Apartments - 1100",
    "169.234.248.128/255.255.255.128": "Santiago Apartments - 300 and 400",
    "169.234.249.0/255.255.255.128": "Santiago Apartments - 500 and 600",
    "169.234.249.128/255.255.255.128": "Santiago Apartments - 700 and 800",
    "169.234.250.0/255.255.255.128": "Santiago Apartments - 900 and 1000",
    "10.12.78.0/255.255.254.0": "Mesa Court - Aldea",
    "169.234.149.0/255.255.255.0": "Mesa Court - Aldea",
    "10.12.72.0/255.255.254.0": "Mesa Court - Barranca",
    "169.234.146.0/255.255.255.0": "Mesa Court - Barranca",
    "10.12.70.0/255.255.254.0": "Mesa Court - Cascada",
    "169.234.145.0/255.255.255.0": "Mesa Court - Cascada",
    "10.12.74.0/255.255.254.0": "Mesa Court - Isla",
    "169.234.147.0/255.255.255.0": "Mesa Court - Isla",
    "10.12.76.0/255.255.254.0": "Mesa Court - Jardin",
    "169.234.148.0/255.255.255.0": "Mesa Court - Jardin",
    "10.12.80.0/255.255.254.0": "Mesa Court - Laguna",
    "169.234.150.0/255.255.255.0": "Mesa Court - Laguna",
    "10.12.64.0/255.255.254.0": "Mesa Court - Lluvia",
    "169.234.142.0/255.255.255.0": "Mesa Court - Lluvia",
    "10.12.68.0/255.255.254.0": "Mesa Court - Niebla",
    "169.234.144.0/255.255.255.0": "Mesa Court - Niebla",
    "10.12.66.0/255.255.254.0": "Mesa Court - Ondas",
    "169.234.143.0/255.255.255.0": "Mesa Court - Ondas",
    "10.12.82.0/255.255.254.0": "Mesa Court - Selva",
    "169.234.151.0/255.255.255.0": "Mesa Court - Selva",
    "10.12.46.0/255.255.254.0": "Mesa Court - Arroyo",
    "169.234.141.127/255.255.255.255": "Mesa Court - Arroyo",
    "169.234.141.128/255.255.255.128": "Mesa Court - Arroyo",
    "10.12.38.0/255.255.254.0": "Mesa Court - Bahia",
    "169.234.139.128/255.255.255.128": "Mesa Court - Bahia",
    "10.12.32.0/255.255.254.0": "Mesa Court - Cielo",
    "169.234.138.0/255.255.255.128": "Mesa Court - Cielo",
    "10.12.40.0/255.255.254.0": "Mesa Court - Cuesta",
    "169.234.140.0/255.255.255.128": "Mesa Court - Cuesta",
    "10.12.34.0/255.255.254.0": "Mesa Court - Lago",
    "169.234.138.128/255.255.255.128": "Mesa Court - Lago",
    "10.12.44.0/255.255.254.0": "Mesa Court - Loma",
    "169.234.141.0/255.255.255.128": "Mesa Court - Loma",
    "10.12.36.0/255.255.254.0": "Mesa Court - Sierra",
    "169.234.139.0/255.255.255.128": "Mesa Court - Sierra",
    "10.12.42.0/255.255.254.0": "Mesa Court - Viento",
    "169.234.140.128/255.255.255.128": "Mesa Court - Viento",
    "10.12.2.0/255.255.254.0": "Mesa Court - Brisa",
    "169.234.129.0/255.255.255.0": "Mesa Court - Brisa",
    "10.12.10.0/255.255.254.0": "Mesa Court - Camino",
    "169.234.133.0/255.255.255.128": "Mesa Court - Camino",
    "10.12.12.0/255.255.254.0": "Mesa Court - Ciudad",
    "169.234.133.128/255.255.255.128": "Mesa Court - Ciudad",
    "10.12.8.0/255.255.254.0": "Mesa Court - Cumbre",
    "169.234.132.0/255.255.255.0": "Mesa Court - Cumbre",
    "10.12.16.0/255.255.254.0": "Mesa Court - Estrella",
    "169.234.135.0/255.255.255.0": "Mesa Court - Estrella",
    "10.12.20.0/255.255.254.0": "Mesa Court - Nubes",
    "169.234.137.0/255.255.255.0": "Mesa Court - Nubes",
    "10.12.0.0/255.255.254.0": "Mesa Court - Otero",
    "169.234.128.0/255.255.255.0": "Mesa Court - Otero",
    "10.12.18.0/255.255.254.0": "Mesa Court - Palo",
    "169.234.136.0/255.255.255.0": "Mesa Court - Palo",
    "10.12.4.0/255.255.254.0": "Mesa Court - Prado",
    "169.234.130.0/255.255.255.0": "Mesa Court - Prado",
    "10.12.6.0/255.255.254.0": "Mesa Court - Puente",
    "169.234.131.0/255.255.255.0": "Mesa Court - Puente",
    "10.12.14.0/255.255.254.0": "Mesa Court - Vista",
    "169.234.134.0/255.255.255.0": "Mesa Court - Vista",
    "10.12.84.0/255.255.254.0": "Mesa Court - Towers",
    "10.12.172.0/255.255.252.0": "Middle Earth - Calmindon and Grey Havens",
    "169.234.170.0/255.255.254.0": "Middle Earth - Calmindon and Grey Havens",
    "10.12.160.0/255.255.252.0": "Middle Earth - Elrond and Shadowfax",
    "169.234.164.0/255.255.254.0": "Middle Earth - Elrond and Shadowfax",
    "10.12.180.0/255.255.252.0": "Middle Earth - Harrowdale and Balin",
    "169.234.174.0/255.255.254.0": "Middle Earth - Harrowdale and Balin",
    "10.12.184.0/255.255.252.0": "Middle Earth - Quenya",
    "169.234.203.0/255.255.255.0": "Middle Earth - Quenya",
    "10.12.164.0/255.255.252.0": "Middle Earth - Rohan and Aldor",
    "169.234.166.0/255.255.254.0": "Middle Earth - Rohan and Aldor",
    "10.12.168.0/255.255.252.0": "Middle Earth - Snowbourn and Gondolin",
    "169.234.168.0/255.255.254.0": "Middle Earth - Snowbourn and Gondolin",
    "10.12.176.0/255.255.252.0": "Middle Earth - Whispering Wood and Woodhall",
    "169.234.172.0/255.255.254.0":
        "Middle Earth - Whispering Wood and Woodhall",
    "10.12.142.0/255.255.254.0": "Middle Earth - Crickhollow",
    "169.234.160.0/255.255.255.0": "Middle Earth - Crickhollow",
    "10.12.146.0/255.255.254.0": "Middle Earth - Evenstar",
    "169.234.162.0/255.255.255.0": "Middle Earth - Evenstar",
    "10.12.136.0/255.255.254.0": "Middle Earth - Hobbiton",
    "169.234.158.0/255.255.255.128": "Middle Earth - Hobbiton",
    "10.12.132.0/255.255.254.0": "Middle Earth - Isengard",
    "169.234.157.0/255.255.255.128": "Middle Earth - Isengard",
    "10.12.128.0/255.255.254.0": "Middle Earth - Lorien",
    "169.234.156.0/255.255.255.128": "Middle Earth - Lorien",
    "10.12.134.0/255.255.254.0": "Middle Earth - Mirkwood",
    "169.234.157.128/255.255.255.128": "Middle Earth - Mirkwood",
    "10.12.138.0/255.255.254.0": "Middle Earth - Misty Mountain",
    "169.234.158.128/255.255.255.128": "Middle Earth - Misty Mountain",
    "10.12.144.0/255.255.254.0": "Middle Earth - Oakenshield",
    "169.234.161.0/255.255.255.0": "Middle Earth - Oakenshield",
    "10.12.130.0/255.255.254.0": "Middle Earth - Rivendell",
    "169.234.156.128/255.255.255.128": "Middle Earth - Rivendell",
    "10.12.140.0/255.255.254.0": "Middle Earth - The Shire",
    "169.234.159.0/255.255.255.128": "Middle Earth - The Shire",
    "10.12.148.0/255.255.254.0": "Middle Earth - Valimar",
    "169.234.163.0/255.255.255.0": "Middle Earth - Valimar",
    "10.12.188.0/255.255.252.0": "Middle Earth - Towers & Pippin",
    "10.13.65.0/255.255.255.0": "OIT Trailers - RNC Office",
    "169.234.254.128/255.255.255.128": "OIT Trailers - RNC Office",
    "10.13.64.0/255.255.255.0": "OIT Trailers - Walk-up",
    "169.234.255.160/255.255.255.224": "OIT Trailers - Walk-up, Public",
    "169.234.227.0/255.255.255.128": "Palo Verde - 1100 and 1200",
    "169.234.227.128/255.255.255.128": "Palo Verde - 1300 and 1400",
    "169.234.228.0/255.255.255.128": "Palo Verde - 2100 and 2200",
    "169.234.228.128/255.255.255.128": "Palo Verde - 3100 and 3200",
    "169.234.229.0/255.255.255.128": "Palo Verde - 3300 and 4100",
    "169.234.229.128/255.255.255.128": "Palo Verde - 4200 and 4300",
    "169.234.230.0/255.255.255.128": "Palo Verde - 4400 and 5100",
    "169.234.230.128/255.255.255.128": "Palo Verde - 5200 and 5300",
    "169.234.231.0/255.255.255.128": "Palo Verde - 6100 and 6200",
    "169.234.231.128/255.255.255.128": "Palo Verde - 6300 and 7000",
    "169.234.232.0/255.255.255.0": "Palo Verde - 7100",
    "169.234.233.0/255.255.255.0": "Palo Verde - 7200",
    "169.234.234.0/255.255.255.0": "Palo Verde - 7300",
    "169.234.235.0/255.255.255.0": "Palo Verde - 7400",
    "169.234.236.0/255.255.255.0": "Palo Verde - 7500",
    "169.234.237.0/255.255.255.0": "Palo Verde - 7600",
    "169.234.238.0/255.255.255.0": "Palo Verde - 8100",
    "169.234.239.0/255.255.255.0": "Palo Verde - 8200",
    "169.234.240.0/255.255.255.0": "Palo Verde - 8300",
    "169.234.241.0/255.255.255.0": "Palo Verde - 8400",
    "169.234.242.0/255.255.255.0": "Palo Verde - 8500",
    "169.234.243.0/255.255.255.0": "Palo Verde - 8600",
    "169.234.244.0/255.255.255.0": "Palo Verde - 8700",
    "169.234.245.0/255.255.255.0": "Palo Verde - 8800",
    "169.234.205.128/255.255.255.128": "Verano Place - 100",
    "169.234.208.128/255.255.255.128": "Verano Place - 1100 and 1200",
    "169.234.209.0/255.255.255.128": "Verano Place - 1300 and 1400",
    "169.234.209.128/255.255.255.128": "Verano Place - 1500 and 1600",
    "169.234.210.0/255.255.255.128": "Verano Place - 1700 and 1800",
    "169.234.210.128/255.255.255.128": "Verano Place - 1900 and 2000",
    "169.234.206.0/255.255.255.128": "Verano Place - 200",
    "169.234.211.0/255.255.255.128": "Verano Place - 2100 and 2200",
    "169.234.211.128/255.255.255.128": "Verano Place - 2300 and 2400",
    "169.234.212.0/255.255.255.128": "Verano Place - 2500 and 2600",
    "169.234.212.128/255.255.255.128": "Verano Place - 2700 and 2800",
    "169.234.213.0/255.255.255.128": "Verano Place - 2900 and 3000",
    "169.234.206.128/255.255.255.128": "Verano Place - 300 and 400",
    "169.234.213.128/255.255.255.128": "Verano Place - 3100 and 3200",
    "169.234.207.0/255.255.255.128": "Verano Place - 500 and 600",
    "169.234.207.128/255.255.255.128": "Verano Place - 700 and 800",
    "169.234.208.0/255.255.255.128": "Verano Place - 900 and 1000",
    "169.234.214.0/255.255.255.0": "Verano Place - 66000",
    "169.234.215.0/255.255.255.0": "Verano Place - 67000",
    "169.234.216.0/255.255.255.0": "Verano Place - 68000",
    "169.234.217.0/255.255.255.0": "Verano Place - 69000",
    "169.234.218.0/255.255.255.128": "Verano Place - 6200 and 6220",
    "169.234.218.128/255.255.255.128": "Verano Place - 6222 and 6230",
    "169.234.219.0/255.255.255.128": "Verano Place - 6238 and 6253",
    "169.234.219.128/255.255.255.128": "Verano Place - 6255 and 6263",
    "169.234.220.0/255.255.255.128": "Verano Place - 6271 and 6286",
    "169.234.220.128/255.255.255.128": "Verano Place - 6287 and 6295",
    "169.234.221.0/255.255.255.128": "Verano Place - 6303 and 6319",
    "169.234.221.128/255.255.255.128": "Verano Place - 6321 and 6329",
    "169.234.222.0/255.255.255.128": "Verano Place - 6337 and 6352",
    "169.234.222.128/255.255.255.128": "Verano Place - 6355 and 6363",
    "169.234.223.0/255.255.255.128": "Verano Place - 6371 and 6386",
    "169.234.223.128/255.255.255.128": "Verano Place - 6387 and 6395",
    "169.234.224.0/255.255.255.128": "Verano Place - 6403 and 6419",
    "169.234.224.128/255.255.255.128": "Verano Place - 6437 and 6456",
    "169.234.225.0/255.255.255.128": "Verano Place - 6457 and 6476",
    "169.234.225.128/255.255.255.128": "Verano Place - 6478 and 6493",
    "169.234.226.0/255.255.255.128": "Verano Place - 6495 and 6511",
    "169.234.226.128/255.255.255.128": "Verano Place - 6512 and 6528",
}
