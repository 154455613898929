import { Badge, IconButton, Tooltip } from "@mui/material"
import { Notifications as NotificationsIcon } from "@mui/icons-material"
import React from "react"
import { last_updated, update_message } from "../../notifications"

interface NotificationsProps {}

const LOCAL_STORAGE_KEY = "last_checked_updates"

let Notifications = (props: NotificationsProps) => {
    let [lastChecked, setLastChecked] = React.useState<Date>(new Date(0, 0, 1))

    React.useEffect(() => {
        let last_checked_string = localStorage.getItem(LOCAL_STORAGE_KEY)

        if (last_checked_string) {
            try {
                let last_checked = new Date(last_checked_string)
                setLastChecked(last_checked)
            } catch {
                localStorage.setItem(LOCAL_STORAGE_KEY, new Date(0, 0, 1).toISOString())
            }
        } else {
            localStorage.setItem(LOCAL_STORAGE_KEY, new Date(0, 0, 1).toISOString())
        }
    }, [])

    let handleOpen = () => {
        let now = new Date()
        setLastChecked(now)
        localStorage.setItem(LOCAL_STORAGE_KEY, now.toISOString())
    }

    return (
        <Tooltip title={update_message} arrow leaveDelay={500} onClose={handleOpen}>
            <IconButton size="large">
                <Badge variant="dot" color="primary" invisible={lastChecked > last_updated}>
                    <NotificationsIcon></NotificationsIcon>
                </Badge>
            </IconButton>
        </Tooltip>
    )
}

export default Notifications
