import React, { useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import { Box, Card, CardContent, TextField, Button, Typography, FormControlLabel, Checkbox, Backdrop, CircularProgress, Stack } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { useCurrentUserInfo } from "../DataHooks/useCurrentUserInfo";
import { updateTempServiceAlerts } from '../../apiFunctions';
import { TempServiceAlerts, TempServiceAlertsComment } from '../../apiInterfaces';

interface MajorIncidentsInputProps {
  baseTempAlert?: TempServiceAlerts;
}

export const formatDate = (date: Date) => 
  date.toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true
  }).replace(',', '');

const MajorIncidentsInput: React.FC<MajorIncidentsInputProps> = ({ baseTempAlert }) => {
  const user = useCurrentUserInfo();
  const userName = user.ldap_data?.displayName || "SYSTEM";
  const [title, setTitle] = useState<string>("");
  const [comment, setComment] = useState<string>("");
  const [commentList, setCommentList] = useState<TempServiceAlertsComment[]>([]);
  const [resolved, setResolved] = useState<boolean>(false);
  const [displayTime, setDisplayTime] = useState(formatDate(new Date(baseTempAlert?.sys_created_on || Date.now())));
  const [submitMessage, setSubmitMessage] = useState<string>("")
  const createdOn = baseTempAlert?.sys_created_on || new Date().toLocaleString('sv-SE', { timeZone: 'America/Los_Angeles' }).replace(" ", "T");

  useEffect(() => {
    if (baseTempAlert?.short_description) {
      setTitle(baseTempAlert.short_description);
    }
    if (baseTempAlert?.comments) {
      setCommentList(baseTempAlert.comments);
    }
  }, [baseTempAlert]);

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<'success' | 'error' | 'idle'>('idle');

  const mutation = useMutation(updateTempServiceAlerts, {
    onMutate: () => {
      setLoading(true);
      setStatus('idle');
      setSubmitMessage("Handling your request...")
    },
    onSuccess: () => {
      setLoading(false);
      setStatus('success');
      setSubmitMessage(`Temp-alert ${title} ${baseTempAlert? "updated":"created"}`)
    
      setTimeout(() => { 
        setStatus('idle'); 

        // clean up form
        setTitle("");
        setComment("");
        setCommentList([]);
        setResolved(false);
        setDisplayTime(formatDate(new Date(Date.now())));

        window.location.reload(); 
      }, 2000); // hide success icon after 2 seconds
    },
    onError: (error) => {
      setLoading(false);
      setStatus('error');
      setSubmitMessage(`Error: ${error}`)
      setTimeout(() => setStatus('idle'), 2000); // hide error icon after 2 seconds
    }
  });

  const handleCreateSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const curDate = new Date().toLocaleString('sv-SE', { timeZone: 'America/Los_Angeles' }).replace(" ", "T");
    
    const newComment: TempServiceAlertsComment = {
      timestamp: curDate,
      author: userName,
      content: comment
    };
    
    setCommentList(prevComments => [...prevComments, newComment]);

    const updatedAlerts: TempServiceAlerts = {
      sys_id: 'SYSTEM',
      short_description: title,
      cmdb_ci: 'SYSTEM',
      active: !resolved,
      comments: [...commentList, newComment],
      sys_created_on: createdOn,
      sys_updated_on: curDate,
      opened_at: baseTempAlert?.opened_at || createdOn,
      resolved_at: resolved? curDate : null,
      closed_at: baseTempAlert?.closed_at || null,
    };

    const doc_id = `${updatedAlerts.short_description}_${updatedAlerts.sys_created_on}` || '';
    mutation.mutate({ doc_id, updated_alerts: updatedAlerts });
  };

  return (
    <Card 
      variant="outlined"
      component="form" 
      onSubmit={handleCreateSubmit}
      sx={{ position: 'relative' }}
    >
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box>
            <Typography variant="h5">Post/Update Temporary Service Alerts</Typography>
            <Typography>
              Please only use this tool when Service Now is down.
            </Typography>
            <Typography sx={{ mb: 2 }}>
              You can update the status of any temp alert from the active menu by clicking on the pencil icon.
            </Typography>
          </Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={resolved}
                onChange={(e) => {
                  setResolved(e.target.checked)
                }}
                color="primary"
              />
            }
            label="Mark as Resolved"
            sx={{ ml: 2 }}
          />
        </Box>
        <TextField
          label="Short Description"
          variant="outlined"
          value={title}
          InputProps={{
            readOnly: !!baseTempAlert,
          }}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth 
          sx={{ mb: 2 }}
        />
        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <TextField
            label="Created On"
            variant="outlined"
            value={displayTime}
            InputProps={{
              readOnly: true,
            }}
            fullWidth 
          />
          <TextField
            label="Author"
            variant="outlined"
            value={userName}
            InputProps={{
              readOnly: true,
            }}
            fullWidth 
          />
        </Box>
        <TextField
          label="Add Comment"
          value={comment}
          variant="outlined"
          multiline
          rows={3}
          onChange={(e) => setComment(e.target.value)}
          fullWidth 
          sx={{ mb: 2 }}
        />
        <Button variant="outlined" color="primary" type="submit" fullWidth >
          Submit
        </Button>
        {/** @todo: Create a proper success screen */}
        {loading && (
          <Backdrop
            sx={(theme) => ({ color: '#fff' })}
            open={loading}
          >
            <Stack
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress color="inherit" />
              <Typography>{submitMessage}</Typography>
            </Stack>
          </Backdrop>
        )}
        {status === 'success' && (
          <Backdrop
            sx={(theme) => ({ color: '#fff' })}
            open={status === 'success'}
          >
            <Stack
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <CheckCircleIcon sx={{ fontSize: 50, color: 'green' }} />
              <Typography>{submitMessage}</Typography>
            </Stack>
          </Backdrop>
        )}
        {status === 'error' && (
          <Backdrop
            sx={(theme) => ({ color: '#fff' })}
            open={status === 'error'}
          >
            <Stack
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <ErrorIcon sx={{ fontSize: 50, color: 'red' }} />
              <Typography>{submitMessage}</Typography>
            </Stack>
          </Backdrop>
        )}
      </CardContent>
    </Card>
  );
};

export default MajorIncidentsInput;
