import { Cloud, DoDisturb, PictureAsPdf } from "@mui/icons-material"
import { AdobeLicense } from "../../apiInterfaces"


export type LicenseType = {
    title: string
    description: string
    icon: React.ReactNode | null
}

export const getLicenseData = (license: AdobeLicense): LicenseType => {
  switch (license) {
    case AdobeLicense.CreativeCloud:
      return {
        title: "Adobe Creative Cloud",
        description:
          "A collection of apps for photography, video, design, web, UX, and social media",
        icon: <Cloud fontSize='large' sx={{ width: "inherit", height: "inherit", padding: "1rem"}}/>,
      }
    case AdobeLicense.AcrobatDC:
      return {
        title: "Adobe Acrobat DC",
        description:
          "Make your job easier with the trusted PDF editor and creator.",
        icon: <PictureAsPdf fontSize='large' sx={{ width: "inherit", height: "inherit", padding: "1rem"}}/>,
      }
    case AdobeLicense.None:
      return {
        title: "No License",
        description: "No Adobe License Assigned",
        icon: <DoDisturb fontSize='large' sx={{ width: "inherit", height: "inherit", padding: "1rem"}}/>,
      }
  }
}