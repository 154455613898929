import React from "react"
import _ from "lodash"
import { Paper, Table, TableBody } from "@mui/material"
import { Close, Check, Work } from "@mui/icons-material"
import InfoBlock from "../../GenericBlocks/InfoBlock/InfoBlock"
import TableKeyValueRow from "../../GenericBlocks/TableKeyValueRow"
import CloseableAlert from "../../PageStructure/AlertManager/CloseableAlert"
import { AlertProps } from "../../PageStructure/AlertManager/useAlerts"
import useLDAP from "../../DataHooks/useLDAP"
import { fetchKFSOrgByNumber, fetchQuickTempDataByUCInetID } from "../../../apiFunctions"
import { useQuery } from "react-query"

const SUPERVISOR_REGEX = /uid=(?<ucinetid>\w*),/

interface EmployeeInfoProps {
    ucinetid: string
    expanded?: boolean
}

let EmployeeInfo = (props: EmployeeInfoProps & AlertProps) => {
    let { ucinetid, expanded = false, addAlert } = props

    let { loading: ldapLoading, entry } = useLDAP(ucinetid)

    let {
        department,
        uciAffiliation,
        employeeNumber,
        postalAddress,
        postalCode,
        telephoneNumber,
        uciEmployeeGivenName,
        uciEmployeeMiddleName,
        uciEmployeeSN,
        uciEmployeeStatus,
        uciFloater,
        uciHrStatus,
        uciLevel2DepartmentID,
        uciLevel2DepartmentDescription,
        uciLevel3DepartmentID,
        uciLevel3DepartmentDescription,
        uciLevel4DepartmentID,
        uciLevel4DepartmentDescription,
        uciPrimaryDepartment,
        uciPrimaryDepartmentCode,
        uciOrganization,
        uciPrimaryEmployeeClassDescription,
        uciPrimaryTitle,
        uciRecentlyHired,
        uciSupervisorDN,
        uciSupervisorRoleFlag,
        uciZotCodeName,
    } = entry?.attributes ?? {}

    let has_employee_affiliation = ldapLoading || uciAffiliation?.includes("employee")

    let supervisor = undefined

    if (uciSupervisorDN) {
        supervisor = _.get(SUPERVISOR_REGEX.exec(uciSupervisorDN), "groups.ucinetid", "NONE FOUND")
    }

    const { isLoading: quickTempLoading, data: quick_temp_data } = useQuery(
        ["quick-temp-data", ucinetid],
        fetchQuickTempDataByUCInetID(ucinetid),
        {
            enabled: uciPrimaryDepartmentCode === "IR7008",
        }
    )

    const { isLoading: department_support_isLoading, data: department_support_data } = useQuery(
        ["kfs-org", quick_temp_data?.department_number],
        fetchKFSOrgByNumber(quick_temp_data?.department_number.replaceAll(/[a-zA-Z]/g, "")),
        {
            enabled: quick_temp_data?.department_number !== undefined,
        }
    )

    const loading = ldapLoading || quickTempLoading || department_support_isLoading

    React.useEffect(() => {
        if (uciHrStatus === "I") {
            addAlert(
                "EMPLOYEE INFO - HR STATUS",
                <CloseableAlert severity="warning" title="HR Status - Inactive Employee">
                    This may or may not be an issue, but this user is marked as an inactive
                    employee.
                </CloseableAlert>
            )
        }
    }, [uciHrStatus, addAlert])

    const isQuickTempEmployee = quick_temp_data !== undefined && quick_temp_data !== null

    return (
        <InfoBlock
            title="Employee Info"
            icon={<Work></Work>}
            loading={loading}
            expanded={expanded}
            hide={!has_employee_affiliation}
        >
            <Paper>
                <Table>
                    <TableBody>
                        <TableKeyValueRow title="Employee Status">
                            {uciEmployeeStatus} - (HR Status:{" "}
                            {uciHrStatus === "A"
                                ? "Active"
                                : uciHrStatus === "I"
                                ? "Inactive"
                                : "UNKNOWN / ERROR"}
                            )
                        </TableKeyValueRow>
                        <TableKeyValueRow title="Employee Class">
                            {uciPrimaryEmployeeClassDescription}
                        </TableKeyValueRow>
                        <TableKeyValueRow title="Employee Full Name">
                            {[uciEmployeeGivenName, uciEmployeeMiddleName, uciEmployeeSN].join(" ")}
                        </TableKeyValueRow>
                        <TableKeyValueRow title="Employee ID">{employeeNumber}</TableKeyValueRow>
                        {!isQuickTempEmployee ? (
                            <>
                                <TableKeyValueRow title="Primary Department">
                                    {uciPrimaryDepartment}
                                </TableKeyValueRow>
                                {department && department.length > 1 && (
                                    <TableKeyValueRow title="All Departments">
                                        {department.join(", ")}
                                    </TableKeyValueRow>
                                )}
                                <TableKeyValueRow title="Primary Department Code">
                                    {uciPrimaryDepartmentCode}
                                </TableKeyValueRow>
                                <TableKeyValueRow title="Level 2 Department ID">
                                    {uciLevel2DepartmentID}
                                </TableKeyValueRow>
                                <TableKeyValueRow title="Level 2 Department Description">
                                    {uciLevel2DepartmentDescription}
                                </TableKeyValueRow>
                                <TableKeyValueRow title="Level 3 Department ID">
                                    {uciLevel3DepartmentID}
                                </TableKeyValueRow>
                                <TableKeyValueRow title="Level 3 Department Description">
                                    {uciLevel3DepartmentDescription}
                                </TableKeyValueRow>
                                <TableKeyValueRow title="Level 4 Department ID">
                                    {uciLevel4DepartmentID}
                                </TableKeyValueRow>
                                <TableKeyValueRow title="Level 4 Department Description">
                                    {uciLevel4DepartmentDescription}
                                </TableKeyValueRow>
                            </>
                        ) : (
                            <>
                                <TableKeyValueRow title="Department Name">
                                    {department_support_data?.org_name}
                                </TableKeyValueRow>
                                <TableKeyValueRow title="Department Code">
                                    {quick_temp_data?.department_number}
                                </TableKeyValueRow>
                            </>
                        )}
                        <TableKeyValueRow title="Primary Job Title">
                            {uciPrimaryTitle}
                        </TableKeyValueRow>
                        {telephoneNumber && (
                            <TableKeyValueRow title="Phone Number">
                                {telephoneNumber}
                            </TableKeyValueRow>
                        )}
                        {(postalAddress || uciOrganization || postalCode) && (
                            <TableKeyValueRow title="Address">
                                {[postalAddress, uciOrganization, postalCode]
                                    .filter((e) => e !== undefined)
                                    .join(", ")}
                            </TableKeyValueRow>
                        )}
                        {uciZotCodeName && (
                            <TableKeyValueRow
                                title="Zot Code"
                                tooltip="More of a non official address. Usually a building name."
                            >
                                {uciZotCodeName}
                            </TableKeyValueRow>
                        )}
                        {!isQuickTempEmployee ? (
                            <TableKeyValueRow
                                title="Supervisor UCInetID"
                                tooltip="This is the UCInetID of the employee's supervisor."
                            >
                                {supervisor}
                            </TableKeyValueRow>
                        ) : (
                            <>
                                <TableKeyValueRow
                                    title="Supervisor Name"
                                    tooltip="This is the name (Last, First) of the employee's supervisor."
                                >
                                    {quick_temp_data?.supervisor_name}
                                </TableKeyValueRow>
                                <TableKeyValueRow
                                    title="Supervisor UCInetID"
                                    tooltip="This is the UCInetID of the employee's supervisor."
                                >
                                    {quick_temp_data?.supervisor_ucinetid}
                                </TableKeyValueRow>
                            </>
                        )}
                        <TableKeyValueRow
                            title="Is Supervisor?"
                            tooltip="True if the user officially supervises other employees."
                        >
                            {uciSupervisorRoleFlag === "Y" ? <Check></Check> : <Close></Close>}
                        </TableKeyValueRow>
                        <TableKeyValueRow
                            title="Is a Floater Employee?"
                            tooltip="Floater Employees are Employees that can easily move between departments, usually not permanently employed by a single department."
                        >
                            {uciFloater === "Y" ? <Check></Check> : <Close></Close>}
                        </TableKeyValueRow>
                        <TableKeyValueRow
                            title="Employee Recently Hired?"
                            tooltip="True if the employee was hired within the last year."
                        >
                            {uciRecentlyHired === "Y" ? <Check></Check> : <Close></Close>}
                        </TableKeyValueRow>
                    </TableBody>
                </Table>
            </Paper>
        </InfoBlock>
    )
}

export default EmployeeInfo
