import {
    Box,
    Button,
    Checkbox,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Switch,
    Tooltip,
    Typography,
} from "@mui/material"
import React from "react"
import ToolHeader from "../GenericBlocks/ToolHeader"
import { APPLICATION_STATE_CONTEXT } from "../PageStructure/ApplicationStateManager/ApplicationStateManager"
import PageParent from "../PageStructure/PageParent/PageParent"
import { ComponentKey, ComponentKeys, COMPONENT_DISPLAY_NAME } from "./ComponentKeys"

interface SettingsProps {}

let Settings = (props: SettingsProps) => {
    let [appState, appDispatch] = React.useContext(APPLICATION_STATE_CONTEXT)

    let [selectedItems, setSelectedItems] = React.useState<Set<ComponentKey>>(new Set())

    let shownComponents = appState.account_blocks
    let shownComponentsSet = new Set(shownComponents)
    let hiddenComponents = ComponentKeys.filter((c) => !shownComponentsSet.has(c))

    let handleMoveToShown = () => {
        let toMove = hiddenComponents.filter((i) => selectedItems.has(i))
        appDispatch({
            type: "set-account-blocks",
            payload: [...appState.account_blocks, ...toMove],
        })
    }

    let handleMoveToHidden = () => {
        let toKeep = shownComponents.filter((i) => !selectedItems.has(i))
        appDispatch({
            type: "set-account-blocks",
            payload: toKeep,
        })
    }

    let handleSelect = (key: ComponentKey) => () => {
        setSelectedItems((keys) => {
            keys = new Set(keys)
            if (keys.has(key)) {
                keys.delete(key)
            } else {
                keys.add(key)
            }
            return keys
        })
    }

    let handleReset = () => {
        appDispatch({ type: "set-account-blocks", payload: [...ComponentKeys] })
        appDispatch({ type: "set-account-expanded-blocks", payload: [] })
    }

    let expanded = new Set([...appState.account_expanded_blocks])

    let handleExpandToggle = (key: ComponentKey) => () => {
        if (expanded.has(key)) {
            expanded.delete(key)
        } else {
            expanded.add(key)
        }

        appDispatch({
            type: "set-account-expanded-blocks",
            payload: [...expanded],
        })
    }

    return (
        <PageParent>
            <ToolHeader>Settings</ToolHeader>

            <Box paddingY="1rem">
                <Typography variant="body1">
                    This is our new Settings page which can be used to configure how the components
                    work when displaying <b>Accounts</b> in the Search Tool! This is a <i>beta</i>{" "}
                    feature, so please let us know your thoughts on how it can be improved.
                </Typography>
            </Box>

            <Box marginY="1rem">
                <Button onClick={handleReset} variant="outlined">
                    Reset
                </Button>
            </Box>

            <Grid container spacing={2}>
                <Grid item xs>
                    <Typography variant="h6">Hidden Blocks</Typography>
                    <Paper>
                        <List>
                            {hiddenComponents.map((key) => (
                                <ListItem button key={key} onClick={handleSelect(key)}>
                                    <ListItemIcon>
                                        <Checkbox checked={selectedItems.has(key)}></Checkbox>
                                    </ListItemIcon>
                                    <ListItemText>{COMPONENT_DISPLAY_NAME[key]}</ListItemText>
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                </Grid>
                <Grid item xs={1} container direction="column" justifyContent="center">
                    <Button onClick={handleMoveToShown}>{">"}</Button>
                    <Button onClick={handleMoveToHidden}>{"<"}</Button>
                </Grid>
                <Grid item xs>
                    <Typography variant="h6">Shown Blocks</Typography>
                    <Paper>
                        <List>
                            {shownComponents.map((key) => (
                                <ListItem button key={key} onClick={handleSelect(key)}>
                                    <ListItemIcon>
                                        <Checkbox checked={selectedItems.has(key)}></Checkbox>
                                    </ListItemIcon>
                                    <ListItemText>{COMPONENT_DISPLAY_NAME[key]}</ListItemText>
                                    <ListItemSecondaryAction>
                                        <Tooltip title="Automatically Expand" placement="top">
                                            <Switch
                                                onChange={handleExpandToggle(key)}
                                                checked={expanded.has(key)}
                                            ></Switch>
                                        </Tooltip>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                </Grid>
            </Grid>
        </PageParent>
    )
}

export default Settings
