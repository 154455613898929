import { Box, Collapse, Grid, MenuItem, TextField, Typography } from "@mui/material"
import React from "react"
import { fetchBlockList, fetchRegistrationsByUCInetID } from "../../../apiFunctions"
import { SUBNETS } from "../../../data/subnets"
import { useDelayedQuery } from "../../DataHooks/useDelayedQuery"
import { convert_ip_to_bytes } from "../../Utilities/ipUtilities"
import { format_mac_address, remove_mac_address_separators } from "../../Utilities/macUtilities"
import { Check } from "./Check"
import { ForescoutMonitor } from "./ForescoutMonitor"

enum Network {
    UCInet_Wired = "UCInet Wired",
    UCInet_Wireless = "UCInet Wireless",
    ResNet_Wired = "ResNet Wired",
    ResNet_Wireless = "ResNet Wireless",
}

interface NetworkTroubleshooterProps {}

export const NetworkTroubleshooter = (props: NetworkTroubleshooterProps) => {
    const [ucinetid, setUCInetID] = React.useState("")
    const [ip, setIP] = React.useState("")
    const [mac, setMAC] = React.useState("")

    const [network, setNetwork] = React.useState<Network | "">("")

    const { status: networkRegistrationsStatus, data: networkRegistrations } = useDelayedQuery(
        ["network-registrations-by-ucinetid", ucinetid],
        fetchRegistrationsByUCInetID(ucinetid),
        { enabled: ucinetid !== "" }
    )

    const { status: ucinetidBlockedStatus, data: ucinetidBlocked } = useDelayedQuery(
        ["ucinetid-blocked", ucinetid],
        fetchBlockList(ucinetid.toLowerCase()),
        {
            enabled: ucinetid !== "",
        }
    )

    const { status: ipBlockedStatus, data: ipBlocked } = useDelayedQuery(
        ["ip-address-blocked", ip],
        fetchBlockList(ip),
        { enabled: ip !== "" }
    )

    const bare_mac_address = remove_mac_address_separators(mac)
    const formatted_mac_address = format_mac_address(bare_mac_address)

    const { status: macBlockedStatus, data: macBlocked } = useDelayedQuery(
        ["mac-address-blocked", formatted_mac_address],
        fetchBlockList(formatted_mac_address),
        { enabled: mac !== "" }
    )

    const show_forescout = network === Network.ResNet_Wired || network === Network.ResNet_Wireless
    const ip_as_number = (() => {
        try {
            return convert_ip_to_bytes(ip)
        } catch {
            return undefined
        }
    })()

    const ip_subnets = ip_as_number
        ? SUBNETS.filter((s) => s.range_start <= ip_as_number && ip_as_number <= s.range_end)
        : []

    return (
        <Grid container direction="column" spacing={4}>
            <Grid item>
                <Typography>
                    Enter as much information as you can for the device that's connecting to UCInet
                    (or ResNet). If you are connecting a router, this is the <i>Router's</i>{" "}
                    information, not the information for the device connecting to the router.
                </Typography>
            </Grid>
            <Grid item>
                <TextField
                    label="Network"
                    value={network}
                    onChange={(e) => setNetwork(e.target.value as unknown as Network)}
                    select
                    helperText="The network this device is connecting to"
                    variant="outlined"
                >
                    {Object.values(Network).map((v) => (
                        <MenuItem key={v} value={v}>
                            {v}
                        </MenuItem>
                    ))}
                </TextField>
                <Collapse in={network !== "" && !show_forescout}>
                    <Typography variant="caption">
                        HDUtils can only provide limited information for this network. This tool is
                        most powerful when used with ResNet.
                    </Typography>
                </Collapse>
                <Box paddingBottom="1rem"></Box>
            </Grid>
            <Grid item>
                <Box paddingBottom="1rem">
                    <TextField
                        label="Responsible UCInetID"
                        value={ucinetid}
                        onChange={(e) => setUCInetID(e.target.value)}
                        variant="outlined"
                    ></TextField>
                </Box>
                <Collapse in={ucinetid !== ""}>
                    <Check
                        query_status={ucinetidBlockedStatus}
                        query_loading_text="Checking Blocklist..."
                        condition={ucinetidBlocked?.length === 0}
                        success_text="The UCInetID does not appear to be blocked."
                        fail_text={`The UCInetID ${ucinetid.toUpperCase()} is BLOCKED!!!`}
                    />
                    <Check
                        query_status={networkRegistrationsStatus}
                        query_loading_text="Looking up Network Registrations..."
                        condition={networkRegistrations?.length !== 0}
                        success_text={`This UCInetID has ${networkRegistrations?.length} registered devices.`}
                        fail_text="This UCInetID has NO registered devices!"
                    />
                </Collapse>
            </Grid>
            <Grid item>
                <Box paddingBottom="1rem">
                    <TextField
                        label="IP Address"
                        value={ip}
                        onChange={(e) => setIP(e.target.value)}
                        variant="outlined"
                    ></TextField>
                </Box>
                <Collapse in={ip !== ""}>
                    <Check
                        query_status={ipBlockedStatus}
                        query_loading_text="Checking if the IP Address is Blocked..."
                        condition={ipBlocked?.length === 0}
                        success_text="The IP Address does not appear to be blocked."
                        fail_text={`The IP Address ${ip} is BLOCKED!!!`}
                    />
                    <Check
                        query_status={"success"}
                        query_loading_text="Checking the IP's Subnet"
                        condition={ip_subnets.every((s) => s.internet) ?? false}
                        success_text={
                            <>
                                <Typography variant="body2">
                                    The IP Address belongs to the following subnets:
                                </Typography>
                                {ip_subnets.map((s) => (
                                    <Typography variant="body2" key={s.subnet}>
                                        {s.name} ({s.subnet}){s.message && <>: {s.message}</>}
                                    </Typography>
                                ))}
                            </>
                        }
                        fail_text={
                            ip_subnets === undefined ? (
                                "Nothing is known about the subnet that this IP Address is in!"
                            ) : (
                                <>
                                    <Typography variant="body2">
                                        <b>
                                            The IP Address belongs to a subnet that doesn't have
                                            internet!
                                        </b>
                                    </Typography>
                                    {ip_subnets.map((s) => (
                                        <Typography variant="body2" key={s.subnet}>
                                            <b>{s.name}</b> ({s.subnet})
                                            {s.message && (
                                                <>
                                                    : <i>{s.message}</i>
                                                </>
                                            )}
                                        </Typography>
                                    ))}
                                </>
                            )
                        }
                    />
                    {show_forescout && <ForescoutMonitor ip={ip}></ForescoutMonitor>}
                </Collapse>
            </Grid>
            <Grid item>
                <Box paddingBottom="1rem">
                    <TextField
                        label="MAC Address"
                        value={mac}
                        onChange={(e) => setMAC(e.target.value)}
                        variant="outlined"
                    ></TextField>
                </Box>
                <Collapse in={mac !== ""}>
                    <Check
                        query_status={macBlockedStatus}
                        query_loading_text="Checking if the MAC Address is Blocked..."
                        condition={macBlocked?.length === 0}
                        success_text="The MAC Address does not appear to be blocked."
                        fail_text={`The MAC Address ${formatted_mac_address} is BLOCKED!!!`}
                    />
                    {ucinetid && (
                        <Check
                            query_status={networkRegistrationsStatus}
                            query_loading_text="Verifying the MAC Address is registered..."
                            condition={
                                networkRegistrations?.filter(
                                    (reg) =>
                                        reg.mac_address === bare_mac_address &&
                                        reg.responsible_ucinetid === ucinetid.toUpperCase()
                                ).length === 1
                            }
                            success_text={`This MAC Address is registered to ${ucinetid}.`}
                            fail_text={`This MAC Address is NOT registered to ${ucinetid}!`}
                        />
                    )}
                    {show_forescout && <ForescoutMonitor mac={bare_mac_address}></ForescoutMonitor>}
                </Collapse>
            </Grid>
        </Grid>
    )
}
