import React from "react"
import { CurrentUserLDAPContext } from "../PageStructure/LoginController/LoginController"

export const useCurrentUserInfo = () => {
    const ldap_data = React.useContext(CurrentUserLDAPContext)

    const is_admin_user = ldap_data?.memberOf?.includes(
        "cn=ITSVC - Help Desk Utilities - Administrator,ou=ksams,ou=groups,dc=uci,dc=edu"
    )

    return { ldap_data, is_admin_user }
}
