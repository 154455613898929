import { Box, CircularProgress, Collapse, Zoom } from "@mui/material"
import { red } from "@mui/material/colors"
import { PriorityHigh } from "@mui/icons-material"
import { Skeleton } from '@mui/material';
import React from "react"
import BlockHeader from "./BlockHeader"
import ErrorBoundary from "../../ErrorManagement/ErrorBoundary"
import SpinningCheckMark from "../SpinningCheckMark/SpinningCheckMark"

interface InfoBlockProps {
    title: string
    icon: React.ReactNode
    loading?: boolean
    hide?: boolean
    alert?: boolean

    /** Indicates that the component will have the spinning check mark */
    loadingIndicator?: boolean

    expanded?: boolean

    children: React.ReactNode

    mountOnEnter?: boolean
}

let InfoBlock = (props: InfoBlockProps) => {
    let {
        loading = false,
        hide = false,
        alert = false,
        loadingIndicator = true,
        expanded = false,
        mountOnEnter = false,
        title,
        icon,
        children,
    } = props

    let [expand, setExpand] = React.useState(expanded)

    let handleClick = () => {
        setExpand(!expand)
    }

    return (
        <Box
        // order={hide ? 10 : 0}
        >
            <Collapse in={true} appear={loadingIndicator} mountOnEnter={mountOnEnter}>
                <Box marginBottom="2rem">
                    <Box display="flex" alignItems="center" marginBottom="1rem">
                        <BlockHeader alert={alert} disabled={hide} onClick={handleClick}>
                            <Box display="flex" alignItems="center">
                                <Box marginRight="1rem">{icon}</Box>
                                {title}
                            </Box>
                        </BlockHeader>
                        <Box marginLeft="1rem">
                            {loadingIndicator &&
                                (loading ? (
                                    <CircularProgress size="2rem"></CircularProgress>
                                ) : (
                                    <SpinningCheckMark></SpinningCheckMark>
                                ))}
                        </Box>
                        <Zoom in={alert}>
                            <Box marginLeft="1rem" color={red[500]}>
                                <PriorityHigh></PriorityHigh>
                            </Box>
                        </Zoom>
                    </Box>
                    <Collapse in={expand && loading}>
                        <Skeleton variant="rectangular" height="5rem" width="100%"></Skeleton>
                    </Collapse>
                    <Collapse in={expand && !loading} mountOnEnter={true}>
                        <ErrorBoundary>{children}</ErrorBoundary>
                    </Collapse>
                </Box>
            </Collapse>
        </Box>
    );
}

export default InfoBlock
