import { Box, Paper, Button, buttonClasses } from "@mui/material"
import { blue, green, lightGreen, orange, purple, red } from "@mui/material/colors"
import { SupportGroup } from "../../apiInterfaces"
import { OrgHierarchy, Orgs } from "./DepartmentSupportAdmin"
import RenderIfVisible from 'react-render-if-visible'

interface DSTableBlockProps {
    org: string
    org_hierarchy: OrgHierarchy
    orgs: Orgs
    handleOrgClick: (org_code: string) => () => void
    support_groups: { [org_code: string]: SupportGroup }
    depth?: number
}

const getColor = (depth: number) => {
    const colors = [lightGreen[200], blue[200], green[200], orange[200], purple[200], red[200]]

    return colors[depth % colors.length]
}

export const DSTableBlock = (props: DSTableBlockProps) => {
    let { org, org_hierarchy, orgs, handleOrgClick, support_groups, depth = 0 } = props

    let children = org in org_hierarchy ? [...org_hierarchy[org]] : []

    let cc = 250 - depth * 15
    let bgcolor = org in support_groups ? getColor(depth) : `rgb(${cc},${cc},${cc})`

    return (
        <Paper>
            <Box
                display="flex"
                alignItems="center"
                paddingX="0.5rem"
                bgcolor={bgcolor}
                textAlign="center"
            >
                <Button
                    size="small"
                    onClick={handleOrgClick(org)}
                    sx={{
                        [`& .${buttonClasses["root"]}`]: {
                            fontSize: "inherit",
                        },
                    }}
                >
                    {orgs[org].org_name} ({org})
                </Button>

                {children.length > 0 && (
                    <Box display="flex" flexDirection="column" justifyContent="flex-start">
                        <RenderIfVisible stayRendered={true} root={this} visibleOffset={20000}>
                            {children.map((c) => (
                                <Box key={c} paddingX="1rem" paddingY="0.5rem">
                                    <DSTableBlock
                                        depth={depth + 1}
                                        org={c}
                                        org_hierarchy={org_hierarchy}
                                        orgs={orgs}
                                        handleOrgClick={handleOrgClick}
                                        support_groups={support_groups}
                                    ></DSTableBlock>
                                </Box>
                            ))}
                        </RenderIfVisible>
                    </Box>
                )}
            </Box>
        </Paper>
    )
}
