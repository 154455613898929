import React from "react"
import { LDAPForcedAttributes } from "../../../apiInterfaces"
import { ValidSearchType } from "../Search"
import { Avatar, Grid, ListItem, ListItemAvatar, ListItemText } from "@mui/material"
import {
    getColorForAffiliation,
    getIconForAffiliation,
    getMainAffiliation,
} from "../../Utilities/accountUtilities"
import { SearchSuggestion, SearchSuggestionsState } from "./SearchSuggestions"
import { useQueries } from "react-query"
import { fetchAccountSuggestions } from "../../../apiFunctions"

export const ID_NUMBER_REGEX = /^\d+$/
export const NAME_REGEX = /^[a-zA-Z '-]+$/
export const UCInetID_REGEX = /^[a-zA-Z0-9-_]*[a-zA-Z][a-zA-Z0-9-_]*$/

enum AccountInputType {
    id_number,
    name,
    ucinetid,
}

let removeDuplicateAsterisk = (str: string) => {
    return str.replaceAll(/\*{2,}/g, "*")
}

let determineQueries = (searchInput: string, searchType: Set<AccountInputType>) => {
    let spaces_as_wildcards = searchInput.replace(/\s/, "*")
    spaces_as_wildcards = "*" + spaces_as_wildcards + "*"

    spaces_as_wildcards = removeDuplicateAsterisk(spaces_as_wildcards)

    let queries: string[] = []
    for (let search_type of searchType) {
        switch (search_type) {
            case AccountInputType.ucinetid:
                queries.push(`(uid=${searchInput})`)
                queries.push(`(uid=${spaces_as_wildcards})`)
                break
            case AccountInputType.name:
                queries.push(`(displayName=${spaces_as_wildcards})`)
                break
            case AccountInputType.id_number:
                queries.push(`(uciStudentID=${spaces_as_wildcards})`)
                queries.push(`(employeeNumber=${spaces_as_wildcards})`)
                // queries.push(`(uciCampusID=${spaces_as_wildcards})`)
                // queries.push(`(uciUCNetID=${spaces_as_wildcards})`)
                break
        }
    }

    return queries
}

let determinePossibleInputTypes = (inputValue: string) => {
    let possible_input_types = new Set<AccountInputType>()

    // UCInetIDs must contain at least one letter
    if (UCInetID_REGEX.test(inputValue)) {
        possible_input_types.add(AccountInputType.ucinetid)
    }
    if (ID_NUMBER_REGEX.test(inputValue)) {
        possible_input_types.add(AccountInputType.id_number)
    }
    if (NAME_REGEX.test(inputValue)) {
        possible_input_types.add(AccountInputType.name)
    }

    return possible_input_types
}

let getSecondaryText = (r: LDAPForcedAttributes) => {
    let block: React.ReactNode = false

    let title = r.uciPrimaryTitle
    let department = r.uciPrimaryDepartment
    let gradeLevel = typeof r.uciStudentLevel === "string" ? r.uciStudentLevel : undefined
    let major = typeof r.uciStudentMajor === "string" ? r.uciStudentMajor : undefined

    let s = []
    if (title) {
        s.push(title)
    }
    if (department) {
        s.push(department)
    }
    if (s.length === 0 && major) {
        if (gradeLevel) {
            s.push(gradeLevel)
        }
        if (major) {
            s.push(major)
        }
    }
    if (s.length !== 0) {
        let str = s.join(" - ")
        block = (
            <>
                {str}
                <br></br>
            </>
        )
    }

    // MDP & Affiliations
    let mdp = r.uciMailDeliveryPoint
    let affiliations = r.uciAffiliation?.join(", ")

    s = []
    if (typeof mdp === "string") {
        s.push(`MDP: ${mdp}`)
    }
    if (affiliations) {
        s.push(`Affiliations: ${affiliations}`)
    }

    block = (
        <>
            {block}
            {s.join(" - ")}
        </>
    )

    return block
}

let useAccountSearchSuggestions = (searchInput: string): SearchSuggestionsState => {
    let query = React.useMemo(() => {
        if (searchInput.length === 0) {
            return []
        }

        let possible_input_types = determinePossibleInputTypes(searchInput)

        if (possible_input_types.size === 0) {
            return []
        }

        return determineQueries(searchInput, possible_input_types)
    }, [searchInput])

    let queryResults = useQueries(
        query.map((q) => ({
            queryKey: ["private-ldap-query", q],
            queryFn: fetchAccountSuggestions(q),
        }))
    )

    const isLoading = queryResults.some((r) => r.isLoading)
    const isError = queryResults.some((r) => r.isError)

    const data = React.useMemo(() => {
        // Merge and then sort
        const all_results = queryResults.flatMap((res) => res.data ?? [])

        let processed_uids = new Set()

        let entries = []
        for (let result of all_results) {
            if (!processed_uids.has(result.uid?.[0])) {
                processed_uids.add(result.uid?.[0])
                entries.push(result)
            }
        }

        return entries
    }, [queryResults])

    let generateSearchSuggestions = (): SearchSuggestion[] => {
        return (data ?? []).map((res) => ({
            value: res.uid[0],
            type: ValidSearchType.ucinetid,
            component: (
                <ListItem button>
                    <ListItemAvatar>
                        <Avatar
                            variant="rounded"
                            style={{
                                backgroundColor: getColorForAffiliation(
                                    getMainAffiliation(res.uciAffiliation)
                                ) as string,
                            }}
                        >
                            {getIconForAffiliation(getMainAffiliation(res.uciAffiliation))}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Grid container justifyContent="space-between">
                                <Grid item>{`${res.displayName} (${res.uid?.[0]})`}</Grid>
                            </Grid>
                        }
                        secondary={getSecondaryText(res)}
                    ></ListItemText>
                </ListItem>
            ),
        }))
    }

    return {
        loading: isLoading,
        error: isError,
        searchSuggestions: generateSearchSuggestions(),
    }
}

export default useAccountSearchSuggestions
