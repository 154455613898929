import {
    Collapse,
    List,
    ListItem,
    ListItemText,
    Paper,
    Table,
    TableBody,
    TableContainer,
    Typography,
} from "@mui/material"
import { DynamicFeed } from "@mui/icons-material"
import { Alert } from '@mui/material';
import React from "react"
import InfoBlock from "../../GenericBlocks/InfoBlock/InfoBlock"
import TableKeyValueRow from "../../GenericBlocks/TableKeyValueRow"
import { format_date_string } from "../../Utilities/dateUtilities"
import { useQuery } from "react-query"
import { fetchActiveDirectoryByUCInetID } from "../../../apiFunctions"

interface ActiveDirectoryInfoProps {
    ucinetid: string
    expanded?: boolean
}

let QuickDNListItem = (props: { group: string }) => {
    let [open, setOpen] = React.useState(false)

    return (
        <ListItem
            dense
            disableGutters
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
        >
            <ListItemText
                disableTypography
                primary={<Typography variant="body2">{get_dn_primary(props.group)}</Typography>}
                secondary={
                    <Collapse in={open}>
                        <Typography variant="body2" color="textSecondary">
                            {clean_dn_string(props.group)}
                        </Typography>
                    </Collapse>
                }
            ></ListItemText>
        </ListItem>
    )
}

let QuickDNList = (props: { items: string[] }) => {
    return (
        <List dense disablePadding>
            {props.items.map((group) => (
                <QuickDNListItem key={group} group={group}></QuickDNListItem>
            ))}
        </List>
    )
}

let clean_dn_string = (str: string) =>
    str
        .replaceAll(/CN=|OU=|DC=/g, "")
        .replaceAll(",ad,uci,edu", "")
        .replaceAll(",", " ➜ ")

let get_dn_primary = (str: string) => str.split(",")?.[0].replace("CN=", "")

const BEGINNING_OF_TIME = "1601-01-01T00:00:00+00:00"

let ActiveDirectoryInfo = (props: ActiveDirectoryInfoProps) => {
    let { ucinetid, expanded = false } = props

    const { isLoading, data: adInfo } = useQuery(
        ["active-directory", ucinetid],
        fetchActiveDirectoryByUCInetID(ucinetid)
    )

    return (
        <InfoBlock
            title="Active Directory Info"
            icon={<DynamicFeed></DynamicFeed>}
            loading={isLoading}
            expanded={expanded}
            hide={!isLoading && adInfo === undefined}
        >
            {!isLoading && adInfo === undefined && (
                <Alert severity="warning">
                    No Active Directory account found for <b>{ucinetid}</b>
                </Alert>
            )}
            <Paper>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableKeyValueRow title="Object Origin">
                                {adInfo?.dn.split(",").map((part) => (
                                    <div key={part}>{part}</div>
                                ))}
                            </TableKeyValueRow>
                            <TableKeyValueRow title="AD Email">
                                {adInfo?.attributes.userPrincipalName}
                            </TableKeyValueRow>
                            <TableKeyValueRow title="Exchange Email">
                                {adInfo?.attributes.mail}
                            </TableKeyValueRow>
                            <TableKeyValueRow title="Name">
                                {adInfo?.attributes.name}
                            </TableKeyValueRow>
                            <TableKeyValueRow title="Display Name">
                                {adInfo?.attributes.displayName}
                            </TableKeyValueRow>
                            <TableKeyValueRow title="AD Account Created On">
                                {adInfo?.attributes.whenCreated &&
                                    format_date_string(adInfo?.attributes.whenCreated)}
                            </TableKeyValueRow>
                            <TableKeyValueRow title="AD Account Updated On">
                                {adInfo?.attributes.whenChanged &&
                                    format_date_string(adInfo?.attributes.whenChanged)}
                            </TableKeyValueRow>
                            <TableKeyValueRow title="Exchange Groups">
                                {adInfo?.attributes.memberOf && (
                                    <QuickDNList items={adInfo?.attributes.memberOf}></QuickDNList>
                                )}
                            </TableKeyValueRow>
                            <TableKeyValueRow title="Shared Mailbox Access">
                                {adInfo?.attributes.publicDelegatesBL && (
                                    <QuickDNList
                                        items={adInfo?.attributes.publicDelegatesBL}
                                    ></QuickDNList>
                                )}
                            </TableKeyValueRow>
                            <TableKeyValueRow
                                title="Bad Password Count"
                                tooltip="The number of times the user tried to log into the account using an incorrect password. This may reset upon a successful login."
                            >
                                {adInfo?.attributes.badPwdCount}
                            </TableKeyValueRow>
                            <TableKeyValueRow
                                title="Last Bad Password On"
                                tooltip="The last time that an incorrect password was used to try to login to the account."
                            >
                                {adInfo?.attributes.badPasswordTime === BEGINNING_OF_TIME
                                    ? "N/A"
                                    : adInfo?.attributes.badPasswordTime &&
                                      format_date_string(adInfo.attributes.badPasswordTime)}
                            </TableKeyValueRow>
                            <TableKeyValueRow title="Last Logon">
                                {adInfo?.attributes.lastLogon &&
                                    format_date_string(adInfo.attributes.lastLogon)}
                            </TableKeyValueRow>
                            <TableKeyValueRow title="Password Last Changed On">
                                {adInfo?.attributes.pwdLastSet &&
                                    format_date_string(adInfo?.attributes.pwdLastSet)}
                            </TableKeyValueRow>
                            <TableKeyValueRow
                                title="Account Expires On"
                                tooltip="The date that the account will expire. If today's date is after this date then the user will not be able to login to their account."
                            >
                                {adInfo?.attributes.accountExpires === BEGINNING_OF_TIME
                                    ? "No Expiration Date"
                                    : adInfo?.attributes.accountExpires &&
                                      format_date_string(adInfo.attributes.accountExpires)}
                            </TableKeyValueRow>
                            <TableKeyValueRow
                                title="Account Locked On"
                                tooltip="The date that the account was locked out on."
                            >
                                {adInfo?.attributes.lockoutTime === BEGINNING_OF_TIME
                                    ? "Account Not Locked"
                                    : adInfo?.attributes.lockoutTime &&
                                      format_date_string(adInfo.attributes.lockoutTime)}
                            </TableKeyValueRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </InfoBlock>
    )
}

export default ActiveDirectoryInfo
