import React from "react"
import { BrowserRouter } from "react-router-dom"
import LoginController from "./components/PageStructure/LoginController/LoginController"
import { ThemeProvider, createTheme } from "@mui/material/styles"
import { blue, red, green } from "@mui/material/colors"
import { CssBaseline, useMediaQuery } from "@mui/material"
import RouteController from "./components/PageStructure/RouteController/RouteController"
import DynamicFavicons from "./components/PageStructure/DynamicFavicon/DynamicFavicon"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import { ApplicationStateManager } from "./components/PageStructure/ApplicationStateManager/ApplicationStateManager"
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: 60 * 1000, // 1 minute
        },
    },
})

export const App = () => {
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)")

    let [darkMode, setDarkMode] = React.useState(() => {
        let dark_mode: "true" | "false" | string | null = localStorage.getItem("dark_mode")
        switch (dark_mode) {
            case "true":
                return true
            case "false":
                return false
            default:
                return prefersDarkMode
        }
    })

    const theme = createTheme({
        palette: {
            primary: blue,
            secondary: green,
            error: red,
            mode: darkMode ? "dark" : "light",
        },
        typography: {
            fontFamily: "Nunito, Arial",
            fontSize: 16,
            fontWeightLight: 300,
            fontWeightRegular: 400,
            fontWeightMedium: 700,
            fontWeightBold: 900,
        },
        // components: {
        //     MuiCssBaseline: {
        //         styleOverrides: {
        //             body: darkMode ? darkScrollbar() : null,
        //         },
        //     },
        // },
    })

    let toggleDarkMode = () => {
        setDarkMode((dark) => {
            localStorage.setItem("dark_mode", !dark ? "true" : "false")
            return !dark
        })
    }

    return (
        <React.StrictMode>
            <BrowserRouter>
                {/* Remove this once React Query updates to v4 */}
                {/* @ts-ignore */}
                <QueryClientProvider client={queryClient}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <ApplicationStateManager>
                            <ThemeProvider theme={theme}>
                                <CssBaseline />
                                <LoginController>
                                    <DynamicFavicons />
                                    <RouteController toggleDarkMode={toggleDarkMode} />
                                </LoginController>
                            </ThemeProvider>
                            <ReactQueryDevtools></ReactQueryDevtools>
                        </ApplicationStateManager>
                    </LocalizationProvider>
                </QueryClientProvider>
            </BrowserRouter>
        </React.StrictMode>
    )
}
