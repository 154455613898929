import { Divider, Grid, Typography } from "@mui/material"
import { JPLSubnet as JPLSubnetInterface } from "../../../apiInterfaces"

let raw_bits_to_ip = (bits: Uint32Array) => {
    let parts = new Uint8Array(bits.buffer, 0, 4)
    return parts.reverse().join(".")
}

let convertCIDR = (network_addr: string) => {
    const CIDR_REGEX = /^(?<ip>[\d.]+)(\/(?<cidr>\d+))?$/
    const DEFAULT_CIDR = "24"

    let match = network_addr.match(CIDR_REGEX)

    if (match?.groups?.ip) {
        let ip = match.groups.ip
        let cidr = match.groups.cidr || DEFAULT_CIDR

        let ip_split = ip.split(".")
        let ip_split_raw = new Uint8Array(
            ip_split.map((ip) => parseInt(ip)).reverse()
        )

        let full_view = new Uint32Array(ip_split_raw.buffer)

        // CIDR Masking
        let cidr_parsed = parseInt(cidr)
        let mask = ((1 << (32 - cidr_parsed)) - 1) >>> 0

        let network_part = full_view[0] & ~mask
        let beginning = new Uint32Array(1)
        let end = new Uint32Array(1)
        let subnet = new Uint32Array(1)

        beginning[0] = network_part
        end[0] = network_part | mask
        subnet[0] = ~mask

        return {
            start: raw_bits_to_ip(beginning),
            end: raw_bits_to_ip(end),
            netmask: raw_bits_to_ip(subnet),
            assumed: !match.groups.cidr,
        }
    }

    return {}
}

interface JPLSubnetProps {
    subnet: JPLSubnetInterface
}

let JPLSubnet = (props: JPLSubnetProps) => {
    let { subnet } = props

    let cidr_info = convertCIDR(subnet.network_address)

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h6">
                    JPL Network: {subnet.jpl_network_name}
                </Typography>
                <Divider></Divider>
                <br></br>
            </Grid>
            <Grid item xs={12} md={6}>
                <b>Building ID: </b>
                {subnet.building}
                <br></br>
                <b>Network Address: </b>
                {subnet.network_address}
                <br></br>
                <b>Range{cidr_info.assumed && " (assumed)"}: </b>
                {cidr_info.start} - {cidr_info.end}
                <br></br>
                <b>Subnet Mask{cidr_info.assumed && " (assumed)"}: </b>
                {cidr_info.netmask}
            </Grid>
            <Grid item xs={12} md={6}>
                <b>Network Description: </b>
                {subnet.network_usage}
                <br></br>
                <b>Router: </b>
                {subnet.router}
                <br></br>
                <b>Additional Details: </b>
                {subnet.router_additional_details !== ""
                    ? subnet.router_additional_details
                    : "None"}
                <br></br>
                <b>Interface: </b>
                {subnet.interface}
                <br></br>
                <b>Notes: </b>
                {subnet.notes}
            </Grid>
        </Grid>
    )
}

export default JPLSubnet
