import React from "react"

let Venn = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			version="1.1"
			width="811px"
			height="791px"
			viewBox="-0.5 -0.5 811 791"
		>
			<defs />
			<g>
				<ellipse
					cx="410.5"
					cy="540.5"
					rx={250}
					ry={250}
					fillOpacity="0.6"
					fill="#f08705"
					stroke="none"
					pointerEvents="none"
				/>
				<ellipse
					cx="250.5"
					cy="250.5"
					rx={250}
					ry={250}
					fillOpacity="0.6"
					fill="#12aab5"
					stroke="none"
					pointerEvents="none"
				/>
				<ellipse
					cx="560.5"
					cy="250.5"
					rx={250}
					ry={250}
					fillOpacity="0.6"
					fill="#e85642"
					stroke="none"
					pointerEvents="none"
				/>
				<g transform="translate(134.5,231.5)">
					<switch>
						<foreignObject
							style={{ overflow: "visible" }}
							pointerEvents="all"
							width={162}
							height={27}
							requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
						>
							<div
								xmlns="http://www.w3.org/1999/xhtml"
								style={{
									display: "inline-block",
									fontSize: "24px",
									fontFamily: "Helvetica",
									color: "rgb(255, 255, 255)",
									lineHeight: "1.2",
									verticalAlign: "top",
									whiteSpace: "nowrap",
									textAlign: "center",
								}}
							>
								<div
									xmlns="http://www.w3.org/1999/xhtml"
									style={{ display: "inline-block", textAlign: "inherit", textDecoration: "inherit" }}
								>
									{"We Can't Code"}
								</div>
							</div>
						</foreignObject>
						<text x={81} y={26} fill="#FFFFFF" textAnchor="middle" fontSize="24px" fontFamily="Helvetica">
							{"We Can't Code"}
						</text>
					</switch>
				</g>
				<g transform="translate(534.5,231.5)">
					<switch>
						<foreignObject
							style={{ overflow: "visible" }}
							pointerEvents="all"
							width={161}
							height={27}
							requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
						>
							<div
								xmlns="http://www.w3.org/1999/xhtml"
								style={{
									display: "inline-block",
									fontSize: "24px",
									fontFamily: "Helvetica",
									color: "rgb(255, 255, 255)",
									lineHeight: "1.2",
									verticalAlign: "top",
									whiteSpace: "nowrap",
									textAlign: "center",
								}}
							>
								<div
									xmlns="http://www.w3.org/1999/xhtml"
									style={{ display: "inline-block", textAlign: "inherit", textDecoration: "inherit" }}
								>
									{"You Can't Type"}
								</div>
							</div>
						</foreignObject>
						<text x={81} y={26} fill="#FFFFFF" textAnchor="middle" fontSize="24px" fontFamily="Helvetica">
							{"You Can't Type"}
						</text>
					</switch>
				</g>
				<g transform="translate(288.5,561.5)">
					<switch>
						<foreignObject
							style={{ overflow: "visible" }}
							pointerEvents="all"
							width={254}
							height={27}
							requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
						>
							<div
								xmlns="http://www.w3.org/1999/xhtml"
								style={{
									display: "inline-block",
									fontSize: "24px",
									fontFamily: "Helvetica",
									color: "rgb(255, 255, 255)",
									lineHeight: "1.2",
									verticalAlign: "top",
									whiteSpace: "nowrap",
									textAlign: "center",
								}}
							>
								<div
									xmlns="http://www.w3.org/1999/xhtml"
									style={{ display: "inline-block", textAlign: "inherit", textDecoration: "inherit" }}
								>
									The Computer Gave Up
								</div>
							</div>
						</foreignObject>
						<text x={127} y={26} fill="#FFFFFF" textAnchor="middle" fontSize="24px" fontFamily="Helvetica">
							The Computer Gave Up
						</text>
					</switch>
				</g>
				<g transform="translate(350.5,187.5)">
					<switch>
						<foreignObject
							style={{ overflow: "visible" }}
							pointerEvents="all"
							width={119}
							height={36}
							requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
						>
							<div
								xmlns="http://www.w3.org/1999/xhtml"
								style={{
									display: "inline-block",
									fontSize: "16px",
									fontFamily: "Helvetica",
									color: "rgb(255, 255, 255)",
									lineHeight: "1.2",
									verticalAlign: "top",
									whiteSpace: "nowrap",
									textAlign: "center",
								}}
							>
								<div
									xmlns="http://www.w3.org/1999/xhtml"
									style={{ display: "inline-block", textAlign: "inherit", textDecoration: "inherit" }}
								>
									Computers
									<br />
									Are Meaningless
								</div>
							</div>
						</foreignObject>
						<text x={60} y={26} fill="#FFFFFF" textAnchor="middle" fontSize="16px" fontFamily="Helvetica">
							Computers&lt;br&gt;Are Meaningless
						</text>
					</switch>
				</g>
				<g transform="translate(261.5,407.5)">
					<switch>
						<foreignObject
							style={{ overflow: "visible" }}
							pointerEvents="all"
							width={58}
							height={17}
							requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
						>
							<div
								xmlns="http://www.w3.org/1999/xhtml"
								style={{
									display: "inline-block",
									fontSize: "16px",
									fontFamily: "Helvetica",
									color: "rgb(255, 255, 255)",
									lineHeight: "1.2",
									verticalAlign: "top",
									whiteSpace: "nowrap",
									textAlign: "center",
								}}
							>
								<div
									xmlns="http://www.w3.org/1999/xhtml"
									style={{ display: "inline-block", textAlign: "inherit", textDecoration: "inherit" }}
								>
									Anarchy
								</div>
							</div>
						</foreignObject>
						<text x={29} y={17} fill="#FFFFFF" textAnchor="middle" fontSize="16px" fontFamily="Helvetica">
							Anarchy
						</text>
					</switch>
				</g>
				<g transform="translate(517.5,407.5)">
					<switch>
						<foreignObject
							style={{ overflow: "visible" }}
							pointerEvents="all"
							width={46}
							height={17}
							requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
						>
							<div
								xmlns="http://www.w3.org/1999/xhtml"
								style={{
									display: "inline-block",
									fontSize: "16px",
									fontFamily: "Helvetica",
									color: "rgb(255, 255, 255)",
									lineHeight: "1.2",
									verticalAlign: "top",
									whiteSpace: "nowrap",
									textAlign: "center",
								}}
							>
								<div
									xmlns="http://www.w3.org/1999/xhtml"
									style={{ display: "inline-block", textAlign: "inherit", textDecoration: "inherit" }}
								>
									Chaos
								</div>
							</div>
						</foreignObject>
						<text x={23} y={17} fill="#FFFFFF" textAnchor="middle" fontSize="16px" fontFamily="Helvetica">
							Chaos
						</text>
					</switch>
				</g>
				<g transform="translate(375.5,337.5)">
					<switch>
						<foreignObject
							style={{ overflow: "visible" }}
							pointerEvents="all"
							width={69}
							height={17}
							requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
						>
							<div
								xmlns="http://www.w3.org/1999/xhtml"
								style={{
									display: "inline-block",
									fontSize: "16px",
									fontFamily: "Helvetica",
									color: "rgb(255, 255, 255)",
									lineHeight: "1.2",
									verticalAlign: "top",
									whiteSpace: "nowrap",
									textAlign: "center",
								}}
							>
								<div
									xmlns="http://www.w3.org/1999/xhtml"
									style={{ display: "inline-block", textAlign: "inherit", textDecoration: "inherit" }}
								>
									404 Page
								</div>
							</div>
						</foreignObject>
						<text x={35} y={17} fill="#FFFFFF" textAnchor="middle" fontSize="16px" fontFamily="Helvetica">
							404 Page
						</text>
					</switch>
				</g>
			</g>
		</svg>
	)
}

export default Venn
