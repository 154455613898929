export const convert_ip_to_bytes = (ip: string) => {
    if (ip === "") {
        return 0
    }

    let numbers = ip.split(".").map((s) => parseInt(s))

    while (numbers.length < 4) {
        numbers.push(1)
    }

    let bytes = new Uint8Array(numbers).reverse()

    let final_number = new Uint32Array(bytes.buffer)[0]

    return final_number
}
