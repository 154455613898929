import { Select, MenuItem, FormControl, InputLabel, Checkbox, ListItemText, SelectChangeEvent } from "@mui/material"
import { useState } from "react"


const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
            width: "inherit",
        },
    }
}

interface MultiSelectorProps {
    name: string,
    items: string[],
    defaultItemIndexes?: number[], //selected indexes
    onSelect: (selectedItems: string[]) => void
}

let MultiSelector = (props: MultiSelectorProps) => {
    let { name, items, defaultItemIndexes, onSelect } = props
    let [selectedItems, setSelectedItems] = useState(defaultItemIndexes ? defaultItemIndexes.map((index: number) => items[index]) : [])

    const handleSelect = (event: SelectChangeEvent<typeof items>) => {
        const { target: { value } } = event
        let selected = typeof (value) === 'string' ? value.split(',') : value
        setSelectedItems(selected)
        onSelect(selected)
    }

    return (
        <FormControl size="small" sx={{ width: "100%" }}>
            <InputLabel id={name}>{name}</InputLabel>
            <Select
                multiple
                name={name}
                label={name}
                value={selectedItems}
                onChange={handleSelect}
                MenuProps={MenuProps}
                renderValue={(selected) => {
                    //This makes sure the order of value follows the order of items.
                    let value = items.filter((item: string) => selected.indexOf(item) !== -1)
                    return value.join(",")
                }}
            >
                {items.map((value) => (
                    <MenuItem key={value} value={value} sx={{ height: ITEM_HEIGHT }}>
                        <Checkbox checked={selectedItems.indexOf(value) !== -1} />
                        <ListItemText primary={value} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl >
    )
}

export default MultiSelector