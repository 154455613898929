import { useQuery } from "react-query";
import MajorIncidentsDisplay from './MajorIncidentsDisplay';
import { fetchActiveServiceNowMajorIncidents, fetchActiveTempServiceAlerts } from "../../apiFunctions";
import { EditMajorIncidentsProps } from "./MajorIncidents";

const ActiveMajorIncidents: React.FC<EditMajorIncidentsProps> = ({ onEdit }) => {
  const { data: majorIncidents, isLoading: isLoadingMajorIncidents } = useQuery(
    "SN-active-major-incidents",
    fetchActiveServiceNowMajorIncidents(),
    {
      refetchInterval: 300000, // refetch every 5 min
      staleTime: 300000, // cache data for 5 minutes
      cacheTime: 900000, // keep the data in cache for 15 minutes
    }
  );

  const { data: tempServiceAlerts, isLoading: isLoadingTempServiceAlerts } = useQuery(
    "SN-active-temp-service-alerts",
    fetchActiveTempServiceAlerts(),
    {
      refetchInterval: 300000, // refetch every 5 min
      staleTime: 300000, // cache data for 5 minutes
      cacheTime: 900000, // keep the data in cache for 15 minutes
    }
  );
  
  const isLoading = isLoadingMajorIncidents || isLoadingTempServiceAlerts;

  const activeMajorIncidentList = [...(majorIncidents || []), ...(tempServiceAlerts || [])];

  return (
    <MajorIncidentsDisplay
      isLoading={isLoading}
      onEdit={onEdit}
      incidentList={activeMajorIncidentList}
      type="active"
    />
  );
};

export default ActiveMajorIncidents;