import { Typography, Paper, Box } from "@mui/material"
import { useState } from "react"
import { ZoomTicket } from "../../apiInterfaces"
import { fetchServiceNowZoomTickets } from "../../apiFunctions";
import { useQuery, UseQueryResult } from "react-query"
import UpcomingZoomTickets from "./UpcomingZoomTickets"
import PageParent from "../PageStructure/PageParent/PageParent"
import ToolHeader from "../GenericBlocks/ToolHeader"

export interface TicketProps {
    allTickets: ZoomTicket[],
    isLoading: boolean;
    refetch: UseQueryResult['refetch'];
}

let ZoomTicketSection = () => {

    const [tickets, setTickets] = useState<ZoomTicket[]>([])

    let {refetch, isLoading} = useQuery(
        "SN-zoom-tickets",
        fetchServiceNowZoomTickets(),
        {onSuccess: (fetchedZoomTickets) => {
            const zoomTicketList = fetchedZoomTickets.map((ticket) => {
                return {number: ticket.number || "", 
                        link: `https://uci.service-now.com/now/nav/ui/classic/params/target/incident.do%3Fsys_id%3D${ticket.sys_id}`,
                        description: ticket.short_description || "",
                        caller: ticket.caller_id || "",
                        zoom_time: ticket.u_incident_appointment}
            })
            setTickets(sortTicketsByZoomTime(zoomTicketList))
        }}
    )
   
    const sortTicketsByZoomTime = (tickets : ZoomTicket[]) => {
        return [...tickets].sort((a, b) => {
            if (a.zoom_time === null && b.zoom_time === null) {
                return 0; 
            } else if (a.zoom_time === null) {
                return -1; 
            } else if (b.zoom_time === null) {
                return 1; 
            } else {
                return Date.parse(a.zoom_time) - Date.parse(b.zoom_time);
            }
        });
    }

    return (
        <PageParent>
            <ToolHeader> Zoom Tickets</ToolHeader>
            <Box paddingBottom="2rem">
                <Typography gutterBottom>
                    The Zoom Ticket section will list all the tickets that require Zoom calls to ensure we don't forget about them. 
                    You can manually refresh and retrieve the latest Zoom tickets by clicking the refresh button.
                    Please feel free to share any suggestions on how it can be further improved.
                </Typography>
            </Box>
            <Paper elevation={3}>
                <UpcomingZoomTickets allTickets={tickets} isLoading={isLoading} refetch={refetch}/>
            </Paper>
        </PageParent>
    )
}

export default ZoomTicketSection