import React from "react"
import DepartmentInfo from "../DepartmentInfo/DepartmentInfo"
import { ViewProps } from "../Search/Search"
import DepartmentSupportByDepartmentNumber from "../AccountInformation/DepartmentSupport/DepartmentSupportByDepartmentNumber"
import { useQuery } from "react-query"
import { fetchKFSOrgByNumber, fetchKFSOrgContactByNumber } from "../../apiFunctions"

let DepartmentView = (props: ViewProps) => {
    let department_number = props.searchValue

    let {isLoading: loading_department, data: department } = useQuery(
        ["kfs-org-by-org-number", department_number],
        fetchKFSOrgByNumber(department_number)
    )

    let {isLoading: loading_contact, data: contact } = useQuery(
        ["kfs-org-contact-by-org-number", department_number],
        fetchKFSOrgContactByNumber(department_number)
    )

    return (
        <>
            <DepartmentInfo
                loading={loading_department && loading_contact}
                department={department}
                contact={contact}
            ></DepartmentInfo>
            <DepartmentSupportByDepartmentNumber
                department_number={department_number}
            ></DepartmentSupportByDepartmentNumber>
        </>
    )
}

export default DepartmentView
