import { MACVendorInfo, NetworkRegistration } from "../../apiInterfaces"
import { fetchRegistrationsByUCInetID, fetchVendorForMACAddress } from "../../apiFunctions"
import { useQueries, useQuery, UseQueryResult } from "react-query"

interface NetworkRegistrationData {
    loading: boolean
    registrations: NetworkRegistration[]
    error: boolean
}

interface VendorMap {
    mac: string
    vendor: MACVendorInfo | null
}

let useNetworkRegistrations = (ucinetid: string): NetworkRegistrationData => {
    let {
        isLoading: netreg_loading,
        isError: netreg_error,
        data: registrations,
    } = useQuery(
        ["network-registrations-by-ucinetid", ucinetid],
        fetchRegistrationsByUCInetID(ucinetid)
    )

    let vendorInfo = useQueries(
        registrations?.map((reg) => ({
            queryKey: ["vendor-info-for-mac", reg.mac_address],
            queryFn: async () => {
                let vendor = await fetchVendorForMACAddress(reg.mac_address)()

                let r: VendorMap = {
                    mac: reg.mac_address,
                    vendor: vendor ?? null,
                }
                return r
            },
        })) ?? []
    ) as unknown as UseQueryResult<VendorMap, unknown>[]

    // Convert the vendor info to a map
    let vendorInfoMap: { [key: string]: MACVendorInfo | null } = {}
    for (let v of vendorInfo) {
        if (v.data) {
            vendorInfoMap[v.data.mac] = v.data.vendor
        }
    }

    return {
        loading: netreg_loading,
        error: netreg_error,
        registrations:
            registrations?.map((r) => {
                if (r.mac_address in vendorInfoMap) {
                    r.vendor = vendorInfoMap[r.mac_address] ?? null
                }
                return r
            }) ?? [],
    }
}

export default useNetworkRegistrations
