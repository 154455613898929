import { Box, Grid, Paper, Typography } from "@mui/material"
import { ErrorOutline } from "@mui/icons-material"
import PropTypes from "prop-types"
import React from "react"

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.log(error, errorInfo)
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <Paper>
                    <Box padding="2rem">
                        <Grid container direction="column" alignItems="center">
                            <Grid item>
                                <ErrorOutline />
                            </Grid>
                            <Grid item>
                                <Typography>
                                    Something went wrong. Please tell our devs
                                    about this issue!
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            )
        }

        return this.props.children
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node,
}

export default ErrorBoundary
