import {AdobeLicenseInfo, ModelerLicenseInfo } from "../../apiInterfaces"
import { useQuery } from "react-query"
import { fetchAdobeLicenseInfoByUCInetID, fetchModelerLicenseInfoByUCInetID } from "../../apiFunctions"

interface LicenseInfo {
    adobe?: AdobeLicenseInfo
    adobeLoading: boolean
    adobeError: boolean
    modeler?: ModelerLicenseInfo
    modelerLoading: boolean
    modelerError: boolean
}

let useLicenseInfo = (ucinetid: string): [LicenseInfo]=> {
    let {isFetching: adobeLoading, isError: adobeError, data: adobeData} = useQuery(
        ["fetch-adobe-license-by-ucinetid", ucinetid], 
        fetchAdobeLicenseInfoByUCInetID(ucinetid)
    )

    let {isFetching: modelerLoading, isError: modelerError, data: modelerData} = useQuery(
        ["fetch-modeler-license-by-ucinetid", ucinetid],
        fetchModelerLicenseInfoByUCInetID(ucinetid)
    )

    

    return [{ 
        adobe: adobeData, 
        adobeLoading: adobeLoading, 
        adobeError: adobeError, 
        modeler: modelerData, 
        modelerLoading: modelerLoading, 
        modelerError: modelerError
    }]
}

export default useLicenseInfo
