import { useQuery } from "react-query";
import MajorIncidentsDisplay from './MajorIncidentsDisplay';
import { fetchClosedServiceNowMajorIncidents, fetchClosedTempServiceAlerts  } from "../../apiFunctions";
import { EditMajorIncidentsProps } from "./MajorIncidents";

const ClosedMajorIncidents: React.FC<EditMajorIncidentsProps> = ({ onEdit }) => {
  const { data: majorIncidents, isLoading: isLoadingMajorIncidents } = useQuery(
    "SN-closed-major-incidents",
    fetchClosedServiceNowMajorIncidents(),
    {
      refetchInterval: 300000, // refetch every 5 min
      staleTime: 300000, // cache data for 5 minutes
      cacheTime: 900000, // keep the data in cache for 15 minutes
    }
  );

  const { data: tempServiceAlerts, isLoading: isLoadingTempServiceAlerts } = useQuery(
    "SN-closed-temp-service-alerts",
    fetchClosedTempServiceAlerts(),
    {
      refetchInterval: 300000, // refetch every 5 min
      staleTime: 300000, // cache data for 5 minutes
      cacheTime: 900000, // keep the data in cache for 15 minutes
    }
  );

  const isLoading = isLoadingMajorIncidents || isLoadingTempServiceAlerts;

  const closedMajorIncidentList = [...(majorIncidents || []), ...(tempServiceAlerts || [])];

  return (
    <MajorIncidentsDisplay
      isLoading={isLoading}
      onEdit={onEdit}
      incidentList={closedMajorIncidentList}
      type="closed"
    />
  );
};

export default ClosedMajorIncidents;