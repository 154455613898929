import React from "react"

const partyParrotLinks = [
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/60fpsparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/accessibleparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/angelparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/angryparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/astronautparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/aussiecongaparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/aussieparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/aussiereversecongaparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/autonomousparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/backwardsparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/balconyparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/beerparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/beretparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/bikerparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/biparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/birthdaypartyparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/bluntparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/bobaparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/boomparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/bootlegparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/boredparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/bouncingparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/brazilianfanparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/brazilianplayerparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/bunnyparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/cakeparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/calvinist_parrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/ceilingparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/chicoparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/christmasparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/confusedparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/congaparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/congapartyparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/copparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/covid19parrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/dadparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/daftpunkparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/dailyparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/darkmodeparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/dealwithitnowparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/dealwithitparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/discoparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/docparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/donutparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/ethparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/everythingsfineparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/evilparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/fastparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/flowerparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/flyingmoneyparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/footballparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/frenchparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/gentlemanparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/githubparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/glimpseparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/gothparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/grouchoparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/hanamiparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/hardhatparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/harpoparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/headbangingparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/headingparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/headsetparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/hmmparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/horizontalparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/hypnoparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/hypnoparrotdark.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/hypnoparrotlight.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/illuminatiparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/imposterparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/innersourceparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/inverseparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/invisibleparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/jediparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/jumpingparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/jumpingparrotjr.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/kindasusparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/laptop_parrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/levitationparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/maracasparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/mardigrasparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/marshmallowparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/mateparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/meldparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/mergeconflictparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/michaeljacksonparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/middleparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/moonparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/moonwalkingparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/mustacheparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/nodeparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/norwegianblueparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/opensourceparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/originalparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/parrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/parrotnotfound.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/partyparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/phparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/picassoparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/pingpongparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/pirateparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/playcatchleftparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/playcatchrightparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/pokeparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/popcornparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/portalblueparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/portalorangeparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/pumpkinparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/quadparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/raceconditionparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/redbullparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/redenvelopeparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/redhatparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/reeeparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/reversecongaparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/reverseparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/reverseportalblueparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/reverseportalorangeparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/revolutionparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/rubyparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/rythmicalparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/sadparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/sassyparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/scienceparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/sherlockholmesparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/shortparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/shuffleparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/sidewaysparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/sintparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/sithparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/sleepingparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/slowparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/sneezyparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/spinningparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/spyparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/stableparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/stayhomeparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/stayhomeparrotcloser.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/stayhomeparrotwindow.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/staytfhomeparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/sushiparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/tennisparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/thankyouparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/thefastestparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/thumbsupparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/tiedyeparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/tinfoilhatparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/tpparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/transparront.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/turndownforwatchparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/twinsparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/ultrafastparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/vaccineparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/verticalparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/vikingparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/vueparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/wendyparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/wfhparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/whitewalkerparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/wineparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/yosemitesamparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/zombieparrot.gif",
    "https://cdn.jsdelivr.net/gh/jmhobbs/cultofthepartyparrot.com@master/parrots/hd/zoukparrot.gif",
]

export const useRandomPartyParrot = () => {
    const [parrotURL] = React.useState(() => {
        let idx = Math.floor(Math.random() * partyParrotLinks.length)
        return partyParrotLinks[idx]
    })

    return parrotURL
}
