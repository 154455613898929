import { useQuery } from "react-query"
import { fetchActiveVisitorRegistrationsByMACAddress } from "../../../apiFunctions"
import { remove_mac_address_separators } from "../../Utilities/macUtilities"

let useVisitorNetworkRegistrations = (searchValue: string) => {
    searchValue = remove_mac_address_separators(searchValue)

    const is_enabled = searchValue.length >= 2

    let { isLoading, isError, data } = useQuery(
        ["visitor-network-registrations-by-mac", searchValue],
        fetchActiveVisitorRegistrationsByMACAddress(searchValue),
        { enabled: is_enabled }
    )

    return {
        loading: isLoading,
        error: isError,
        registrations: is_enabled ? data ?? [] : [],
    }
}

export default useVisitorNetworkRegistrations
