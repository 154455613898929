import React from "react"
import AlertList from "./AlertList"

export interface Alert {
    key: string
    element: React.ReactNode
}

export interface AlertProps {
    addAlert: (key: string, element: React.ReactNode) => void
}

interface AlertsList {
    [key: string]: Alert
}

let useAlerts = () => {
    let [alerts, setAlerts] = React.useState<AlertsList>({})

    const addAlert = (key: string, element: React.ReactNode) => {
        console.log(key, element)
        setAlerts((alerts) => {
            if (!alerts[key]) {
                return { ...alerts, [key]: { key: key, element: element } }
            } else {
                return alerts
            }
        })
    }

    const clearAlerts = () =>{
        setAlerts({})
    }

    return {
        addAlert: addAlert,
        clearAlerts: clearAlerts,
        AlertList: <AlertList alerts={Object.values(alerts)}></AlertList>
    }
}

export default useAlerts
