import React from "react"
import { ComponentKey, ComponentKeys } from "../../Settings/ComponentKeys"

interface ApplicationStateManagerProps {
    children: React.ReactNode
}

interface ApplicationState {
    account_blocks: ComponentKey[]
    account_expanded_blocks: ComponentKey[]
}

/** Remember that this must be JSON.stringify compatible!!! */
const INITIAL_APPLICATION_STATE: ApplicationState = {
    account_blocks: [...ComponentKeys],
    account_expanded_blocks: [],
}

type ApplicationAction =
    | { type: "clear" }
    | { type: "set-application-state"; payload: ApplicationState }
    | { type: "set-account-blocks"; payload: ComponentKey[] }
    | { type: "set-account-expanded-blocks"; payload: ComponentKey[] }

export const APPLICATION_STATE_CONTEXT = React.createContext(
    (undefined as unknown) as [ApplicationState, React.Dispatch<ApplicationAction>]
)

export const reducer = (state: ApplicationState, action: ApplicationAction): ApplicationState => {
    switch (action.type) {
        case "clear":
            return { account_blocks: [], account_expanded_blocks: [] }
        case "set-application-state":
            return action.payload
        case "set-account-blocks":
            return { ...state, account_blocks: action.payload }
        case "set-account-expanded-blocks":
            return { ...state, account_expanded_blocks: action.payload }
        default:
            console.log("UNIMPLEMENTED ACTION!!!")
            return state
    }
}

export const ApplicationStateManager = (props: ApplicationStateManagerProps) => {
    const [state, dispatch] = React.useReducer(reducer, INITIAL_APPLICATION_STATE)

    React.useEffect(() => {
        let json_app_state = localStorage.getItem("application_state")
        if (json_app_state) {
            try {
                let app_state = JSON.parse(json_app_state)
                dispatch({
                    type: "set-application-state",
                    payload: {
                        ...INITIAL_APPLICATION_STATE,
                        ...app_state,
                    },
                })
            } catch {
                localStorage.removeItem("application_state")
            }
        }
    }, [])

    React.useEffect(() => {
        localStorage.setItem("application_state", JSON.stringify(state))
    }, [state])

    return (
        <APPLICATION_STATE_CONTEXT.Provider value={[state, dispatch]}>
            {props.children}
        </APPLICATION_STATE_CONTEXT.Provider>
    )
}
