import { Avatar, ListItem, ListItemAvatar, ListItemText } from "@mui/material"
import { green, lightGreen } from "@mui/material/colors"
import { SettingsEthernet, WifiTethering } from "@mui/icons-material"
import React from "react"
import { NetworkRegistration, VisitorNetworkRegistration } from "../../../../apiInterfaces"
import { format_mac_address, remove_mac_address_separators } from "../../../Utilities/macUtilities"
import { ValidSearchType } from "../../Search"
import { SearchSuggestion, SearchSuggestionsState } from "../SearchSuggestions"
import useForescoutMACSearch from "../useForescoutMACSearch"
import ForescoutSearchSuggestion from "./ForescoutSearchSuggestion"
import useNetworkRegistrations from "../useNetworkRegistrations"
import _ from "lodash"
import useVisitorNetworkRegistrations from "../useVisitorNetworkRegistrations"

let calculate_new_index = (s: string, index: number) => {
    let actual_index = 0

    while (index > 0) {
        actual_index++
        if (s[actual_index] !== ":") {
            index--
        }
    }

    return actual_index
}

export const calculate_bolded_sections = (mac_address: string, searchValue: string) => {
    searchValue = remove_mac_address_separators(searchValue)

    let beginning = mac_address.indexOf(searchValue)
    let end = beginning + searchValue.length

    let mac = format_mac_address(mac_address)?.toUpperCase()

    beginning = calculate_new_index(mac, beginning)
    end = calculate_new_index(mac, end)

    let first_part = mac?.substring(0, beginning)
    let bolded = mac?.substring(beginning, end)
    let last_part = mac?.substring(end, mac.length)

    if (bolded.endsWith(":")) {
        bolded = bolded.substring(0, bolded.length - 1)
        last_part = ":" + last_part
    }

    return {
        first_part,
        bolded,
        last_part,
    }
}

let NetworkRegistrationSearchSuggestion = (
    registration: NetworkRegistration,
    searchValue: string
) => {
    let mac_address = registration.mac_address

    let { first_part, bolded, last_part } = calculate_bolded_sections(mac_address, searchValue)

    return (
        <ListItem key={mac_address} button>
            <ListItemAvatar>
                <Avatar style={{ backgroundColor: green["A400"] }}>
                    <SettingsEthernet></SettingsEthernet>
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={
                    <>
                        {first_part}
                        <b>{bolded}</b>
                        {last_part}
                    </>
                }
                secondary={
                    <>
                        Registered To: {registration.responsible_ucinetid}{" "}
                        {registration.comments && "|"}{" "}
                        {_.truncate(registration.comments, { length: 49 })}
                        <br></br>
                        Network Registration
                    </>
                }
            ></ListItemText>
        </ListItem>
    )
}

let VisitorNetworkRegistrationSearchSuggestion = (
    registration: VisitorNetworkRegistration,
    searchValue: string
) => {
    let mac_address = registration.mac_address

    let { first_part, bolded, last_part } = calculate_bolded_sections(mac_address, searchValue)

    return (
        <ListItem key={mac_address} button>
            <ListItemAvatar>
                <Avatar style={{ backgroundColor: lightGreen["A400"] }}>
                    <WifiTethering></WifiTethering>
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={
                    <>
                        {first_part}
                        <b>{bolded}</b>
                        {last_part}
                    </>
                }
                secondary={
                    <>
                        {registration.name} | {registration.email_address} | {registration.reason}
                        {registration.reason_other !== null && <> | {registration.reason_other}</>}
                        <br></br>
                        Visitor Network Registration
                    </>
                }
            ></ListItemText>
        </ListItem>
    )
}

let ForcedMACSuggestion = (mac_address: string, searchValue: string) => {
    return (
        <ListItem key={mac_address} button>
            <ListItemAvatar>
                <Avatar style={{ backgroundColor: green["A400"] }}>
                    <SettingsEthernet></SettingsEthernet>
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={<b>{mac_address}</b>}
                secondary={<>MAC Address</>}
            ></ListItemText>
        </ListItem>
    )
}

let could_be_mac_address = (s: string) => {
    let mac_chars = Boolean(s.match(/^[0-9a-fA-F:-]{5,}$/g))
    let good_length = s.length <= 17
    let no_whitespace = !s.match(/\s/g)

    return mac_chars && good_length && no_whitespace
}

let useMACAddressSearchSuggestions = (trueSearchValue: string): SearchSuggestionsState => {
    let trimmed = trueSearchValue.trim()

    let could_be_mac = could_be_mac_address(trimmed)
    let searchValue = could_be_mac ? remove_mac_address_separators(trimmed) : ""


    let registrations = useNetworkRegistrations(searchValue)
    let visitor_registrations = useVisitorNetworkRegistrations(searchValue)
    let forescout = useForescoutMACSearch(searchValue)

    let results = React.useMemo(() => {
        let forescout_keys = new Set(forescout.hosts.map((h) => h.mac))

        let forescout_hosts: SearchSuggestion[] = forescout.hosts.map((host) => ({
            value: format_mac_address(host.mac),
            type: ValidSearchType.mac_address,
            component: (
                <ForescoutSearchSuggestion
                    key={host.mac}
                    forescout_info={host}
                    searchValue={searchValue}
                ></ForescoutSearchSuggestion>
            ),
        }))

        let network_registrations: SearchSuggestion[] = registrations.registrations
            .filter((r) => !forescout_keys.has(r.mac_address))
            .map((reg) => ({
                value: format_mac_address(reg.mac_address),
                type: ValidSearchType.mac_address,
                component: NetworkRegistrationSearchSuggestion(reg, searchValue),
            }))

        let visitor_regs: SearchSuggestion[] = visitor_registrations.registrations.map((reg) => ({
            value: format_mac_address(reg.mac_address),
            type: ValidSearchType.mac_address,
            component: VisitorNetworkRegistrationSearchSuggestion(reg, searchValue),
        }))

        return [...visitor_regs, ...forescout_hosts, ...network_registrations]
    }, [searchValue, registrations, visitor_registrations, forescout])

    let forced_search = trimmed.match(/(?:[0-9a-fA-F]{2}[:-]?){5}[0-9a-fA-F]{2}/)
        ? [
              {
                  value: format_mac_address(trimmed),
                  type: ValidSearchType.mac_address,
                  component: ForcedMACSuggestion(trimmed, trimmed),
              },
          ]
        : []

    return {
        loading: registrations.loading || forescout.loading,
        error: false,
        searchSuggestions: [...forced_search, ...results],
    }
}

export default useMACAddressSearchSuggestions
