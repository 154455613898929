import { Paper, Table, TableBody, TableContainer, Tooltip } from "@mui/material"
import { Router } from "@mui/icons-material"
import React from "react"
import InfoBlock from "../../GenericBlocks/InfoBlock/InfoBlock"
import TableKeyValueRow from "../../GenericBlocks/TableKeyValueRow"
import { format_date_string } from "../../Utilities/dateUtilities"
import { RESNET_SUBNET_TO_SEGMENT } from "../../../data/resnet_segments"
import { useQuery } from "react-query"
import { fetchForescoutHostByMAC } from "../../../apiFunctions"

// interface ForescoutProps {}

interface ForescoutPropsMacAddress {
    mac_addr: string
}

// interface ForescoutPropsIPAddress {
//     ip_addr: string
// }

interface TooltipWrapperProps {
    last_updated?: number
    children?: React.ReactElement | string
}
let TooltipWrapper = (props: TooltipWrapperProps) =>
    props.last_updated ? (
        <Tooltip
            title={`Last Updated: ${format_date_string(props.last_updated * 1000)}`}
            placement="top"
        >
            <span>{props.children}</span>
        </Tooltip>
    ) : null

let Forescout = (props: ForescoutPropsMacAddress) => {
    let { mac_addr } = props

    let { isLoading, isError, data } = useQuery(
        ["forescout-host-by-mac", mac_addr],
        fetchForescoutHostByMAC(mac_addr),
        { enabled: Boolean(mac_addr) }
    )

    let forescout_community = React.useMemo(() => {
        let bdomain = data?.host?.fields?.bdomain?.value as keyof typeof RESNET_SUBNET_TO_SEGMENT

        return RESNET_SUBNET_TO_SEGMENT[bdomain ?? ""]
    }, [data])

    let host = data?.host

    return (
        <InfoBlock title="Forescout" icon={<Router></Router>} loading={isLoading} hide={isError}>
            <Paper>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableKeyValueRow title="IP Address">{host?.ip}</TableKeyValueRow>
                            <TableKeyValueRow title="Online/Connection Active">
                                <TooltipWrapper last_updated={host?.fields?.online?.timestamp}>
                                    {host?.fields?.online?.value.toUpperCase()}
                                </TooltipWrapper>
                            </TableKeyValueRow>
                            <TableKeyValueRow title="Has Seen Packet?">
                                <TooltipWrapper
                                    last_updated={host?.fields?.engine_seen_packet?.timestamp}
                                >
                                    {host?.fields?.engine_seen_packet?.value.toUpperCase()}
                                </TooltipWrapper>
                            </TableKeyValueRow>
                            <TableKeyValueRow title="Mobile Access Registered?">
                                <TooltipWrapper
                                    last_updated={host?.fields?.dex_MobileAccess?.timestamp}
                                >
                                    {host?.fields?.dex_MobileAccess?.value.toUpperCase()}
                                </TooltipWrapper>
                            </TableKeyValueRow>
                            <TableKeyValueRow title="Mobile Access User (According to Forescout)">
                                <TooltipWrapper
                                    last_updated={host?.fields?.dex_MobileAccessUser?.timestamp}
                                >
                                    {host?.fields?.dex_MobileAccessUser?.value}
                                </TooltipWrapper>
                            </TableKeyValueRow>
                            <TableKeyValueRow title="Vendor (Based off of MAC Address)">
                                <TooltipWrapper
                                    last_updated={host?.fields?.mac_vendor_string?.timestamp}
                                >
                                    {host?.fields?.mac_vendor_string?.value}
                                </TooltipWrapper>
                            </TableKeyValueRow>
                            <TableKeyValueRow title="Device Hostname (What the Device Calls Itself)">
                                <TooltipWrapper
                                    last_updated={host?.fields?.dhcp_hostname_v2?.timestamp}
                                >
                                    {host?.fields?.dhcp_hostname_v2?.value}
                                </TooltipWrapper>
                            </TableKeyValueRow>
                            <TableKeyValueRow title="Device Fingerprint">
                                <TooltipWrapper last_updated={host?.fields?.fingerprint?.timestamp}>
                                    {host?.fields?.fingerprint?.value}
                                </TooltipWrapper>
                            </TableKeyValueRow>
                            <TableKeyValueRow title="DHCP Device Type">
                                <TooltipWrapper
                                    last_updated={host?.fields?.dhcp_class_v2?.timestamp}
                                >
                                    {host?.fields?.dhcp_class_v2?.value}
                                </TooltipWrapper>
                            </TableKeyValueRow>
                            <TableKeyValueRow title="Network Hostname">
                                <TooltipWrapper last_updated={host?.fields?.hostname?.timestamp}>
                                    {host?.fields?.hostname?.value}
                                </TooltipWrapper>
                            </TableKeyValueRow>
                            <TableKeyValueRow
                                title="Community"
                                tooltip="Data for determining community is *NOT* automatically collected. If new communities are added to Forescout or if the network layout for Forescout communities changes, the data will need to be updated. If this field is blank and or inaccurate, please let the developers know!"
                            >
                                {forescout_community}
                            </TableKeyValueRow>
                            <TableKeyValueRow title="Subnet">
                                <TooltipWrapper last_updated={host?.fields?.bdomain?.timestamp}>
                                    {host?.fields?.bdomain?.value}
                                </TooltipWrapper>
                            </TableKeyValueRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </InfoBlock>
    )
}

export default Forescout
