import {
    Paper,
    Table,
    TableContainer,
    TableBody,
    ListItem,
    ListItemText,
    List,
    Avatar,
    Box,
    ListItemAvatar,
    Typography,
} from "@mui/material"
import { orange, green } from "@mui/material/colors"
import { Email, Group, Person, Phone } from "@mui/icons-material"
import { SupportGroup } from "../../apiInterfaces"
import InfoBlock from "../GenericBlocks/InfoBlock/InfoBlock"
import TableKeyValueRow from "../GenericBlocks/TableKeyValueRow"

import SupportedDepartment from "./SupportedDepartment"

interface SupportGroupInfoProps {
    loading: boolean
    support_group?: SupportGroup
}

let SupportGroupInfo = (props: SupportGroupInfoProps) => {
    let { loading, support_group } = props

    return (
        <>
            <InfoBlock title="Support Group" icon={<Group></Group>} loading={loading} expanded>
                <Paper>
                    <TableContainer>
                        <Table>
                            <TableBody>
                                <TableKeyValueRow title="Name">
                                    {support_group?.name}
                                </TableKeyValueRow>
                                <TableKeyValueRow title="Supported Departments">
                                    <List disablePadding>
                                        {support_group?.supported_department_numbers.map((num) => (
                                            <SupportedDepartment
                                                key={num}
                                                org_number={num}
                                            ></SupportedDepartment>
                                        ))}
                                    </List>
                                </TableKeyValueRow>
                                <TableKeyValueRow title="Contacts">
                                    {support_group?.contacts.map((contact) => (
                                        <ListItem disableGutters key={contact.name}>
                                            <ListItemAvatar>
                                                <Avatar
                                                    style={{
                                                        backgroundColor:
                                                            contact.phone_number_private
                                                                ? orange[500]
                                                                : green[500],
                                                    }}
                                                >
                                                    {contact.phone_number ? (
                                                        <Phone></Phone>
                                                    ) : contact.email ? (
                                                        <Email></Email>
                                                    ) : (
                                                        <Person></Person>
                                                    )}
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                disableTypography
                                                primary={contact.name}
                                                secondary={
                                                    <Box display="flex" flexDirection="column">
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                        >
                                                            {contact.phone_number_private &&
                                                                "PRIVATE: "}
                                                            {contact.phone_number}
                                                            <br />
                                                            {contact.email}
                                                        </Typography>
                                                    </Box>
                                                }
                                            ></ListItemText>
                                        </ListItem>
                                    ))}
                                </TableKeyValueRow>
                                <TableKeyValueRow title="Notes">
                                    {support_group?.notes ?? "This Support Group has No Notes"}
                                </TableKeyValueRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </InfoBlock>
        </>
    )
}

export default SupportGroupInfo
