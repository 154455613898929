import { Table, TableHead, TableBody, TableRow, TableContainer, TableCell, Link, IconButton, Tooltip, CircularProgress } from "@mui/material"
import ReplayIcon from '@mui/icons-material/Replay';
import { TicketProps } from "./ZoomTicketSection"

let UpcomingZoomTickets = ({ allTickets, isLoading, refetch } : TicketProps) => {
    
    const onRefresh = () => {
        refetch()
    }

    return (
        <>
        <TableContainer sx={{maxHeight: 500, minHeight: 500}}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Number</TableCell>
                        <TableCell>Short Description</TableCell>
                        <TableCell>Caller</TableCell>
                        <TableCell>
                            <Tooltip title="Refresh">
                                <IconButton onClick={onRefresh}>  
                                    <ReplayIcon />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { isLoading ? (
                        <TableRow>
                            <TableCell colSpan={4} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    ) : (
                        allTickets.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={4} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                    No Upcoming Zoom Tickets, yay!
                                </TableCell>
                            </TableRow>
                        ) : (
                            allTickets.map((ticket) => (
                                <TableRow key={ticket.number}>
                                    <TableCell><Link href={ticket.link || ''} target="_blank" rel="noopener noreferrer">{ticket.number}</Link></TableCell>
                                    <TableCell>{ticket.description}</TableCell>
                                    <TableCell>{ticket.caller}</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            ))
                        )
                    )} 
                </TableBody>
            </Table>
        </TableContainer>
        </>
    )
}

export default UpcomingZoomTickets