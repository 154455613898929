import React from "react"
import { Typography, TypographyProps } from "@mui/material"
import { blue, red } from "@mui/material/colors"

interface BlockHeaderProps extends TypographyProps {
    alert?: boolean
    disabled?: boolean
    children: React.ReactNode
    // [propName: string]: any // This is needed to support the ...otherProps
}

let BlockHeader = (props: BlockHeaderProps) => {
    let { alert = false, disabled = false, children, ...otherProps } = props

    return (
        <Typography
            variant="h4"
            sx={{
                fontWeight: "bold",
                transition: "color 0.25s",
                cursor: "pointer",
                ...(alert
                    ? {
                          color: red[500],
                          "&:hover": {
                              color: red["A700"],
                          },
                      }
                    : disabled
                    ? { color: "text.disabled" }
                    : {
                          "&:hover": {
                              color: blue[500],
                          },
                      }),
            }}
            {...otherProps}
        >
            {children}
        </Typography>
    )
}

export default BlockHeader
