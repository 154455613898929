import { Box, Collapse, Link, Paper, Typography } from "@mui/material"
import React from "react"
import { Link as RouterLink } from "react-router-dom"
import BlockList from "../NetworkInformation/BlockList/BlockList"
import useBlockList from "../DataHooks/useBlockList"
import useMACAddressNetworkRegistrations from "../DataHooks/useMACAddressNetworkRegistrations"
import NetworkRegistrations from "../NetworkInformation/NetworkRegistrations/NetworkRegistrations"
import { ViewProps } from "../Search/Search"
import Forescout from "../NetworkInformation/Forescout/Forescout"
import { remove_mac_address_separators } from "../Utilities/macUtilities"
import useVisitorNetworkRegistrations from "../Search/SearchSuggestions/useVisitorNetworkRegistrations"
import VisitorNetworkRegistrations from "../NetworkInformation/NetworkRegistrations/VisitorRegistrations"

let MACAddressView = (props: ViewProps) => {
    let { searchValue: mac_address } = props

    let mac_address_pretty = mac_address

    mac_address = remove_mac_address_separators(mac_address.toLowerCase())

    let { loading: netreg_loading, registrations: network_registrations } =
        useMACAddressNetworkRegistrations(mac_address)

    let { loading: visitor_loading, registrations: visitor_registrations } =
        useVisitorNetworkRegistrations(mac_address)

    let ucinetid = network_registrations?.[0]?.responsible_ucinetid

    let {
        loading: blocked_loading,
        ucinetidBlocked,
        blocks: block_records,
    } = useBlockList(ucinetid ?? "", [mac_address])

    return (
        <>
            <Collapse in={!netreg_loading}>
                <Box marginBottom="2rem">
                    <Paper>
                        <Box padding="2rem">
                            <Typography>
                                {ucinetid !== undefined ? (
                                    <>
                                        The MAC Address: <b>{mac_address_pretty}</b> is registered
                                        to UCInetID:{" "}
                                        <Link
                                            component={RouterLink}
                                            to={`/search/ucinetid/${ucinetid}`}
                                        >
                                            <b>{ucinetid}</b>
                                        </Link>
                                    </>
                                ) : visitor_registrations?.[0] !== undefined ? (
                                    <>
                                        The MAC Address: <b>{mac_address_pretty}</b> is registered
                                        to the visitor <b>{visitor_registrations?.[0].name}</b>
                                    </>
                                ) : (
                                    <>
                                        The MAC Address: <b>{mac_address_pretty}</b> is not
                                        registered!
                                    </>
                                )}
                            </Typography>
                        </Box>
                    </Paper>
                </Box>
            </Collapse>
            <NetworkRegistrations
                loading={netreg_loading}
                registrations={network_registrations ?? []}
            ></NetworkRegistrations>
            <VisitorNetworkRegistrations
                loading={visitor_loading}
                registrations={visitor_registrations}
            ></VisitorNetworkRegistrations>
            <BlockList
                loading={blocked_loading}
                ucinetidBlocked={ucinetidBlocked}
                blocks={block_records}
            ></BlockList>
            <Forescout mac_addr={mac_address}></Forescout>
        </>
    )
}

export default MACAddressView
