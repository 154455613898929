import { Alert, Box, Card, CardContent, CircularProgress, Collapse, Dialog, DialogContent, Typography } from "@mui/material"
import { green, yellow } from "@mui/material/colors"
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from "@mui/lab"
import { useQuery, useQueryClient } from "react-query"
import { useEffect } from "react"

import { fetchALMEventByUCInetID } from "../../apiFunctions"

interface AdobeLicenseManagementLogProps {
    ucinetid: string
    open: boolean
    handleClose: () => void
}

let AdobeLicenseManagementLog = (props: AdobeLicenseManagementLogProps) => {
    const queryClient = useQueryClient()
    let { isFetching, data } = useQuery(
        ["fetch-alm-events-by-ucinetid", props.ucinetid],
        fetchALMEventByUCInetID(props.ucinetid),
        {
            enabled: props.open
        }
    )
    useEffect(() => {
        if (props.open === true) {
            queryClient.invalidateQueries(["fetch-alm-events-by-ucinetid", props.ucinetid], { exact: true })
        }
    }, [props.open, props.ucinetid, queryClient])

    return (
        <Dialog open={props.open} onClose={props.handleClose} maxWidth="lg">
            <DialogContent>
                {isFetching ? (
                    <Box display="flex" justifyContent="flex-center" flexDirection="column" alignItems="center" padding="2rem">
                        <CircularProgress size="10rem" />
                    </Box>
                ) : (
                    data && data.length > 0 ? (
                        <Collapse in={true}>
                            <Timeline>
                                {data.map((event, index) => (
                                    <TimelineItem
                                        key={index}
                                        sx={{
                                            "&:before": {
                                                content: "none",
                                            },
                                        }}
                                    >
                                        <TimelineSeparator>
                                            <TimelineDot></TimelineDot>
                                            {index + 1 !== data?.length && (
                                                <TimelineConnector></TimelineConnector>
                                            )}
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <Box width="100%">
                                                <Typography fontWeight="bold" color="primary">Event Type: {event.type}</Typography>
                                                <Card>
                                                    <CardContent>
                                                        <Typography color={green[600]}>
                                                            Operator: {event.operator.toUpperCase()}
                                                        </Typography>
                                                        <Typography style={{ whiteSpace: 'pre-line' }}>
                                                            {event.detail}
                                                        </Typography>
                                                        <Typography color={yellow[900]}>
                                                            Event Time: {(new Date(event.timestamp)).toLocaleString('en-US')}
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </Box>
                                        </TimelineContent>
                                    </TimelineItem>
                                ))}
                            </Timeline>
                        </Collapse>
                    ) : (
                        <Alert severity="error">
                            Cannot find logs for user {props.ucinetid.toUpperCase()},
                            actions prior to 7/8/2022 are not recorded
                        </Alert>
                    )
                )
                }
            </DialogContent>
        </Dialog>
    )
}
export default AdobeLicenseManagementLog