import {
    Box,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Paper,
    Table,
    TableBody,
    TableContainer,
    Avatar
} from "@mui/material"
import { Domain, Email, Phone } from "@mui/icons-material"
import { blue } from "@mui/material/colors"
import { KFSOrg, KFSOrgContact } from "../../apiInterfaces"
import InfoBlock from "../GenericBlocks/InfoBlock/InfoBlock"
import TableKeyValueRow from "../GenericBlocks/TableKeyValueRow"

interface DepartmentInfoProps {
    loading: boolean
    department?: KFSOrg
    contact?: KFSOrgContact
}

let DepartmentInfo = (props: DepartmentInfoProps) => {
    let { loading, department, contact } = props

    return (
        <InfoBlock
            title="Department Info"
            icon={<Domain></Domain>}
            loading={loading}
            expanded={true}
        >
            <Paper>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableKeyValueRow title="Department Name">
                                {department?.org_name}
                            </TableKeyValueRow>
                            <TableKeyValueRow title="Department Number">
                                {department?.department_number}
                            </TableKeyValueRow>
                            <TableKeyValueRow title="Department Manager">
                                {department?.org_manager_name && (
                                    <>
                                        {department?.org_manager_name} (
                                        {department?.org_manager_user_id})
                                    </>
                                )}
                            </TableKeyValueRow>
                            <TableKeyValueRow title="Department Responsibility">
                                {
                                    department?.org_responsibility_long_description
                                }
                            </TableKeyValueRow>
                            <TableKeyValueRow title="Department Contacts">
                                <List disablePadding>
                                    {contact && contact.phone_numbers.map((phone) => (
                                        <ListItem disableGutters key={phone}>
                                            <ListItemAvatar>
                                                <Avatar style={{ backgroundColor: blue[500] }}><Phone /></Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                disableTypography
                                                primary={"phone"}
                                                secondary={
                                                    <Box
                                                        display="flex"
                                                        flexDirection="column"
                                                        sx={{ color: "text.secondary" }}
                                                    >
                                                        <span>
                                                            {phone}
                                                        </span>
                                                    </Box>
                                                }
                                            />
                                        </ListItem>
                                    ))}
                                    {contact && contact.email_addresses.map((email) => (
                                        <ListItem disableGutters key={email}>
                                            <ListItemAvatar>
                                                <Avatar style={{ backgroundColor: blue[500] }}><Email /></Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                disableTypography
                                                primary={"email"}
                                                secondary={
                                                    <Box
                                                        display="flex"
                                                        flexDirection="column"
                                                        sx={{ color: "text.secondary" }}
                                                    >
                                                        <span>
                                                            {email}
                                                        </span>
                                                    </Box>
                                                }
                                            />
                                        </ListItem>
                                    ))}
                                    {contact && contact.websites.map((website) => (
                                        <ListItem disableGutters key={website}>
                                            <ListItemAvatar>
                                                <Avatar style={{ backgroundColor: blue[500] }}><Domain /></Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                disableTypography
                                                primary={"website"}
                                                secondary={
                                                    <Box
                                                        display="flex"
                                                        flexDirection="column"
                                                        sx={{ color: "text.secondary" }}
                                                    >
                                                        <span>
                                                            {website}
                                                        </span>
                                                    </Box>
                                                }
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </TableKeyValueRow>
                            <TableKeyValueRow title="Parent Org">
                                {department?.org_parent_name} (
                                {department?.org_parent_code})
                            </TableKeyValueRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </InfoBlock>
    )
}

export default DepartmentInfo
