import {
    Alert,
    AlertTitle,
    Avatar,
    Box,
    Button,
    Collapse,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListSubheader,
    Paper,
    Table,
    TableBody,
    TableContainer,
    Typography,
} from "@mui/material"
import { green, orange } from "@mui/material/colors"
import { Business, Email, Person, Phone } from "@mui/icons-material"
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineSeparator,
} from "@mui/lab"
import React from "react"
import { useQuery } from "react-query"
import { fetchCSCUserDataByUCInetID, fetchDepartmentSupportByUCInetID } from "../../../apiFunctions"
import { SupportGroup, SupportGroupLog } from "../../../apiInterfaces"
import useLDAP from "../../DataHooks/useLDAP"
import InfoBlock from "../../GenericBlocks/InfoBlock/InfoBlock"
import TableKeyValueRow from "../../GenericBlocks/TableKeyValueRow"
import CloseableAlert from "../../PageStructure/AlertManager/CloseableAlert"

interface DepartmentSupportProps {
    ucinetid: string
    expanded?: boolean
}

let DepartmentSupport = (props: DepartmentSupportProps) => {
    let { ucinetid, expanded = false } = props

    let { loading: ldap_loading, entry: ldap_entry } = useLDAP(ucinetid)
    const ldap_attributes = ldap_entry?.attributes

    let [supportGroups, setSupportGroups] = React.useState<SupportGroup[]>([])
    let [supportGroupLog, setSupportGroupLog] = React.useState<SupportGroupLog[]>([])
    let [showLog, setShowLog] = React.useState(false)
    let [hide, setHide] = React.useState(false)

    const {
        isLoading,
        isError,
        data: ds_data,
    } = useQuery(
        ["department-support-by-ucinetid", ucinetid],
        fetchDepartmentSupportByUCInetID(ucinetid)
    )

    let {
        data: csc_data
    } = useQuery(
        ["csc-by-ucinetid", ucinetid],
        fetchCSCUserDataByUCInetID(ucinetid)
    )


    React.useEffect(() => {
        setSupportGroups(ds_data?.support_groups ?? [])
        setSupportGroupLog(ds_data?.log ?? [])
    }, [ds_data])

    React.useEffect(() => {
        if (isError) {
            setHide(true)
        }
    }, [isError])

    return (
        <InfoBlock
            title="Department Support"
            icon={<Business></Business>}
            loading={ldap_loading || isLoading}
            expanded={expanded}
            hide={hide}
            
        >
        {csc_data?.ucinetid !== undefined &&
            (<Alert severity="warning">
                <AlertTitle>
                    <b>{ucinetid} is a CSC</b>
                </AlertTitle>
                Do not redirect the caller to their Department Support group.
            </Alert>)}
            <CloseableAlert
                hidden={(ldap_attributes?.department?.length ?? 0) <= 1}
                severity="warning"
                title="User has Multiple Departments!"
            >
                This user has multiple departments. HDUtils currently uses the first department
                listed <i>HOWEVER</i> this may not be their primary department! (Tell the dev team
                if you encounter this warning often so we'll increase the priority to fix it).
                <br></br>
                <br></br>
                Their Departments: {ldap_attributes?.department?.join(", ")}
            </CloseableAlert>
            <Paper>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableKeyValueRow title="Possible Support Groups">
                                <List disablePadding>
                                    {supportGroups.map((sg) => (
                                        <React.Fragment key={sg.name}>
                                            <ListSubheader disableGutters disableSticky>
                                                <Typography variant="h6" color="primary">
                                                    {sg.name}
                                                </Typography>
                                            </ListSubheader>
                                            {sg.contacts.map((contact) => (
                                                <ListItem disableGutters key={contact.name}>
                                                    <ListItemAvatar>
                                                        <Avatar
                                                            style={{
                                                                backgroundColor:
                                                                    contact.phone_number_private
                                                                        ? orange[500]
                                                                        : green[500],
                                                            }}
                                                        >
                                                            {contact.phone_number ? (
                                                                <Phone></Phone>
                                                            ) : contact.email ? (
                                                                <Email></Email>
                                                            ) : (
                                                                <Person></Person>
                                                            )}
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        disableTypography
                                                        primary={contact.name}
                                                        secondary={
                                                            <Box
                                                                display="flex"
                                                                flexDirection="column"
                                                                sx={{ color: "text.secondary" }}
                                                            >
                                                                <span>
                                                                    {contact.phone_number_private &&
                                                                        "PRIVATE: "}
                                                                    {contact.phone_number}
                                                                </span>
                                                                <span>{contact.email}</span>
                                                            </Box>
                                                        }
                                                    ></ListItemText>
                                                </ListItem>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </List>
                            </TableKeyValueRow>
                            <TableKeyValueRow title="Log">
                                <Button onClick={() => setShowLog((s) => !s)}>Show Log</Button>
                                <Collapse in={showLog}>
                                    <Timeline>
                                        {supportGroupLog.map((log, index) => (
                                            <TimelineItem
                                                key={log.message}
                                                sx={{
                                                    "&:before": {
                                                        content: "none",
                                                    },
                                                }}
                                            >
                                                <TimelineSeparator>
                                                    <TimelineDot></TimelineDot>
                                                    {index + 1 !== supportGroupLog.length && (
                                                        <TimelineConnector></TimelineConnector>
                                                    )}
                                                </TimelineSeparator>
                                                <TimelineContent>
                                                    <Box width="100%">
                                                        <Typography>{log.message}</Typography>
                                                    </Box>
                                                </TimelineContent>
                                            </TimelineItem>
                                        ))}
                                    </Timeline>
                                </Collapse>
                            </TableKeyValueRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </InfoBlock>
    )
}

export default DepartmentSupport
