import { Box, Container, useTheme } from "@mui/material"
import React, { useCallback } from "react"
import Particles from "react-particles"
import { loadFull } from "tsparticles"
import { Engine } from "tsparticles-engine"

interface PageParentProps {
    children: NonNullable<React.ReactNode>
    fullHeight?: boolean
    minWidth?: number | string
    backgroundOverlay?: boolean
}

let PageParent = (props: PageParentProps) => {
    let { children, fullHeight = false, minWidth = "0rem", backgroundOverlay = true } = props
    const theme = useTheme()
    const particlesInit = useCallback(async (engine: Engine) => { await loadFull(engine); }, []);

    return (
        <Container maxWidth="lg" style={fullHeight ? { minWidth: minWidth, height: "100%" } : { minWidth: minWidth }}>
            <Box position="absolute" overflow="hidden" zIndex={-999} left={0} top={0} height="100%" width="100%">
                <Particles url="bg-animation-particle-config.json" init={particlesInit} id="particles"  options={{smooth: true}}/>
            </Box>
            {/* <MountainView /> */}
            {backgroundOverlay && <Box
                position="absolute"
                left={0}
                top={0}
                height="100%"
                width="100%"
                bgcolor={theme.palette.mode === "light" ? "rgba(255,255,255,0.55)" : "rgba(0,0,0,0.55)"}
                zIndex={-99}
            />}
            {children}
        </Container>
    )
}

export default PageParent
