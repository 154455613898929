import { Box, Grid, TextField } from "@mui/material"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { useState } from "react"

interface DateRangePickerProps {
    defaultDateRange: [Date, Date]//[Start Date, End Date]
    disablePast?: boolean,
    disableFuture?: boolean,
    onChange: (dateRange: [Date, Date]) => void
}

let DateRangePicker = (props: DateRangePickerProps) => {
    let { defaultDateRange, disablePast=false, disableFuture=false, onChange } = props
    let [dateRange, setDateRange] = useState(defaultDateRange)

    let handleDateChange = (startDate: Date, endDate: Date) => {
        if(startDate > endDate){
            if (dateRange[0] === startDate){
                startDate = endDate
            }else{
                endDate = startDate
            }
        }
        setDateRange([startDate, endDate])
        onChange([startDate, endDate])
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container>
                <Grid item xs={5.5}>
                    <DesktopDatePicker
                        label="Start Date"
                        inputFormat="MM/dd/yyyy"
                        value={dateRange[0]}
                        disablePast={disablePast}
                        disableFuture = {disableFuture}
                        onChange={(startDate: Date | null) => startDate && handleDateChange(startDate, dateRange[1])}
                        renderInput={(params) => <TextField size="small" {...params} />}
                    />
                </Grid>
                <Grid item xs={1} >
                    <Box mx="auto" width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">to</Box>
                </Grid>
                <Grid item xs={5.5}>
                    <DesktopDatePicker
                        label="End Date"
                        inputFormat="MM/dd/yyyy"
                        value={dateRange[1]}
                        disablePast={disablePast}
                        disableFuture = {disableFuture}
                        onChange={(endDate: Date | null) => endDate && handleDateChange(dateRange[0], endDate)}
                        renderInput={(params) => <TextField size="small" {...params} />}
                    />
                </Grid>
            </Grid>
        </LocalizationProvider>
    )

}

export default DateRangePicker