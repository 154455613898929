import { LDAPEntry } from "../../apiInterfaces"
import { useQuery } from "react-query"
import { fetchAllLDAPDataByUCInetID } from "../../apiFunctions"

interface LDAPData {
    entry?: LDAPEntry
    loading: boolean
    error: boolean
}

let useLDAP = (ucinetid: string): LDAPData => {
    let { isLoading, isError, data } = useQuery(
        ["ldap-all-attributes-by-ucinetid", ucinetid],
        fetchAllLDAPDataByUCInetID(ucinetid)
    )

    return { entry: data, loading: isLoading, error: isError }
}

export default useLDAP
