import { Alert, Button, Box, CircularProgress, Dialog, DialogActions, DialogTitle, DialogContent, TextField, Typography, Checkbox, FormControlLabel } from "@mui/material"
import { useEffect, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { updateModelerLicense } from "../../apiFunctions"
import { ModelerLicenseRequestInfo } from "../../apiInterfaces"



interface ModelerLicenseModificationDialogProps {
    ucinetid: string
    open: boolean
    handleClose: () => void
}

const ModelerLicenseModificationDialog = (props: ModelerLicenseModificationDialogProps) => {
    const queryClient = useQueryClient()
    let [reason, setReason] = useState("")
    let [submitted, setSubmitted] = useState(false)
    let [isChecked, setIsChecked] = useState(false)

    const mutation = useMutation(updateModelerLicense, {
        onMutate: async (requestInfo) => {
            await queryClient.cancelQueries("update-modeler-license")
            const previousRequestInfo = queryClient.getQueryData("update-modeler-license")
            queryClient.setQueryData("update-modeler-license", requestInfo)
            return { previousRequestInfo }
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["fetch-modeler-license-by-ucinetid", props.ucinetid], { exact: true })
        },
    })

    const saveLicense = () => {
        const requestInfo = {
            ucinetid: props.ucinetid,
            reason: reason
        } as ModelerLicenseRequestInfo
        console.log(requestInfo)
        mutation.mutate(requestInfo)
        setSubmitted(true)
    }

    if (submitted === true && mutation.isSuccess) {
        setSubmitted(false)
        props.handleClose()
    }

    //reset when open dialog
    useEffect(() => {
        if (props.open === true) {
            setIsChecked(false)
            setReason("")
        }
    }, [props.open])


    return (
        <Dialog open={props.open} onClose={props.handleClose} >
            <DialogTitle>Modeler License Modification</DialogTitle>
            <DialogContent>
                {submitted === true && mutation.isLoading ? (
                    <Box display="flex" justifyContent="flex-center" flexDirection="column" alignItems="center" >
                        <CircularProgress size="10rem" />
                        <Typography mt="2rem">Setting The License...</Typography>
                    </Box>
                ) : (submitted === true && mutation.isError) ? (
                    <Box display="flex" justifyContent="flex-center" flexDirection="column" alignItems="center" >
                        <Alert severity="error" sx={{ width: '100%' }}>
                            An Error Occurred While Changing Your License.
                            <br />
                            Please reload the page and try again or contact dev team.
                        </Alert>
                    </Box>
                ) : (
                    <Box display="flex" justifyContent="flex-center" flexDirection="column" alignItems="center" >
                        <TextField
                            InputProps={{ style: { fontSize: 16 } }}
                            multiline rows={3}
                            placeholder="Reason"
                            sx={{ width: "100%", p: "1rem" }}
                            value={reason}
                            onChange={(e) => { setReason(e.target.value) }}
                        />
                        <FormControlLabel
                            label="* I understand that this change cannot be reverted, 
                            and this user will receive the code in modeler.oit.uci.edu."
                            sx={{ color: "red", width: "100%", p: "1rem" }}
                            control={
                                <Checkbox
                                    required
                                    checked={isChecked}
                                    onChange={(e, checked) => setIsChecked(checked)}
                                />
                            }
                        />
                    </Box>
                )
                }
            </DialogContent>
            <DialogActions>
                <Button disabled={mutation.isLoading === true} onClick={props.handleClose}>Close</Button>
                <Button disabled={submitted === true || isChecked === false} onClick={saveLicense}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}


export default ModelerLicenseModificationDialog