import {
    Avatar,
    Box,
    Button,
    Collapse,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListSubheader,
    Paper,
    Table,
    TableBody,
    TableContainer,
    Typography,
} from "@mui/material"
import { green, orange } from "@mui/material/colors"
import { Business, Email, Person, Phone } from "@mui/icons-material"
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineSeparator,
} from "@mui/lab"
import React from "react"
import { useQuery } from "react-query"
import { fetchDepartmentSupportByDepartmentNumber } from "../../../apiFunctions"
import { SupportGroup, SupportGroupLog } from "../../../apiInterfaces"
import InfoBlock from "../../GenericBlocks/InfoBlock/InfoBlock"
import TableKeyValueRow from "../../GenericBlocks/TableKeyValueRow"

interface DepartmentSupportByDepartmentNumberProps {
    department_number: string
}

let DepartmentSupportByDepartmentNumber = (props: DepartmentSupportByDepartmentNumberProps) => {
    let { department_number } = props

    let [supportGroups, setSupportGroups] = React.useState<SupportGroup[]>([])
    let [supportGroupLog, setSupportGroupLog] = React.useState<SupportGroupLog[]>([])
    let [showLog, setShowLog] = React.useState(false)
    let [hide, setHide] = React.useState(false)

    let { isLoading, isError, data } = useQuery(
        ["department-support-by-department-number", department_number],
        fetchDepartmentSupportByDepartmentNumber(department_number)
    )

    React.useEffect(() => {
        setSupportGroups(data?.support_groups ?? [])
        setSupportGroupLog(data?.log ?? [])
    }, [data])

    React.useEffect(() => {
        if (isError) {
            setHide(true)
        }
    }, [isError])

    return (
        <InfoBlock
            title="Department Support"
            icon={<Business></Business>}
            loading={isLoading}
            hide={hide}
        >
            <Paper>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableKeyValueRow title="Possible Support Groups">
                                <List disablePadding>
                                    {supportGroups.map((sg) => (
                                        <React.Fragment key={sg.name}>
                                            <ListSubheader disableGutters disableSticky>
                                                <Typography variant="h6" color="primary">
                                                    {sg.name}
                                                </Typography>
                                            </ListSubheader>
                                            {sg.contacts.map((contact) => (
                                                <ListItem disableGutters key={contact.name}>
                                                    <ListItemAvatar>
                                                        <Avatar
                                                            style={{
                                                                backgroundColor:
                                                                    contact.phone_number_private
                                                                        ? orange[500]
                                                                        : green[500],
                                                            }}
                                                        >
                                                            {contact.phone_number ? (
                                                                <Phone></Phone>
                                                            ) : contact.email ? (
                                                                <Email></Email>
                                                            ) : (
                                                                <Person></Person>
                                                            )}
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        disableTypography
                                                        primary={contact.name}
                                                        secondary={
                                                            <Box
                                                                display="flex"
                                                                flexDirection="column"
                                                                sx={{ color: "text.secondary" }}
                                                            >
                                                                <span>
                                                                    {contact.phone_number_private &&
                                                                        "PRIVATE: "}
                                                                    {contact.phone_number}
                                                                </span>
                                                                <span>{contact.email}</span>
                                                            </Box>
                                                        }
                                                    ></ListItemText>
                                                </ListItem>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </List>
                            </TableKeyValueRow>
                            <TableKeyValueRow title="Log">
                                <Button onClick={() => setShowLog((s) => !s)}>Show Log</Button>
                                <Collapse in={showLog}>
                                    <Timeline>
                                        {supportGroupLog.map((log, index) => (
                                            <TimelineItem
                                                key={log.message}
                                                sx={{
                                                    "&:before": {
                                                        content: "none",
                                                    },
                                                }}
                                            >
                                                <TimelineSeparator>
                                                    <TimelineDot></TimelineDot>
                                                    {index + 1 !== supportGroupLog.length && (
                                                        <TimelineConnector></TimelineConnector>
                                                    )}
                                                </TimelineSeparator>
                                                <TimelineContent>
                                                    <Box width="100%">
                                                        <Typography>{log.message}</Typography>
                                                    </Box>
                                                </TimelineContent>
                                            </TimelineItem>
                                        ))}
                                    </Timeline>
                                </Collapse>
                            </TableKeyValueRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </InfoBlock>
    )
}

export default DepartmentSupportByDepartmentNumber
